define(
    'Inventis/Bundle/BricksBundle/Grid/View/PaginatedView',[
        'Inventis/Bundle/BricksBundle/Grid/View/AbstractView',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (AbstractView, Console) {
        'use strict';
        /**
         * @class PaginatedView
         * @extends AbstractView
         */
        return AbstractView.extend({
            /**
             * @var {int}
             */
            currentPage: 1,

            createDefaultModel: function () {
                var model = this.__super();
                model.pagination = {first: null, previous: null, current: null, next: null, last: null, pages: []};
                return model;
            },

            processConfig: function (config) {
                var processedConfig = this.__super(config);

                processedConfig.itemsPerPage = config.itemsPerPage || config.store.config.batchSize;
                /**
                 * the dataset property that will contain the page on changePage
                 */
                processedConfig.pageData = config.pageData || 'page';
                /**
                 * how many pages should be populates in the pagination.pages model
                 */
                processedConfig.maxVisiblePageSize = config.maxVisiblePageSize || 5;

                return processedConfig;
            },

            render: function () {
                return this.__super().then(function () {
                    this.listeners.push(
                        this.getContainerElement().addEventListener('changePage', this.onChangePage.bind(this))
                    );
                }.bind(this));
            },

            onChangePage: function (event) {
                var page = parseInt(event.target.dataset[this.config.pageData]);
                if (!isNaN(page)) {
                    this.drawPage(page);
                } else {
                    Console.error(
                        'changePage event fired, but there was no `' + this.config.pageData + '` dataset value'
                        + ' available. Either add a data-' + this.config.pageData + '="{{ page }}" to your'
                        + ' template, or define another dataset property in your config through `pageData: "someProp"`'
                    );
                }
            },

            /**
             * @protected
             * @param {{data: Record[], totalCount: Integer}} result
             */
            updateModel: function (result) {
                this.model.pagination = this.processPagination(result.totalCount);
                this.__super(result);
            },

            processPagination: function (totalCount) {
                this.totalCount = totalCount;
                var pages = [],
                    currentPage = this.getCurrentPage(),
                    maxPages = this.config.maxVisiblePageSize,
                    last = Math.ceil(totalCount / this.getItemsPerPage()),
                    pagesDesiredOnEachSide = (maxPages - 1) / 2,
                    // Using floor and ceil separately will ensure we have the requested page amount when the amount
                    // does not divide properly by 2. For example, with 5, you have 2 pages left, 1 page as the current
                    // page, and 2 pages right, but for 10, you cannot have 4.5 page left, 1 page as current page and
                    // 4.5 page right, so round down left and up right to correct.
                    pagesDesiredLeft = Math.floor(pagesDesiredOnEachSide),
                    pagesDesiredRight = Math.ceil(pagesDesiredOnEachSide),
                    pagesLeftOnLeft = currentPage - 1,
                    pagesLeftOnRight = last - currentPage,
                    pagesToTakeLeft = Math.min(pagesDesiredLeft, pagesLeftOnLeft),
                    pagesToTakeRight = Math.min(pagesDesiredRight, pagesLeftOnRight),
                    pagesTakenTooFewLeft = pagesDesiredLeft - pagesToTakeLeft,
                    pagesTakenTooFewRight = pagesDesiredRight - pagesToTakeRight,
                    prefixPageCount = Math.min(pagesToTakeLeft + pagesTakenTooFewRight, pagesLeftOnLeft),
                    suffixPageCount = Math.min(pagesToTakeRight + pagesTakenTooFewLeft, pagesLeftOnRight);

                var generatePages = function (count, offset) {
                    return Array.apply(null, {length: count}).map(function (value, index) {
                        var page = index + 1 + offset;
                        return {page: page, isCurrent: currentPage === page};
                    });
                };

                pages.push.apply(pages, generatePages(prefixPageCount, currentPage - 1 - prefixPageCount));
                pages.push.apply(pages, generatePages(1, currentPage - 1));
                pages.push.apply(pages, generatePages(suffixPageCount, currentPage));

                var pagination = {
                    first: currentPage !== 1 ? 1 : null,
                    previous: this.getPreviousPage(),
                    current: currentPage,
                    next: this.getNextPage(),
                    last: last !== currentPage ? last : null,
                    pages: pages,
                };

                return pagination;
            },

            getPreviousPage: function () {
                var previous = this.getCurrentPage() - 1;
                if (previous < 1) {
                    return null;
                }
                return previous;
            },

            getCurrentPage: function () {
                return this.currentPage;
            },

            getNextPage: function () {
                var next = this.getCurrentPage() + 1;
                if (next > Math.ceil(this.totalCount / this.config.itemsPerPage)) {
                    return null;
                }
                return next;
            },

            setCurrentPage: function (page) {
                this.currentPage = parseInt(page);
                this.model.currentPage = this.currentPage;
            },

            drawPage: function (page) {
                this.setCurrentPage(page);
                return this.redraw();
            },

            getItemsPerPage: function () {
                return this.config.itemsPerPage;
            },

            getCurrentStart: function () {
                return ((this.getCurrentPage() - 1) * this.getItemsPerPage());
            },

            getRangeSize: function () {
                return this.getItemsPerPage();
            },
        });
    }
);

