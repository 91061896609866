define(
    'Inventis/Bundle/BricksBundle/Grid/Store/RemoteStore',[
        'Inventis/Bundle/BricksBundle/Ajax',
        'Inventis/Bundle/BricksBundle/Promise',
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Grid/Store/Record',
        'Inventis/Bundle/BricksBundle/Grid/Store/RecordCollection',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (ajax, Promise, Class, Record, RecordCollection, Console) {
        'use strict';

        /**
         * internal endpoint call, always used as endpoint.call(this, params)
         * @this RemoteStore
         * @param {Object} params
         * @return {String|Object}
         */
        var endpoint = function (params) {
            params.cors = params.cors !== undefined ? params.cors : this.config.allowCrossOrigin;
            params.url = params.url || this.config.endpoint;
            params.headers = Object.assign({}, this.config.headers, params.headers || {});
            return ajax(params);
        };

        /**
         * @implements Store
         * @class RemoteStore
         *
         * Store that handles retrieval if items through a specified callback
         */
        var RemoteStore = Class.extend({

            /**
             * @var RecordCollection
             */
            data: null,

            /**
             * keep track of record changes, this allows the store the keep record cache for an extended period
             * as long as lastChange is available in all responses
             */
            lastChange: null,

            /**
             * @param {Object} config
             * @param {Object} [filters]
             * @param {Object} [sort]
             */
            __construct: function (config, filters, sort) {
                this.filters = filters || {};
                this.sort = sort || {};
                try {
                    this.config = this.processConfig(config);
                    this.data = new RecordCollection({model: this.config.model});
                } catch (e) {
                    Console.error('Invalid RemoteStore config:', config, e.message);
                    throw e;
                }
            },

            getRange: function (start, size) {
                return new Promise(function (resolve, reject) {
                    this.getTotalCount().then(function (totalCount) {
                        try {
                            var end = Math.min(start + size - 1, Math.max(0, totalCount - 1));
                            if (this.hasRange(start, end)) {
                                resolve({data: this.getDataRange(start, end), totalCount: totalCount});
                            } else {
                                this.fetchRange(start, start + size - 1).then(function (result) {
                                    resolve(result);
                                }).catch(reject);
                            }
                        } catch (e) {
                            Console.error('Failed to fetchRange:' + e.message);
                            reject(e);
                        }
                    }.bind(this)).catch(reject);
                }.bind(this));
            },

            /**
             * locates the first non available index for range
             * @param {Number} start
             * @param {Number} end
             * @return {Number}
             */
            findFirstMissingRangeIndex: function (start, end) {
                while (this.data.has(start) && start < end) {
                    start++;
                }
                return start;
            },

            getTotalCount: function () {
                return new Promise(function (resolve, reject) {
                    try {
                        if (this.config.totalCount === null) {
                            this.config.totalCount = 0; // if not updated after fetching assume an empty store
                            this.fetchRange(0, this.config.batchSize - 1).then(function () {
                                resolve(this.config.totalCount);
                            }.bind(this)).catch(reject);
                        } else {
                            resolve(this.config.totalCount);
                        }
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            hasRange: function (start, end) {
                return this.getDataRange(start, end).length - 1 === end - start;
            },

            clear: function () {
                this.data.clear();
            },

            getFilters: function () {
                return this.filters;
            },

            setFilters: function (filters) {
                this.filters = filters;
                this.clear();
                this.config.totalCount = null;
            },

            getSort: function () {
                return this.sort;
            },

            setSort: function (sort) {
                this.sort = sort;
                this.clear();
            },

            getData: function () {
                return this.data;
            },

            getRecord: function (id) {
                return this.data.getRecord(id);
            },

            updateRecord: function (id, newData) {
                var newRecord = this.createRecord(newData);
                var index = this.data.getRecord(id);
                this.data.set(index, newRecord);
                return newRecord;
            },

            /**
             * @private
             * @param {Object} config
             * @return {Object}
             */
            processConfig: function (config) {
                if (!config.endpoint) {
                    throw new Error('No endpoint defined in config');
                }
                if (!(config.model instanceof Object)) {
                    throw new Error('No or invalid model defined in config');
                }
                return {
                    endpoint: config.endpoint,
                    /**
                     * the minimum amount of items requested per call from the endpoint
                     */
                    batchSize: parseInt(config.batchSize) || 50,
                    /**
                     * the currently known total count, when a new batch is requested and a totalCount
                     * is present this will be updated accordingly
                     */
                    totalCount: parseInt(config.totalCount) || null,
                    /**
                     * allow the endpoint to be cross-origin, defaults to false
                     */
                    allowCrossOrigin: !!config.allowCrossOrigin,
                    /**
                     * model holds model meta data
                     * model.name = unique name that can be used to identify record types
                     */
                    model: config.model,

                    /**
                     * Any data they should always be send to the store's endpoint
                     */
                    defaultRequestData: config.defaultRequestData || {},

                    /**
                     * some default headers you'd like to send with every request
                     */
                    headers: Object.assign(config.headers || {}, {
                        'Accept': 'application/json',
                    }),
                };
            },

            /**
             * @private
             * @async
             * @param {Number} start
             * @param {Number} end
             * @return {Promise}
             */
            validateRange: function (start, end) {
                return new Promise(function (resolve, reject) {
                    this.getTotalCount().then(function (totalCount) {
                        try {
                            var maxRange = Math.max(0, totalCount - 1);
                            if (start < 0 || start > maxRange) {
                                reject(new RangeError(
                                    'Invalid start range requested, must be between 0 and ' + maxRange
                                ));
                            } else if (end < start || end > maxRange) {
                                reject(new RangeError(
                                    'Invalid end range requested, must be between ' + start + ' and ' + maxRange
                                ));
                            } else {
                                resolve();
                            }
                        } catch (e) {
                            reject(e);
                        }
                    }).catch(reject);
                }.bind(this));
            },

            buildRequestBody: function (start, end) {
                var body = Object.assign({}, this.config.defaultRequestData),
                    filters = this.getFilters(),
                    sort = this.getSort();

                body.start = start;
                body.end = end;
                if (Object.keys(filters).length) {
                    body.filters = filters;
                }
                if (Object.keys(sort).length) {
                    body.sort = sort;
                }

                return body;
            },

            /**
             * @private
             * @async
             * @param {Number} start
             * @param {Number} end
             * @return {Promise}
             */
            fetchRange: function (start, end) {
                return new Promise(function (resolve, reject) {
                    try {
                        var fetchStart = this.findFirstMissingRangeIndex(start, end),
                            // fetch at least the batch size with a max of totalCount
                            fetchEnd = Math.max(fetchStart + this.config.batchSize - 1, end),
                            body = this.buildRequestBody(fetchStart, fetchEnd);
                        endpoint.call(
                            this,
                            {
                                method: 'GET',
                                body: body,
                                success: function (response, request) {
                                    try {
                                        this.processResponse(fetchStart, fetchEnd, response);
                                        var requiredEnd = Math.min(end, this.config.totalCount - 1);
                                        if (!this.hasRange(start, requiredEnd)) {
                                            Console.error(
                                                'Required range fetched, but not available in ',
                                                response,
                                                'for request',
                                                request
                                            );
                                            reject(new Error('Failed to fetch range ' + start + ' to ' + end));
                                        } else {
                                            resolve({
                                                data: this.getDataRange(start, end),
                                                totalCount: this.config.totalCount,
                                            });
                                        }
                                    } catch (e) {
                                        Console.error('Failed to process response', response, 'for request', request);
                                        reject(e);
                                    }
                                }.bind(this),
                                error: function (statusCode, response, request) {
                                    var errorMsg = '[' + statusCode + '] Failed to fetch range ' + start + ' to ' + end;
                                    if (response instanceof Object && response.error) {
                                        errorMsg += ': ' + response.error;
                                    }
                                    Console.error(
                                        errorMsg,
                                        'statusCode', statusCode,
                                        'response', response,
                                        'request', request
                                    );
                                    reject(new Error(errorMsg));
                                },
                            }
                        );
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            /**
             * @private
             * @param {Number} start
             * @param {Number} end
             * @return {Array<Record>}
             */
            getDataRange: function (start, end) {
                var range = [],
                    value;
                while (start <= end) {
                    value = this.data.get(start);
                    if (value) {
                        range.push(value);
                    }
                    ++start;
                }
                return range;
            },

            /**
             * @internal
             * @private
             * @param {Number} start
             * @param {Number} end
             * @param {Object} response
             */
            processResponse: function (start, end, response) {
                response = this.parseResponse(response);
                var currentState = {
                    totalCount: this.config.totalCount,
                    lastChange: this.lastChange,
                };

                if (response.totalCount !== undefined) {
                    this.config.totalCount = response.totalCount;
                }

                if ((currentState.lastChange === null && response.lastChange !== null)
                    || (response.lastChange && response.lastChange.getTime() > currentState.getTime())
                    || currentState.totalCount !== response.totalCount || response.totalCount === null
                ) {
                    this.clear();
                }

                this.lastChange = response.lastChange;

                this.processResponseItems(start, end, response.items);
            },

            /**
             * @private
             * @param {Number} start
             * @param {Number} end
             * @param {Array<Object>|Map} items
             */
            processResponseItems: function (start, end, items) {
                var length = items.length !== undefined ? items.length : items.size;
                if (length - 1 > end - start) {
                    throw new Error('Invalid items count received in response');
                }
                items.forEach(function (item) {
                    this.data.set(start++, this.createRecord(item));
                }.bind(this));
            },

            createRecord: function (data) {
                return this.data.createRecord(data);
            },

            deleteRecord: function (id) {
                var collectionSize = this.data.getLength();
                if (this.data.deleteRecord(id)) {
                    // when a record id deleted see if it reduced the collection'size
                    // else it was a nested record (child)
                    if (collectionSize > this.data.getLength()) {
                        --this.config.totalCount;
                    }
                    return true;
                }
                return false;
            },

            /**
             * @private
             * @param {*} response
             * @return  {Object}
             */
            parseResponse: function (response) {
                if ((typeof response) === 'string') {
                    try {
                        response = JSON.parse(response);
                    } catch (e) {
                        throw new Error('Invalid json response received');
                    }
                }
                if (!(response.items instanceof Array)) {
                    if (response.items instanceof Object) {
                        response.items = new Map(Object.entries(response.items));
                    } else {
                        throw new Error('Invalid items response received! Must be array or map.');
                    }
                }
                if ((typeof response.lastChange) === 'string') {
                    var date = new Date(response.lastChange);
                    if (isNaN(date)) {
                        throw new TypeError(
                            'reponse.lastChange `' + response.lastChange + '` is not a valid W3C date format'
                        );
                    }
                    response.lastChange = date;
                } else if (response.lastChange) {
                    var value = (typeof response.lastChange);
                    throw new TypeError(
                        'reponse.lastChange `' + value + '` is not a valid W3C date format'
                    );
                } else {
                    response.lastChange = null;
                }
                if (response.totalCount !== undefined) {
                    response.totalCount = parseInt(response.totalCount);
                } else {
                    response.totalCount = null;
                }
                return response;
            },
        });

        return RemoteStore;
    }
);

