
define(
    'Inventis/Bundle/BricksBundle/Brick/RemoteBrickLoader',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Application',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Brick/AttributeConfigFactory',
        'Inventis/Bundle/BricksBundle/Brick/Manager',
        'Inventis/Bundle/BricksBundle/Base64EncodedJsonParser',
    ],
    function (Brick, App, $, AttributeConfigFactory, Manager, Base64EncodedJsonParser) {
        'use strict';

        /**
         * @class RemoteBrickLoader
         *
         * @extends Brick
         */
        var RemoteBrickLoader = Brick.extend({
            /**
             * @var {String|null}
             */
            url: null,

            init: function (setup) {
                this.__super(setup);

                if (this.getUrl() !== null) {
                    this.reloadContents();
                }
            },

            attachListeners: function () {
                this.__super();

                this.attachReloadListener();
            },

            attachReloadListener: function () {
                this.on('reload', function (e, options) {
                    if (options.url) {
                        this.setUrl(options.url);
                    }

                    this.reloadContents();
                }.bind(this), true);
            },

            setupFromConfig: function (setup) {
                this.__super(setup);

                this.setUrlFromSetup(setup);
            },

            setUrlFromSetup: function (setup) {
                if (setup.url !== undefined) {
                    this.setUrl(setup.url);
                }
            },

            setUrl: function (url) {
                this.url = url;
            },

            getUrl: function () {
                return this.url;
            },

            reloadContents: function () {
                this.disable();
                this.setLoadingSpinnerVisibility(true);

                App.ajax(this.getUrl(), {}, this.onAjaxComplete, this);
            },

            onAjaxComplete: function (response, options) {
                App.ready(function () {
                    if (response.success === true || response.success === undefined) {
                        this.onAjaxSuccess(response);
                    } else {
                        this.onAjaxFailure(response);
                    }

                    this.setLoadingSpinnerVisibility(false);
                    this.enable();
                }, this);
            },

            onAjaxSuccess: function (response) {
                if (response.success === true) {
                    this.setContent(response.result);
                } else {
                    this.setContent(response);
                }

                this.fire('remoteBricksLoaded', {url: this.getUrl()});
            },

            onAjaxFailure: function (response) {
                this.setContent(response.message);

                this.fire('remoteBricksLoadFailed', {url: this.getUrl()});
            },

            setContent: function (message) {
                var el = $('.remote-brick-loader__content', this.getElement()).shift(),
                    factory = new AttributeConfigFactory(Base64EncodedJsonParser, Manager);

                el.innerHTML = message;

                factory.create(el);
            },

            setLoadingSpinnerVisibility: function (isVisible) {
                var el = $('.remote-brick-loader__loading', this.getElement()).shift();

                if (isVisible) {
                    el.classList.remove('h-hidden');
                } else {
                    el.classList.add('h-hidden');
                }
            },
        });

        return RemoteBrickLoader;
    }
);

