
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/RadioGroup',[
        'Inventis/Bundle/BricksBundle/Brick/Form/CheckboxGroup',
    ],
    function (CheckboxGroup) {
        'use strict';

        var RadioGroup = CheckboxGroup.extend({
            onCheckableChanged: function (event, options) {
                var element = this.getElement(),
                    groupName = this.getGroupName(),
                    radioButtons = element.querySelectorAll('input[name="' + groupName + '"]'),
                    radioButton,
                    fieldValue = null;

                for (var i = 0; i < radioButtons.length; ++i) {
                    radioButton = radioButtons[i];
                    if (radioButton.checked) {
                        options.fieldValue = radioButton.value;
                        return true;
                    }
                }
                return false;
            },

            setGroupNameFromSetup: function (setup) {
                if (setup.name) {
                    this.setGroupName(setup.name);
                }
            },
        });

        return RadioGroup;
    }
);

