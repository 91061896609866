define(
    'Inventis/Bundle/BricksBundle/DelayedTask',[
        'Inventis/Bundle/BricksBundle/Class',
    ],
    function (Class) {
        'use strict';

        return Class.extend({
            timeoutId: null,
            __construct: function (defaultDelay) {
                defaultDelay = defaultDelay === undefined ? 500 : defaultDelay;
                this.milliseconds = defaultDelay;
            },

            /**
             * delay the task callback, removing any already existing callback not yet delayed
             * @param {function} callback
             * @param {int} [milliseconds] optionally change delay from the in construction specified delay
             */
            delay: function (callback, milliseconds) {
                this.cancel();
                this.timeoutId = window.setTimeout(
                    function () {
                        this.timeoutId = null;
                        callback.call();
                    }.bind(this),
                    milliseconds !== undefined ? milliseconds : this.milliseconds
                );
            },

            cancel: function () {
                if (this.isWaiting()) {
                    window.clearTimeout(this.timeoutId);
                    return true;
                }
                return false;
            },

            isWaiting: function () {
                return this.timeoutId !== null;
            },
        });
    }
);

