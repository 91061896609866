
/**
 * The component is the base for all components
 * it will provide the minimal functionality
 * components need to function within the modules
 */
define(
    'Inventis/Bundle/BricksBundle/Brick/Brick',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Mixins/Observable',
        'Inventis/Bundle/BricksBundle/Mixins/Maskable',
        'Inventis/Bundle/BricksBundle/Brick/Brick/EventRoutingMixin',
        'Inventis/Bundle/BricksBundle/Application',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Class, Observable, Maskable, EventRouting, App, Element, $) {
        'use strict';

        /**
         * Brick is an extension of Class so that we get
         * build-in extensibility and mixin type parallel extension
         */
        return Class.extend(function () {
            /**
             * this method only fires the events before and after rendering
             * to ensure all components do this when rendering through normal
             * component processes
             */
            function _renderComponent() {
                this.fire('componentBeforeRender');
                this.render();
                this.fire('componentAfterRender');
                this._isRendered = true;
            }

            /**
             * everything defined below is the public
             * scope for the component object and can be extended
             * @class Brick
             * @extends Class
             */
            return {
                use: [Observable, Maskable, EventRouting],

                /**
                 * renderTo is the selector that will be used
                 * to render the component to after init
                 * @private
                 */
                _renderTo: null,
                _renderToElement: null,

                /**
                 * A list of additional listeners that were loaded from additional requirejs modules specified in the
                 * 'listeners' setup element.
                 *
                 * @var {Object}
                 *
                 * @private
                 */
                _listeners: null,

                /**
                 * the components ID, every component has one
                 * it will be used to fire events from a given element
                 * @private
                 */
                _id: null,

                /**
                 * the first element that was found matching the renderTo selector
                 * @var HTMLElement
                 * @private
                 */
                _element: null,

                /**
                 * indicates component has been rendered
                 * @var {bool}
                 */
                _isRendered: false,

                /**
                 * @var HTMLElement
                 */
                _componentElement: null,

                /**
                 * currently no construction needed, but this way we can use this__super()
                 * in all extending components to be consistent anf future proof
                 *
                 * @private
                 */
                __construct: function () {
                    this._listeners = {};
                },

                /**
                 * init will setup component from config and will ensure the component
                 * executes all dom ui related tasks as soon as the dom is ready
                 * @param {Object} setup
                 */
                init: function (setup) {
                    if (setup) {
                        this.setupFromConfig(setup);
                    }

                    this.setupObservable();
                    this.attachListeners();
                    App.ready(this.onDomReady, this);
                },

                attachListeners: function () {
                    this.on('disable', this.onDisable, true);
                    this.on('enable', this.onEnable, true);
                    this.on('show', this.onShow, true);
                    this.on('hide', this.onHide, true);

                    this.initEventRouting();
                },

                createObservablePromiseResolver: function (resolve) {
                    this.on(
                        'afterDomRender',
                        this.observablePromiseResolveHandler,
                        true,
                        false,
                        false
                    );
                },

                observablePromiseResolveHandler: function (e, options) {
                    if (options.getTarget().id == this.getId()) {
                        this.resolveObservablePromise();
                        this.un('afterDomRender', this.observablePromiseResolveHandler, true, false);
                    }
                },

                /**
                 *
                 * @param parent
                 * @return bool
                 */
                isDescendant: function (parent) {
                    var child = this.getElement();
                    if (parent.compareDocumentPosition !== undefined) {
                        return parent.compareDocumentPosition(child) & Node.DOCUMENT_POSITION_CONTAINED_BY;
                    }
                    var node = child.parentNode;
                    while (node !== null) {
                        if (node === parent) {
                            return true;
                        }
                        node = node.parentNode;
                    }
                    return false;
                },

                /**
                 * this method is what gets called at the end
                 * of the component initialization process and only as
                 * soon as the DOM is ready for manipulation
                 * it allows components to set/remove handlers
                 * as part of the component initialization process
                 * after its finished it will fir a component initialized event
                 * that provides access an HTMLElement wrapped that implements Observable and the element id
                 */
                onDomReady: function () {
                    if (this.observablePromiseEnabled) {
                        if (this.isVisible()) {
                            this.resolveObservablePromise();
                            _renderComponent.call(this);
                        }

                        this.on('domRendered', this.onDomRendered, false, false, false);
                        this.on('domHidden', this.onDomHidden, false, false, false);
                    } else if (this.getRenderTo()) {
                        _renderComponent.call(this);
                    }
                    this.fire('componentInitialized', {element: this.getComponentElement(), id: this.getId()});
                },

                /**
                 * wraps the current component's DOM element into an HTMLElement and returns it
                 */
                getComponentElement: function () {
                    if (!this._componentElement) {
                        this._componentElement = new Element(this.getElement());
                    }

                    return this._componentElement;
                },

                onDomHidden: function (e, options) {
                    if (options.getTarget().id == this.getId() || this.isDescendant(options.getTarget())) {
                        this.un('afterDomRender', this.observablePromiseResolveHandler, true, false);
                        this.resetObservablePromise();
                    }
                },

                /**
                 * this method will call all known object methods from the object
                 * to setup the object as far as possible from configuration
                 * @protected
                 */
                setupFromConfig: function (setup) {
                    if (setup === undefined) {
                        throw new SyntaxError(
                            'setupFromConfig did not receive a setup parameter, ' +
                            'ensure you passed the setup to your __super method'
                        );
                    }
                    this.setRenderToFromSetup(setup);
                    this.setIdFromSetup(setup);
                    this.setChildrenFromSetup(setup);
                    this.setListenersFromSetup(setup);
                    this.setEventRoutingFromConfig(setup);
                    this.setDebugModeFromConfig(setup);
                },

                setDebugModeFromConfig: function (setup) {
                    if (setup.debugMode !== undefined) {
                        this.setObservableDebugMode(setup.debugMode);
                    }
                },

                onDisable: function (options, event) {
                    this.disable();
                    return false;
                },

                onEnable: function (options, event) {
                    this.enable();
                    return false;
                },

                /**
                 * adds components available in the config as child components
                 * @param setup
                 */
                setChildrenFromSetup: function (setup) {
                    if (setup.components !== undefined &&
                        setup.components.length !== undefined &&
                        setup.components.length > 0
                    ) {
                        this.createBricks(setup.components);
                    }
                },

                /**
                 * Retrieves a list of listeners that were loaded from additional listener modules.
                 *
                 * @return {Object}
                 */
                getListeners: function () {
                    return this._listeners;
                },

                /**
                 * Loads additional modules containing listeners for this component and registers the contained
                 * listeners internally.
                 *
                 * @param setup
                 */
                setListenersFromSetup: function (setup) {
                    if (setup.listeners !== undefined &&
                        setup.listeners.length !== undefined &&
                        setup.listeners.length > 0
                    ) {
                        require(setup.listeners, function () {
                            for (var i = 0; i < arguments.length; ++i) {
                                for (var name in arguments[i]) {
                                    if (arguments[i].hasOwnProperty(name)) {
                                        if (this._listeners.hasOwnProperty(name)) {
                                            throw new Error('The listener ' + name + ' is already registered!');
                                        } else {
                                            this._listeners[name] = arguments[i][name];
                                        }
                                    }
                                }
                            }
                        }.bind(this));
                    }
                },

                /**
                 * Requests that the specified components by created.
                 *
                 * @member {Array} components
                 */
                createBricks: function (components) {
                    this.fire('createBricks', {children: components}, false, false, false);
                },

                /**
                 * set the id as specified in the provided setup
                 * @param setup
                 */
                setIdFromSetup: function (setup) {
                    if (setup.id) {
                        this.setId(setup.id);
                    }
                },

                /**
                 * sets renderTo as specified in the provided setup
                 * @param setup
                 */
                setRenderToFromSetup: function (setup) {
                    if (setup.renderTo) {
                        this.setRenderTo(setup.renderTo);
                    }
                },

                /**
                 * set the ID of a component
                 * @param id
                 * @return this
                 */
                setId: function (id) {
                    this._id = id;
                    return this;
                },

                /**
                 * returns the set component id
                 * @return string
                 */
                getId: function () {
                    return this._id;
                },

                /**
                 * sets the dom selector to render the component to
                 * this should be a unique selector as only one element can be used per
                 * component
                 * @param selector
                 * @return this
                 */
                setRenderTo: function (selector) {
                    this._renderTo = selector;
                    return this;
                },

                /**
                 * get the currently set renderTo selector
                 */
                getRenderTo: function () {
                    return this._renderTo;
                },

                /**
                 * get the currently set renderTo selector
                 */
                getRenderToElement: function () {
                    if (!(this._renderToElement instanceof Element)) {
                        this._renderToElement = new Element($(this.getRenderTo()).shift());
                    }
                    return this._renderToElement;
                },

                /**
                 * sets the HTMLElement for use with this component
                 * @param element
                 * @return this
                 */
                setElement: function (element) {
                    this._element = element;
                    return this;
                },

                /**
                 * returns the currently set HTMLElement
                 * the component is set to render to
                 * this is also the element that will trigger
                 * events when set (when not set the document triggers events instead
                 * @return HTMLElement
                 */
                getElement: function () {
                    if (!this._element) {
                        var selector = this.getId();
                        if (!selector) {
                            throw new Error('No component id is set, but is required by getElement');
                        }
                        var element = document.getElementById(selector);
                        if (!element) {
                            throw new Error('Element not found, invalid id [' + selector + '] given.');
                        }
                        this.setElement(element);
                    }
                    return this._element;
                },

                /**
                 * Returns a reference to the form that is relevant to the current component. Components don't have to
                 * be inside a form, so this function can return null.
                 *
                 * @return {*}
                 */
                getForm: function () {
                    return null;
                },

                /**
                 * Returns the current component's form as Element or null if this component is not part of a form.
                 */
                getFormElement: function () {
                    if (!this.getForm()) {
                        return undefined;
                    }
                    return new Element(this.getForm());
                },

                /**
                 * render is called on DomReady event
                 * the base component doesn't need rendering,
                 * but this is the method called and should be overwritten
                 * if the extending component needs more rendering
                 */
                render: function () {
                    var target = $(this.getRenderTo()).shift();
                    if (target && !target.contains(this.getElement())) {
                        target.appendChild(this.getElement());
                    }
                },

                /**
                 * disables the component to prevent further user interaction
                 */
                disable: function () {
                    this.mask();
                    this.fire('componentDisabled', {id: this.getId(), componentType: this.getElement().getAttribute('data-componentType')});
                },

                /**
                 * enables the component to prevent further user interaction
                 */
                enable: function () {
                    this.unmask();
                    this.fire('componentEnabled', {id: this.getId(), componentType: this.getElement().getAttribute('data-componentType')});
                },

                onShow: function () {
                    this.show();
                },

                onHide: function () {
                    this.hide();
                },

                /**
                 * opens the window
                 */
                show: function () {
                    this.getComponentElement().show();
                    this.fire('componentShown', {id: this.getId(), componentType: this.getElement().getAttribute('data-componentType')});
                },

                isVisible: function () {
                    var element = this.getElement();
                    while (element) {
                        if (/\bhidden\b/.test(element.className)) {
                            return false;
                        }
                        element = element.parentNode;
                    }
                    return true;
                },

                /**
                 * closes the window
                 */
                hide: function () {
                    this.getComponentElement().hide();
                    this.fire('componentHidden', {id: this.getId(), componentType: this.getElement().getAttribute('data-componentType')});
                },

                /**
                 * Fired when DOM is ready of child components
                 * @param e
                 * @param options
                 */
                onDomRendered: function (e, options) {
                    if ((options.getTarget().id == this.getId() || this.isDescendant(options.getTarget())) && this.isVisible()) {
                        if (!this._isRendered) {
                            _renderComponent.call(this);
                        }
                        this.fire('afterDomRender', {id: this.getId()}, false);
                    }
                },
            };
        });
    }
);

