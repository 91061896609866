
define(
    'Inventis/Bundle/BricksBundle/Brick/Button',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Mixins/Handling',
    ],
    function (Brick, Handling) {
        'use strict';

        /**
         * @class Button
         * @extends Brick
         */
        var Button = Brick.extend({
            use: [Handling],

            _event: null,

            /**
             * @var {bool}
             */
            _isDisabled: false,

            /**
             * @var {Object}
             */
            _eventData: null,

            __construct: function () {
                this.__super(arguments);
                this._eventData = {};

                // Properties from the Handling mixin.
                this._handling = [];
            },

            init: function (setup) {
                this.__super(setup);
                this.attachHandlingListeners();
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setEventFromSetup(setup);
                this.setEventDataFromSetup(setup);

                // Properties for the handling mixin.
                this.setHandlingFromSetup(setup);
            },

            setEventFromSetup: function (setup) {
                if (setup.event !== undefined) {
                    this.setEvent(setup.event);
                }
            },

            setEventDataFromSetup: function (setup) {
                if (setup.eventData !== undefined) {
                    this.setEventData(setup.eventData);
                }
            },

            attachListeners: function () {
                this.__super();

                this.on('click', this.onClick, true);
            },

            /**
             * Returns a reference to the form that is relevant to the current component. Components don't have to
             * be inside a form, so this function can return null.
             *
             * @return {*}
             */
            getForm: function () {
                return this.getElement().form;
            },

            /**
             * listens on the default click event
             * so that we can translate it into another event
             */
            onClick: function (e) {
                if (!this._isDisabled) {
                    this.fire(this.getEvent(), this.getEventData());
                }

                return false;
            },

            disable: function () {
                this.__super();
                this._isDisabled = true;

                if (this.getElement().disabled !== undefined) {
                    this.getElement().disabled = true;
                }
            },

            enable: function () {
                this.__super();
                this._isDisabled = false;

                if (this.getElement().disabled !== undefined) {
                    this.getElement().disabled = false;
                }
            },

            /**
             *
             * @param event
             * @return {*}
             */
            setEvent: function (event) {
                this._event = event;
                return this;
            },

            /**
             *
             * @return {null}
             */
            getEvent: function () {
                return this._event;
            },

            setEventData: function (data) {
                this._eventData = data;
                return this;
            },

            getEventData: function () {
                return this._eventData;
            },
        });
        return Button;
    }
);

