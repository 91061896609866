
define(
    'Inventis/Bundle/BricksBundle/Translator',[
        'Inventis/Bundle/BricksBundle/Config',
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Application',
        'Inventis/Bundle/BricksBundle/DelayedTask',
    ],
    function (Config, console, App, DelayedTask) {
        'use strict';

        var Translator = (function () {
            return {
                // Grab translation from the window object and make them our own
                translations: Config.translations || [],
                newTranslations: [],
                translationTask: false,

                // init all public scope functions that are required before onready
                /**
                 * adds a string to the list of translations
                 * it's these translations that are send to the server on timers
                 * so that we group them together in batches rather then sending everything off
                 * as a separate request
                 *
                 * @param key - key of the to be translated string
                 * @param str - the to be translated string
                 * (in de default translation language which at time of writing = nl)
                 */
                addTranslatable: function (key, str) {
                    this.translations[key] = str;// make sure its also available and accessible (although in default language)
                    this.newTranslations.push([key, str]);
                    // start the timer as new translations have arrived
                    this.startTranslationPushTaskDelay();
                },

                startTranslationPushTaskDelay: function () {
                    if (!this.translationTask) {
                        // delay a second to see if more translations follow
                        this.translationTask = new DelayedTask(1000);
                    }
                    // delay task as there might be more translations we want to add
                    this.translationTask.delay(this.pushTranslations.bind(this));
                },

                /**
                 * this method will return the translated version of a given string
                 * its the method that the _() helper uses to get its results
                 */
                getTranslation: function (key, str) {
                    if (typeof this.translations[key] === 'undefined') {
                        // we need to get this send to the server so that it can be translated
                        this.addTranslatable(key, str);
                    }
                    // we can now safely assume the translation is available (though maybe in the wrong language)
                    return this.translations[key];
                },

                /**
                 * this method pushes the available translations to the server
                 * it is tasked to do so after a second if new translations are found
                 * this should allow ample time for the unit to build up and push all new translations
                 * to the addTranslatable method
                 */
                pushTranslations: function () {
                    var toSend = this.newTranslations;
                    // make sure a new task can be created
                    this.translationTask = false;
                    // reset as this is what we have will be sending, everything else will fall under a new request
                    this.newTranslations = [];

                    App.ajax(
                        Config.adminUrl + 'translations/savenewtranslations/',
                        {translations: App.JSON.encode(toSend)},
                        function (response) {
                            if (response.success === undefined) {
                                console.log('failed saving translations, make sure the keys are valid', toSend);
                            } else if (response.success) {
                                console.log('saved ' + toSend.length + ' new translations.', toSend);
                            } else {
                                // lets try again adding out failed translations
                                this.newTranslations.push.apply(this.newTranslations, toSend);
                                this.startTranslationPushTaskDelay();
                            }
                        },
                        this
                    );
                },
                translate: function (key, str, params) {
                    if (str === undefined || typeof str !== 'string') {
                        console.error('Translation requests require both a key and a default string, you gave:', key, str);
                    }
                    var translation = this.getTranslation(key, str);
                    translation = translation ? translation : str;

                    if (params !== undefined && params.length > 0) {
                        translation = App.String.format(translation.toString(), params);
                    }

                    return translation;
                },
            };
        })();

        /**
         * Generic translation function, this should catch all static strings and
         * run them through a translation routine.
         * Its merely a helper for Translator.getTranslation() with the added benefit of
         * formatted strings
         */
        return Translator.translate.bind(Translator); // bind to prevent scope changes
    }
);

