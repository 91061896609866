
define(
    'Inventis/Bundle/BricksBundle/Class',[
        'Inventis/Bundle/BricksBundle/Mixin',
    ],
    function (Mixin) {
        // "use strict";

        var initializing = false,
            fnTest = /xyz/.test(function () {
                xyz;
            }) ? /\b__super\b/ : /.*/;

        /**
         * The base Class implementation (does nothing)
         * @class Class
         */
        var Class = function () {};

        // Create a new Class that inherits from this class
        Class.extend = function (prop) {
            var __super = this.prototype;
            // allows you to work with private methods and properties
            if (typeof prop == 'function') {
                prop = new prop();
            }
            // Instantiate a base class (but only create the instance,
            // don't run the init constructor)
            initializing = true;
            var prototype = new this();
            initializing = false;

            // if multiple abstract definitions are available carry them over to the concrete implementation
            if (prop.abstract && prop.abstract.length && this.prototype.abstract) {
                var abstractMethods = this.prototype.abstract;
                abstractMethods.splice(0, 0, prop.abstract);
                var uniques = function (array) {
                    var o = {},
                        i,
                        l = array.length,
                        r = [];
                    for (i = 0; i < l; i += 1) {
                        o[array[i]] = array[i];
                    }
                    for (i in o) {
                        r.push(o[i]);
                    }
                    return r;
                };
                prop.abstract = uniques(abstractMethods);
            }

            // first do mixins so that classes still get the change to overwrite them
            if (prop.use) {
                prop = Mixin(prop);
            }
            // Copy the properties over onto the new prototype
            for (var name in prop) {
                // Check if we're overwriting an existing function
                prototype[name] =
                    typeof prop[name] == 'function'
                        && typeof __super[name] == 'function'
                        && fnTest.test(prop[name])

                        ? (function (name, fn) {
                            // grant function access to the super class function
                            return function () {
                                // ensure that the super function is executed in child's scope
                                // so that overriding can function as expected
                                this.__super = function () {
                                    return __super[name].apply(this, arguments);
                                };
                                // The method only need to be bound temporarily, so we
                                // remove it when we're done executing
                                var ret = fn.apply(this, arguments);

                                return ret;
                            };
                        })(name, prop[name])

                        : prop[name];
            }

            /**
             * check if the concrete implementation holds
             */
            function abstractValidation() {
                if (this.abstract && this.abstract.length) {
                    var missingAbstracts = [];
                    for (var i = 0; i < this.abstract.length; i++) {
                        if (!(this.abstract[i] in this)) {
                            missingAbstracts.push(this.abstract[i]);
                        }
                    }
                    if (missingAbstracts.length) {
                        throw new Error(
                            'Object does not implement all abstract methods, implement [' + missingAbstracts.join(', ') + ']'
                        );
                    }
                }
                delete this.abstract;
            }
            // The dummy class constructor
            function Class() {
                // All construction is actually done in the init method
                if (!initializing) {
                    abstractValidation.call(this);
                    if (this.__construct) {
                        this.__construct.apply(this, arguments);
                    }
                }
            }

            // Populate our constructed prototype object
            Class.prototype = prototype;

            // Enforce the constructor to be what we expect
            Class.prototype.constructor = Class;

            // And make this class extensible
            Class.extend = arguments.callee;

            return Class;
        };
        return Class;
    }
);

