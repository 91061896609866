
define(
    'Inventis/Bundle/BricksBundle/Brick/PageOverlay',[
        'Inventis/Bundle/BricksBundle/Brick/Panel',
        'Inventis/Bundle/BricksBundle/Button',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Panel, Button, Element, $) {
        'use strict';

        var pageOverlayList = [],
            bodyElement = new Element(document.body);

        /*
         * This window could be nested, close the last opened window first, which will be this window if there
         * are no others opened. It is assumed that nested dialogs are created after their parent dialog.
         */
        var popShownPageOverlay = function () {
            for (var i = pageOverlayList.length - 1; i >= 0; --i) {
                if (pageOverlayList[i].isShown()) {
                    pageOverlayList[i].hide();
                    return false; // stop
                }
            }
        };

        bodyElement.on('keydown', function (e) {
            if (e.keyCode === 27) {
                return popShownPageOverlay();
            }
            return true;
        });

        /**
         * @class PageOverlay
         * @extends Panel
         */
        var PageOverlay = Panel.extend({
            closeButtonSelector: null,

            /**
             * @var Button
             * @private
             */
            closeButton: null,

            /**
             * @private
             */
            parent: null,

            /**
             * Sets the properties from the given configuration
             * @param {Object} setup the setup received from the php components
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setCloseButtonSelectorFromSetup(setup);
            },

            setCloseButtonSelectorFromSetup: function (setup) {
                if (setup.closeButtonSelector) {
                    this.closeButtonSelector = setup.closeButtonSelector;
                }
            },

            onDomReady: function () {
                this.__super();
                // A button in the window is clicked
                this.on('buttonClicked', this.onButtonClicked, true);
            },

            /**
             * ataches to the init to set up the close button
             * @param {Object} setup
             */
            init: function (setup) {
                this.__super(setup);
                this.initCloseButton();
            },

            initCloseButton: function () {
                var closeButton = this.getCloseButton();

                if (closeButton === undefined || closeButton === null) {
                    var eventData = {};
                    eventData.callee = this.getId();
                    eventData.action = 'close';
                    closeButton = $(this.closeButtonSelector, this.getElement()).shift();
                    if (closeButton) {
                        this.setCloseButton(new Button(
                            closeButton,
                            eventData
                        ));
                    }
                }
            },

            isShown: function () {
                return this.getElement().classList.contains('-active');
            },

            /**
             * sets the close button to the provided object
             * @param {Button} closeButton
             * @return {Window}
             */
            setCloseButton: function (closeButton) {
                this.closeButton = closeButton;
                return this;
            },

            /**
             * returns the set close button
             * @return {Button}
             */
            getCloseButton: function () {
                return this.closeButton;
            },

            /**
             * called when the close button is pressed
             * @param {MouseEvent} event
             * @param {Object} options
             */
            onButtonClicked: function (event, options) {
                if (options.callee === this.getId() && options.action === 'close') {
                    this.hide();
                }
            },

            show: function () {
                this.getElement().classList.add('-active');
                pageOverlayList.push(this);
                this.fire('pageOverlayActive');
            },

            hide: function () {
                this.getElement().classList.remove('-active');
                pageOverlayList = pageOverlayList.filter(function (entry) {
                    return entry !== this;
                }.bind(this));
                this.fire('pageOverlayClosed', {}, true);
            },
        });

        return PageOverlay;
    }
);

