
/**
 * an observable version of a HTMLElement
 */
define(
    'Inventis/Bundle/BricksBundle/HTML/Element',[
        'Inventis/Bundle/BricksBundle/Mixins/Observable',
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Mixins/Maskable',
    ],
    function (Observable, Class, Maskable) {
        'use strict';

        var __hiddenClass = 'h-hidden';

        var Element = Class.extend({
            use: [Observable, Maskable],
            _element: null,

            /**
             * create an observable version of a default HTMLElement
             * @param {HTMLElement} Element
             */
            __construct: function (Element) {
                this._element = Element;
                this.setupObservable();
            },

            getElement: function () {
                return this._element;
            },

            /**
             * attaches a listener to its own element
             *
             * @param eventName
             * @param callback
             * @param {Boolean} [capture] [default: false] listen to reverse stack (true) or normal bubble stack (false)
             */
            on: function (eventName, callback, capture) {
                Observable.on.call(this, eventName, callback, true, capture);
            },

            /**
             * shows an html element by removing the hidden class from it
             */
            show: function () {
                if (this.isHidden()) {
                    this.removeClass(__hiddenClass);
                    this.fire('domRendered');
                }
                return this;
            },

            /**
             * hides an element by adding the hidden class to it
             */
            hide: function () {
                if (!this.isHidden()) {
                    this.addClass(__hiddenClass);
                    this.fire('domHidden');
                }
                return this;
            },

            isHidden: function () {
                return this.classExists(__hiddenClass);
            },

            /**
             * empty the innerHTML of the element the fastest way possible
             * @link http://jsperf.com/innerhtml-vs-removechild/15
             */
            empty: function () {
                var element = this.getElement();
                // manually clearing last child is fastest benchmark
                while (element.lastChild) {
                    element.removeChild(element.lastChild);
                }
                return this;
            },

            /**
             *
             * @param {String} className
             */
            classExists: function (className) {
                var classes = this.getClasses();
                return classes.indexOf(className) !== -1;
            },

            addClass: function (className) {
                var classes = this.getClasses();
                classes.push(className);
                this.getElement().className = classes.join(' ');
                return this;
            },

            removeClass: function (className) {
                var index,
                    classes = this.getClasses();

                if ((index = classes.indexOf(className)) !== -1) {
                    classes.splice(index, 1);
                }
                this.getElement().className = classes.join(' ');
                return this;
            },

            getClasses: function () {
                return this.getElement().className ? this.getElement().className.split(' ') : [];
            },

            setInnerHtml: function (content) {
                this.getElement().innerHTML = content;
            },
        });

        return Element;
    }
);

