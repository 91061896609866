
/**
 * Simple button, not a complete component.
 */
define(
    'Inventis/Bundle/BricksBundle/Button',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Mixins/Observable',
    ],
    function (Class, Observable, Maskable) {
        'use strict';

        var Button = Class.extend({
            use: [Observable],

            _element: null,
            _disabled: null,

            /**
             * create a button that will listen to the provided element
             * and fire a buttonClicked event with clickEventData in the event
             * @param attachedItem
             * @param clickEventData
             * @private
             */
            __construct: function (attachedItem, clickEventData, eventName) {
                if (!(attachedItem instanceof HTMLElement)) {
                    throw new Error('You cannot create a button without specifying a valid HTMLElement to attach to.');
                }
                this.setElement(attachedItem);
                this.setupObservable();
                this.attachEventListener(clickEventData, eventName);
            },

            /**
             * returns the element the button is attached to
             * this is required by the observable if we want to fire/listen to
             * events on our own element
             * @return {null}
             */
            getElement: function () {
                return this._element;
            },

            /**
             * set the element to which the button needs to be attached
             * @param element
             * @return {*}
             */
            setElement: function (element) {
                this._element = element;
                return this;
            },

            /**
             * attaches the click event listener that will fire a buttonClicked event
             * with certain event data
             * @param eventData
             */
            attachEventListener: function (eventData, eventName) {
                eventName = eventName || 'buttonClicked';
                this.on('click', function (e) {
                    e.preventDefault();
                    if (!this._disabled) {
                        this.fire(eventName, eventData);
                    }
                    return false;
                }, true);
            },

            disable: function () {
                var element = this.getElement(),
                    classes = element.className.split(' ');
                if (classes.indexOf('disabled') === -1) {
                    this._disabled = true;
                    classes.push('disabled');
                    element.className = classes.join(' ');
                }
            },

            enable: function () {
                var element = this.getElement(),
                    classes = element.className.split(' '),
                    index = classes.indexOf('disabled');
                if (index !== -1) {
                    this._disabled = false;
                    classes.splice(index, 1);
                    element.className = classes.join(' ');
                }
            },

            disabled: function () {
                return this._disabled;
            },
        });

        return Button;
    }
);

