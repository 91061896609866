
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Status',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
    ],
    function (Component) {
        'use strict';

        var Status = Component.extend({
            _datePanelId: null,
            _statusSelectId: null,

            _datePanel: null,
            _statusSelect: null,

            /**
             * overwrite and attach custom setup handlers
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setDependenciesFromSetup(setup);
                this.on('componentInitialized', this.onComponentInitialized);
                this.on('change', this.onSelectChange, true);
            },

            updateDatePanelVisibility: function () {
                if (!this._datePanel || !this._statusSelect) {
                    return;
                }

                this._datePanel.fire((this._statusSelect.getValue() === 'pending') ? 'show' : 'hide', {}, false);
            },

            onSelectChange: function (event, options) {
                if (options.id === this._statusSelectId) {
                    this.updateDatePanelVisibility();
                }
            },

            setDependenciesFromSetup: function (setup) {
                if (setup.dependencies) {
                    this.setDatePanelFromSetup(setup);
                    this.setStatusSelectFromSetup(setup);
                }
            },

            setDatePanelFromSetup: function (setup) {
                if (setup.dependencies && setup.dependencies.datePanel) {
                    this._datePanelId = setup.dependencies.datePanel;
                }
            },

            setStatusSelectFromSetup: function (setup) {
                if (setup.dependencies && setup.dependencies.statusSelect) {
                    this._statusSelectId = setup.dependencies.statusSelect;
                }
            },

            onComponentInitialized: function (event, options) {
                if (options.id === this._statusSelectId) {
                    this._statusSelect = options.element;
                } else if (options.id === this._datePanelId) {
                    this._datePanel = options.element;
                }

                this.updateDatePanelVisibility();
            },
        });
        return Status;
    }
);

