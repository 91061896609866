
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Tags',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input',
        'Inventis/Bundle/BricksBundle/Button',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Input, Button, ApiAwareMixin, $) {
        'use strict';

        return Input.extend({
            use: [ApiAwareMixin],

            /**
             * The separator to place between the different tags in the field.
             *
             * @var {?string}
             */
            _separator: null,

            /**
             * A list of most used tags to display to the user so he can click them to add them.
             *
             * @var {?string[]}
             */
            _mostUsed: null,

            /**
             * The field to fetch from the most used tags returned by the data provider.
             *
             * @var {?string}
             */
            _displayField: null,

            /**
             * Constructor.
             */
            __construct: function () {
                this.__super(arguments);
                this._mostUsed = [];
            },

            /**
             * @inheritDoc
             */
            attachListeners: function () {
                this.__super();

                this.on('reload', this.onReload, true);

                var formElement = this.getFormElement();

                if (formElement) {
                    formElement.on('recordSaved', this.createFormEventsHandler(this.onRecordSaved));
                }
            },

            /**
             * @inheritDoc
             */
            onDomReady: function () {
                this.__super();

                // Attach the event listeners so we don't have to recreate all the buttons when they are already set in
                // the template.
                this.attachButtons();
            },

            /**
             * Called when a reload is fired.
             *
             * @member {String} event
             * @member {Object} options
             */
            onReload: function (event, options) {
                var data = {};

                for (var x in options) {
                    if (typeof options[x] !== 'function') {
                        data[x] = options[x];
                    }
                }

                this.fetch(data);
            },

            /**
             * Called when the record in the parent form is saved.
             *
             * @member {String} e
             * @member {Object} options
             */
            onRecordSaved: function (event, options) {
                this.fetch();
            },

            /**
             * Initiates an AJAX call to fetch the most used tags and reloads them.
             *
             * @param {Object=} data Data to pass to the request.
             */
            fetch: function (data) {
                data = data || {};

                this.apiRequest('getMostUsed', data, function (response) {
                    if (response.success) {
                        this.setMostUsed(response.result);
                        this.recreateTags();
                    } else {
                        throw new Error('Loading tags through AJAX failed!');
                    }
                });
            },

            /**
             * @inheritDoc
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setDisplayFieldFromSetup(setup);
                this.setSeparatorFromSetup(setup);
                this.setMostUsedFromSetup(setup);
                this.setApiFromConfig(setup);
            },

            /**
             * Reads the display field parameter from the setup, if it is present.
             *
             * @param {Object} setup
             */
            setDisplayFieldFromSetup: function (setup) {
                if (setup.displayField !== undefined) {
                    this.setDisplayField(setup.displayField);
                }
            },

            /**
             * Sets the display field to use.
             *
             * @param {String} displayField
             *
             * @return this
             */
            setDisplayField: function (displayField) {
                this._displayField = displayField;
                return this;
            },

            /**
             * Retrieves the display field.
             *
             * @return {String}
             */
            getDisplayField: function () {
                return this._displayField;
            },

            /**
             * Reads the separator parameter from the setup, if it is present.
             *
             * @param {Object} setup
             */
            setSeparatorFromSetup: function (setup) {
                if (setup.separator !== undefined) {
                    this.setSeparator(setup.separator);
                }
            },

            /**
             * Sets the separator to use for the tags in the field.
             *
             * @param {String} separator
             *
             * @return this
             */
            setSeparator: function (separator) {
                this._separator = separator;
                return this;
            },

            /**
             * Retrieves the separator placed between the tags.
             *
             * @return {String}
             */
            getSeparator: function () {
                return this._separator;
            },

            /**
             * Reads the most used items from the setup, if present.
             *
             * @param {Object} setup
             */
            setMostUsedFromSetup: function (setup) {
                if (setup.mostUsed !== undefined) {
                    this.setMostUsed(setup.mostUsed);
                }
            },

            /**
             * Sets the most used items. Will not update the visually displayed items, see recreateTags for this.
             *
             * @param {Object[]} mostUsed
             *
             * @return this
             */
            setMostUsed: function (mostUsed) {
                this._mostUsed = mostUsed;
                return this;
            },

            /**
             * Retrieves the most used tags.
             *
             * @return {String[]}
             */
            getMostUsed: function () {
                return this._mostUsed;
            },

            /**
             * Recreates the tags based on the currently set most used tags.
             */
            recreateTags: function () {
                var mostUsed = this.getMostUsed(),
                    displayField = this.getDisplayField(),
                    anchorsWrapper = $('#' + this.getId() + ' ~.tags > .tags-anchors').shift();

                while (anchorsWrapper.hasChildNodes()) {
                    anchorsWrapper.removeChild(anchorsWrapper.lastChild);
                }

                if (mostUsed.length > 0) {
                    for (var x in mostUsed) {
                        if (mostUsed.hasOwnProperty(x)) {
                            var anchorTag = document.createElement('a');
                            anchorTag.href = '#';
                            anchorTag.appendChild(document.createTextNode(mostUsed[x][displayField]));

                            anchorsWrapper.appendChild(anchorTag);
                        }
                    }

                    this.attachButtons();
                }
            },

            /**
             * Attaches event listeners (buttons) to the most used tags so they become clickable.
             */
            attachButtons: function () {
                var anchors = $('#' + this.getId() + ' ~.tags > .tags-anchors > a');

                for (var x in anchors) {
                    if (anchors.hasOwnProperty(x)) {
                        var button = new Button(anchors[x], {text: anchors[x].innerHTML});
                        button.on('buttonClicked', this.onMostUsedTagClicked.bind(this), true);
                    }
                }
            },

            /**
             * Called when one of the most used tags is clicked.
             *
             * @member {String} event
             * @member {Object} options
             */
            onMostUsedTagClicked: function (event, options) {
                var separator = this.getSeparator() + ' ',
                    element = this.getElement(),
                    value = element.value;

                if (value.length >= separator.length &&
                    value.substring(value.length - separator.length, value.length) !== separator
                ) {
                    value += separator;
                }

                value += options.text;

                element.value = value;
            },
        });
    }
);

