define(
    'Inventis/Bundle/BricksBundle/Brick/Grid/BricksBundleGridRendererStrategy',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Promise',
        'Inventis/Bundle/BricksBundle/Grid/Grid',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Class, Promise, Grid, Console) {
        'use strict';

        /**
         * @class BricksBundleGridRenderStrategy
         * @implements {RenderStrategy}
         */
        return Class.extend({
            __construct: function (gridBrick, config) {
                this.gridBrick = gridBrick;
                this.config = this.processConfig(config);
                this.grid = this.createGrid(this.config.grid);
            },

            createGrid: function (gridConfig) {
                return new Grid(gridConfig);
            },

            /**
             * @param {RenderStrategyConfig} config
             * @return {RenderStrategyConfig}
             */
            processConfig: function (config) {
                var errorMsg;

                if (!config.grid) {
                    throw new Error('Expecting a grid configuration property, but its undefined/invalid in config');
                }
                if (typeof config.grid.view !== 'object') {
                    errorMsg = 'Expecting a grid.view configuration property, but its undefined/invalid in config';
                    Console.error('Invalid renderStrategy config', config, errorMsg);
                    throw new Error(errorMsg);
                }
                if (typeof config.grid.store !== 'object') {
                    errorMsg = 'Expecting a grid.store configuration property, but its undefined/invalid in config';
                    Console.error('Invalid renderStrategy config', config, errorMsg);
                    throw new Error(errorMsg);
                }
                if (config.renderers) {
                    config.grid.view.renderers = config.renderers;
                }
                if (config.modelProcessor) {
                    config.grid.view.modelProcessor = config.modelProcessor;
                }
                if (!config.grid.store.endpoint) {
                    config.grid.store.endpoint = this.gridBrick.getActionUrl('getRecords');
                }

                config.grid.store.defaultRequestData = this.gridBrick.getComponentRequestData('getRecords');

                config.idData = config.idData || 'recordId';
                config.reloadOnMove = config.reloadOnMove || true;

                return config;
            },

            attachListeners: function () {
                this.gridBrick.on('reordered', this.onReorder.bind(this), true);
                this.gridBrick.on('selectionChanged', this.onSelectionChanged.bind(this), true);
            },

            onSelectionChanged: function (event) {
                event.stopPropagation();
                if (event.selections && event.selections.length === 1) {
                    this.gridBrick.fire('recordClicked', {id: this.getNodeId(event.selections[0])});
                } else {
                    var selectedRecords = event.selections.map(function (node) {
                        return this.getNodeId(node);
                    }.bind(this));

                    this.gridBrick.fire('recordSelectionChanged', {ids: selectedRecords});
                }
            },

            onReorder: function (event) {
                if (event.newIndex !== event.oldIndex || event.newContainer !== event.oldContainer) {
                    this.gridBrick.fire('moveRecord', this.buildMoveData(event));
                }
            },

            /**
             * handle data preparation for moving records
             * this method is called when the request for moving is going to send data to the server side
             *
             * @param {Object} event the event's data that triggered a moveRecord
             *
             * @return {Object} must contain all data required by the endpoint to complete the move
             */
            buildMoveData: function (event) {
                var target,
                    data = {
                        id: this.getNodeId(event.target),
                        newIndex: event.newIndex,
                        oldIndex: event.oldIndex,
                        target: null,
                        position: null,
                    },
                    newContainerRows = event.newContainer.querySelectorAll(event.rowSelector);

                if (!this.hasNodeId(event.newContainer) || event.newContainer === event.oldContainer) {
                    if (event.newIndex > 0) {
                        data.position = 'after';
                        target = newContainerRows[event.newIndex - (event.target.querySelectorAll(event.rowSelector).length + 1)];
                        if(this.hasNodeId(event.newContainer)) {
                            while (!target.matches(event.rowSelector) || target.parentElement !== event.newContainer) {
                                target = target.parentElement;
                            }
                        }
                    } else {
                        data.position = 'before';
                        var nextRowIndex = event.newIndex;
                        do {
                            ++nextRowIndex;
                            if (nextRowIndex >= newContainerRows.length) {
                                target = null;
                                break;
                            }
                            target = newContainerRows[nextRowIndex];
                        } while (this.hasNodeId(target) && event.target.contains(target));
                    }
                    if (target) {
                        data.target = this.getNodeId(target);
                    } else {
                        data.position = 'in';
                    }
                } else {
                    data.position = 'in';
                    data.target = this.getNodeId(event.newContainer);

                }
                return data;
            },

            hasNodeId: function (targetNode) {
                return targetNode.dataset[this.config.idData] !== undefined
                    && targetNode.dataset[this.config.idData] !== null;
            },

            getNodeId: function (targetNode) {
                var id = targetNode.dataset[this.config.idData];
                if (id === undefined || id === null) {
                    throw new Error(
                        'Invalid record identification data for row node, verify the data attribute for '
                        + this.config.idData
                        + ' is present.'
                    );
                }
                return id;
            },

            /**
             * @inheritDoc
             */
            render: function () {
                this.attachListeners();
                return this.grid.render();
            },

            /**
             * @inheritDoc
             */
            redraw: function () {
                return this.grid.redraw();
            },

            /**
             * @inheritDoc
             */
            getGridInstance: function () {
                return this.grid;
            },

            /**
             * @inheritDoc
             */
            onRecordDeleted: function (identity, response) {
                return new Promise(function (resolve, reject) {
                    try {
                        var records = [];
                        if (Array.isArray(identity.id)) {
                            records = identity.id.map(function (id) {
                                return this.grid.getRecord(id);
                            }.bind(this));
                        } else {
                            records.push(this.grid.getRecord(identity.id));
                        }

                        this.refresh().then(function () {
                            if (!response.success) {
                                reject(new Error('record may not have been deleted, server indicates failure'));
                            } else {
                                resolve(records);
                            }
                        }).catch(reject);
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            refresh: function () {
                this.grid.invalidateStore();
                return this.redraw();
            },

            addFilters: function (filters) {
                return this.grid.addFilters(filters);
            },

            setFilters: function (filters) {
                return this.grid.setFilters(filters);
            },

            clearFilters: function (filters) {
                return this.grid.clearFilters(filters);
            },

            /**
             * @inheritDoc
             */
            onRecordAdded: function (data, response) {
                return new Promise(function (resolve, reject) {
                    try {
                        if (!response || response.success) {
                            this.grid.invalidateStore();
                        }
                        this.redraw().then(function () {
                            try {
                                var record = this.grid.createRecord(response ? (response.record || data) : data);
                                resolve(record);
                            } catch (e) {
                                reject(e);
                            }
                        }.bind(this)).catch(reject);
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            /**
             * @inheritDoc
             */
            onRecordMoved: function (options, response) {
                return new Promise(function (resolve, reject) {
                    try {
                        if (response.id && response.id !== options.id) {
                            if (this.config.reloadOnMove) {
                                    this.grid.reload().then(function () {
                                        // in situations with a tree we might not have the record in the base
                                        // collection, so we reload all and don't try to fetch the actual record
                                        resolve({id: response.id});
                                    }.bind(this));
                            } else {
                                this.gridBrick.apiRequest('getRecord', {id: response.id}, function (response) {
                                    if (response.success) {
                                        resolve(this.grid.updateRecord(options.id, response.record));
                                    } else {
                                        reject(new Error(
                                            'Record id changed, but failed to retrieve new data: '
                                            + (response.error || 'unknown reason')
                                        ));
                                    }
                                }.bind(this));
                            }
                        } else {
                            resolve(this.grid.getRecord(options.id));
                        }
                    } catch (e) {
                        Console.error('Failed to create record from response:', response, ' Reason: ', e.message);
                        reject(e);
                    }
                }.bind(this));
            },

            /**
             * Each render strategy is responsible for its own way of dealing with identification of targets
             * this data is used by the event handlers and passed on to the endpoint
             *
             * @param {HTMLElement} targetNode
             *
             * @return {Object}
             */
            getNodeIdentity: function (targetNode) {
                var id = this.getNodeId(targetNode);
                return {id: id};
            },
        });
    }
);

