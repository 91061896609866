
define(
    'Inventis/Bundle/BricksBundle/Brick/Module',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
    ],
    function (Component) {
        'use strict';

        var Module = Component.extend({

            setupFromConfig: function (setup) {
                this.__super(setup);
            },
        });
        return Module;
    }
);

