
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/LanguagePanel',[
        'Inventis/Bundle/BricksBundle/Brick/Panel',
    ],
    function (Panel) {
        'use strict';

        var LanguagePanel = Panel.extend({
            _language: null,

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setLanguageFromSetup(setup);
            },

            setLanguageFromSetup: function (setup) {
                if (setup.language) {
                    this.setLanguage(setup.language);
                }
            },

            setLanguage: function (language) {
                this._language = language;
                return this;
            },

            getLanguage: function () {
                return this._language;
            },

            onDomReady: function () {
                this.on('translationPanelLanguageStateChange', function (e, options) {
                    if (options.isFromParent() && options.language === this.getLanguage()) {
                        if (options.enabled) {
                            this.enable();
                        } else {
                            this.disable();
                        }
                    }
                });
            },
        });
        return LanguagePanel;
    }
);

