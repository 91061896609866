define(
    'Inventis/Bundle/BricksBundle/Brick/Grid/ComponentRequestAction',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/RemoteAction',
    ],
    /**
     * @param {ActionToolbarPlugin.RemoteAction} RemoteAction
     *
     * @return {GridBrick.ComponentRequestAction}
     */
    function (RemoteAction) {
        'use strict';

        /**
         * @class GridBrick.ComponentRequestAction
         * @extends ActionToolbarPlugin.RemoteAction
         * @abstract
         */
        return RemoteAction.extend({

            processConfig: function (config) {
                if (!config.componentAction || !config.componentId) {
                    throw new Error(
                        'Invalid ComponentRequestAction configuration, requires both componentAction and componentId,' +
                        ' got: ' + JSON.stringify(config)
                    );
                }
                return Object.assign(
                    this.__super(config),
                    {
                        supportsMultiple: config.supportsMultiple ? config.supportsMultiple : false,
                        componentAction: config.componentAction,
                        componentId: config.componentId,
                    }
                );
            },

            getBody: function (/* selections*/) {
                throw new Error(
                    'ComponentRequestAction is an abstract implementation and requires you to implement getBody'
                );
            },
        });
    }
);

