define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/Action',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Class, Promise) {
        'use strict';

        /**
         * @abstract
         *
         * @name ActionToolbarPlugin.Action#getName
         *
         * @return {String}
         */
        /**
         * @abstract
         *
         * @protected
         *
         * @name ActionToolbarPlugin.Action#doExecute
         *
         * @param {HTMLElement[]} selections
         *
         * @return {Promise}
         */

        /**
         * @class ActionToolbarPlugin.Action
         * @extends Class
         */
        return Class.extend({
            abstract: ['doExecute'],

            config: null,

            /**
             * @private
             *
             * @var {Grid}
             */
            grid: null,

            __construct: function (grid, config) {
                this.grid = grid;
                this.config = this.processConfig(config);
            },

            /**
             * returns the grid this action is initiated for
             *
             * @return {Grid}
             */
            getGrid: function () {
                return this.grid;
            },

            /**
             * @protected
             *
             * @param {Object} config
             *
             * @return {Object}
             */
            processConfig: function (config) {
                return {
                    name: config.name || '<unknown>',
                    idData: config.idData || 'recordId',
                    supportsMultiple: config.supportsMultiple !== undefined ? config.supportsMultiple : true,
                    iconClass: config.iconClass || '',
                    groupName: config.groupName || '',
                    groupIconClass: config.groupIconClass || '',
                    activateOnDoubleClick: config.activateOnDoubleClick || false
                };
            },

            /**
             * @return {Object}
             */
            getModel: function () {
                return {
                    name: this.getName(),
                    iconClass: this.getIconClass(),
                    groupName: this.getGroupName(),
                    groupIconClass: this.getGroupIconClass(),
                };
            },

            getGroupName: function () {
                return this.config.groupName;
            },

            getGroupIconClass: function () {
                return this.config.groupIconClass;
            },

            /**
             * @return {String}
             */
            getIconClass: function () {
                return this.config.iconClass;
            },

            /**
             * get the action's name for display purposes
             *
             * @return {String}
             */
            getName: function () {
                return this.config.name;
            },

            /**
             * @param {HTMLElement[]} selections
             *
             * @return {Promise}
             */
            execute: function (selections) {
                return new Promise(function (resolve, reject) {
                    try {
                        this.guardSupportedSelection(selections);
                        this.doExecute(selections).then(resolve);
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            /**
             * returns all id's for the specified selections
             *
             * @protected
             *
             * @param {HTMLElement[]} selections
             *
             * @return {String[]}
             */
            getIds: function (selections) {
                var ids = [];
                for (var i = 0; i < selections.length; ++i) {
                    var id = selections[i].dataset[this.config.idData];
                    if (id === undefined) {
                        throw new Error(
                            'Failed to identify record id for selection ' + i
                            + ' is the configured idData "' + this.config.idData + '" correct?'
                        );
                    }
                    ids.push(id);
                }

                return ids;
            },

            /**
             * identify if the action can deal with multiple selections
             *
             * @return {Boolean}
             */
            canSupportMultiple: function () {
                return this.config.supportsMultiple;
            },

            canSupportDblClick: function () {
                return this.config.activateOnDoubleClick;
            },

            guardSupportedSelection: function (selections) {
                if (selections === undefined
                    || selections.length === undefined
                    || (selections.length > 1 && !this.canSupportMultiple())
                ) {
                    throw new Error('Invalid or unsupported selections defined for action ' + this.getName());
                }
            },
        });
    }
);

