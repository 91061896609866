
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Number',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input',
    ],
    function (Input) {
        'use strict';

        var Number = Input.extend({
            getValue: function () {
                var value = this.__super();
                value = value.replace(',', '.');
                return value;
            },
        });
        return Number;
    }
);

