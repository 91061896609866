
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/Field/Element',
        'Inventis/Bundle/BricksBundle/Mixins/Handling',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Brick, Element, FieldElement, Handling, $) {
        'use strict';

        var __errorClass = '-error',
            __errorMessageClass = 'error-text__message',
            __errorMessagesContainerClass = 'error-text',
            __maskClass = 'is-masked';

        /**
         * @class Field
         * @extends Brick
         */
        var Field = Brick.extend({
            use: [Handling],

            _name: null,
            _value: null,
            _required: null,
            _defaultValue: null,

            __construct: function () {
                this.__super(arguments);

                // Properties from the Handling mixin.
                this._handling = [];
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setNameFromSetup(setup);
                this.setValueFromSetup(setup);
                this.setRequiredFromSetup(setup);
                this.setDefaultValueFromSetup(setup);

                // Properties for the handling mixin.
                this.setHandlingFromSetup(setup);
            },

            setRequired: function (required) {
                this._required = required;
                return this;
            },

            getRequired: function () {
                return this._required;
            },

            setRequiredFromSetup: function (setup) {
                this.setRequired(setup.required);
            },

            setNameFromSetup: function (setup) {
                if (setup.name) {
                    this.setName(setup.name);
                }
            },

            setValueFromSetup: function (setup) {
                if (setup.value) {
                    // bypass setter since the value should already have been set on the component when
                    // loading
                    this._value = setup.value;
                }
            },

            setDefaultValueFromSetup: function (setup) {
                if (setup.defaultValue !== undefined && setup.defaultValue !== null) {
                    this._defaultValue = setup.defaultValue;
                }
            },

            attachListeners: function () {
                this.__super();

                this.getElement().onchange = function () {
                    this.fire('changed', {fieldName: this.getName(), fieldValue: this.getValue()});
                }.bind(this);
                this.on('loadRecord', this.handleRecordLoaded, true);

                var formElement = this.getFormElement();
                if (formElement) {
                    formElement.on('recordLoaded', this.createFormEventsHandler(this.handleRecordLoaded), true);
                    formElement.on('recordChanged', this.createFormEventsHandler(this.handleRecordChanged), true);
                    formElement.on('recordSaved', this.createFormEventsHandler(this.handleRecordLoaded), true);
                    formElement.on('recordSaveFailed', this.createFormEventsHandler(this.handleRecordSaveFailed), true);
                    formElement.on('clear', this.onClear.bind(this), true);
                    formElement.on('beforeFormSave', this.createFormEventsHandler(this.handleBeforeFormSave), true);
                    // This also requires form element to be present.
                    this.attachHandlingListeners();
                }
            },

            onDomReady: function () {
                this.__super();

                this.fire('fieldInitialized', {fieldName: this.getName(), fieldValue: this.getValue()});
            },

            handleRecordChanged: function (e, options) {
                var name = this.getName();
                if (options.record &&
                    options.record[name] !== undefined &&
                    options.record[name] != this.getValue()
                ) {
                    this.handleRecordLoaded(e, options);
                }
            },

            handleRecordSaveFailed: function (e, options) {
                if (!options.errors) {
                    return;
                }
                if (options.errors[this.getName()] !== undefined) {
                    this.setErrors(options.errors[this.getName()]);
                } else {
                    this.clearErrors();
                }
            },

            setErrors: function (messages) {
                var element = this.getComponentElement(),
                    messageContainer = $('.' + __errorMessagesContainerClass, element.getElement()).shift();

                if (!element.classExists(__errorClass)) {
                    element.addClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = messages.map(function (item) {
                        return '<span ' +
                            'class="' + __errorMessageClass + '" data-field="' + this.getName() + '">' + item +
                            '</span>';
                    }.bind(this)).join('');
                }
                this.fire('hasError', {errorClass: __errorClass}, true);
            },

            clearErrors: function () {
                var element = this.getComponentElement(),
                    // Field selector makes sure we don't accidentally select errors from other fields, even if we
                    // move higher up the DOM.
                    selector = '.' + __errorMessagesContainerClass + '[data-field="' + this.getName() + '"]',
                    messageContainer = $(selector, element.getElement()).shift();

                if (element.classExists(__errorClass)) {
                    element.removeClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = '';
                }
            },

            getComponentElement: function () {
                if (!this._componentElement) {
                    this._componentElement = new FieldElement(this.getElement().parentNode, function () {
                        return this.getValue();
                    }.bind(this));
                }
                return this._componentElement;
            },

            getFormElement: function () {
                if (!this.getForm()) {
                    return undefined;
                }
                return new Element(this.getForm());
            },

            onClear: function (e, options) {
                this.clear(options.defaults);
            },

            clear: function (defaults) {
                var value = '',
                    defaultValue;

                if (defaults && defaults[this.getName()] !== undefined) {
                    value = defaults[this.getName()];
                } else if ((defaultValue = this.getDefaultValue())) {
                    value = defaultValue;
                }

                this.setValue(value);
                this.clearErrors();
            },

            /**
             * this method will return an event compliant handler that will
             * call the provided callback only when the event is related to
             * the form that the element belongs to
             * @param {Function} callback
             * @return {Function}
             */
            createFormEventsHandler: function (callback) {
                var me = this;
                return function (e, options) {
                    // long live IE for its compliance!!
                    if (options.getTarget() === me.getForm()) {
                        callback.call(me, e, options);
                    }
                };
            },

            /**
             * returns a reference to the form that is relevant to the current field
             * @return {*}
             */
            getForm: function () {
                return this.getElement().form;
            },

            /**
             * when a recordLoaded even is fired this method can deal with its processing
             * with relation to the form
             * @param {HTMLEvent} e the html event object
             * @param {Object} options event options that where set
             */
            handleRecordLoaded: function (e, options) {
                var name = this.getName();
                this.clearErrors();
                // allow_name enables or disables the field for input
                if (options.metadata && options.metadata[name] !== undefined) {
                    this.handleMetaData(options.metadata[name]);
                }
                if (options.record &&
                    options.record[name] !== undefined
                ) {
                    this.setValueFromRecord(options.record[name]);
                    this.fire('change', {fieldName: this.getName(), fieldValue: this.getValue()});
                }
                this.fire('noError', {errorClass: __errorClass});
            },

            setValueFromRecord: function (record) {
                this.setValue(record.value !== undefined ? record.value : record);
            },

            handleMetaData: function (fieldMetadata) {
                if (fieldMetadata.enabled !== undefined && fieldMetadata.enabled !== true) {
                    this.disableField();
                } else {
                    this.enableField();
                }
                if (fieldMetadata.visible !== undefined && fieldMetadata.visible !== true) {
                    this.hide();
                } else {
                    this.show();
                }
            },

            disableField: function () {
                this.getElement().disabled = true;
            },

            enableField: function () {
                this.getElement().disabled = false;
            },

            isFieldEnabled: function () {
                return !this.getElement().disabled;
            },

            /**
             * handles the form firing a beforeFormSubmit event
             * this will allow the field to attach its values to the form field
             * values so that ist can be send off to the server for processing
             * @param {HTMLEvent} e the html event object
             * @param options
             */
            handleBeforeFormSave: function (e, options) {
                options.fields[this.getName()] = this.getValue();
            },

            /**
             * the field assigned the value to the html element, not validating it
             * that is the responsibility of extending field components
             * @param value
             * @return this
             */
            setValue: function (value) {
                value = value === null ? '' : value;
                this.getElement().value = this._value = value;
                return this;
            },

            /**
             * returns the value of the current HTML element
             */
            getValue: function () {
                var disabled = this.getElement().disabled,
                    value;
                this.getElement().disabled = false;
                value = this.getElement().value;
                this.getElement().disabled = disabled;
                return value;
            },

            setDefaultValue: function (defaultValue) {
                this._defaultValue = defaultValue;
                return this;
            },

            getDefaultValue: function () {
                return this._defaultValue;
            },

            /**
             * set the elements name attribute
             * @param name
             * @return {*}
             */
            setName: function (name) {
                this._name = name;
                var element = this.getElement();
                if (element.getAttribute('name')) {
                    element.setAttribute('name', name);
                }
                return this;
            },

            /**
             * returns this field's form field name
             */
            getName: function () {
                return this.getElement().getAttribute('name') || this._name;
            },

            /**
             * places a mask is the getElement returned container
             */
            mask: function () {
                var element = this.getComponentElement()._element,
                    classes = element.className.split(' ');
                element.scrollTop = 0;
                if (classes.indexOf(__maskClass) === -1) {
                    classes.push(__maskClass);
                    element.className = classes.join(' ');
                }
                element.appendChild(this.getMask());
            },

            /**
             * removed a mask from the getElement returned container
             */
            unmask: function () {
                var element = this.getComponentElement()._element,
                    mask = this.getMask(),
                    classes = element.className.split(' '),
                    maskClassIndex = classes.indexOf(__maskClass);
                if (element.contains(mask)) {
                    if (maskClassIndex !== -1) {
                        classes.splice(maskClassIndex, 1);
                        element.className = classes.join(' ');
                    }
                    element.removeChild(mask);
                }
            },
        });
        return Field;
    }
);

