define(
    'Inventis/Bundle/BricksBundle/Brick/AttributeConfigFactory',[
        'Inventis/Bundle/BricksBundle/Class',
    ],
    function (Class) {
        'use strict';
        var factory = Class.extend({
            configParser: null,
            manager: null,

            __construct: function (configParser, manager) {
                this.configParser = configParser;
                this.manager = manager;
            },

            /**
             *
             * @param {HTMLElement} container
             */
            create: function (container) {
                var bricks = container.querySelectorAll('[data-brick-type]'),
                    type,
                    config;
                for (var i = 0; i < bricks.length; ++i) {
                    var brick = bricks[i];
                    if (brick.dataset.brickInitialized !== '1') {
                        brick.dataset.brickInitialized = '1';
                        type = brick.getAttribute('data-brick-type');
                        config = brick.getAttribute('data-brick-config');
                        config = this.configParser.parse(config);
                        this.manager.createBrick(type, config);
                        delete brick.dataset.brickConfig;
                    }
                }
            },
        });

        return factory;
    }
);

