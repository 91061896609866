define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginChain',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Class) {
        /**
         * @class GridPluginChain
         * @implements GridPlugin
         * @property {int} length the amount of plugins available
         */
        return Class.extend({
            __construct: function (plugins) {
                plugins = [] || plugins;
                this.plugins = plugins;
                Object.defineProperty(this, 'length', {
                    get: function () {
                        return this.plugins.length;
                    }.bind(this),
                });
            },

            addPlugin: function (plugin) {
                this.plugins.push(plugin);
            },

            onCreate: function (grid) {
                this.plugins.forEach(function (plugin) {
                    try {
                        plugin.onCreate(grid);
                    } catch (e) {
                        throw new Error('Grid plugin onCreate failed: ' + e.name + ' ' + e.message);
                    }
                });
            },

            beforeRender: function (view) {
                this.plugins.forEach(function (plugin) {
                    try {
                        plugin.beforeRender(view);
                    } catch (e) {
                        throw new Error('Grid plugin beforeRender failed: ' + e.name + ' ' + e.message);
                    }
                });
            },

            afterRender: function (view) {
                this.plugins.forEach(function (plugin) {
                    try {
                        plugin.afterRender(view);
                    } catch (e) {
                        throw new Error('Grid plugin afterRender failed: ' + e.name + ' ' + e.message);
                    }
                });
            },

            beforeRedraw: function (view) {
                this.plugins.forEach(function (plugin) {
                    try {
                        plugin.beforeRedraw(view);
                    } catch (e) {
                        throw new Error('Grid plugin beforeRedraw failed: ' + e.name + ' ' + e.message);
                    }
                });
            },

            afterRedraw: function (view) {
                this.plugins.forEach(function (plugin) {
                    try {
                        plugin.afterRedraw(view);
                    } catch (e) {
                        throw new Error('Grid plugin afterRedraw failed: ' + e.name + ' ' + e.message);
                    }
                });
            },
        });
    }
);

