
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Fieldset',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Button',
        'Inventis/Bundle/BricksBundle/Mixins/Handling',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Component, Button, Handling, $) {
        'use strict';

        var Fieldset = Component.extend({
            use: [Handling],

            _collapsible: null,
            _collapseButtonSelector: 'legend',
            _collapsedClassName: '-collapsed',
            _collapsed: null,

            __construct: function () {
                this.__super(arguments);

                // Properties from the Handling mixin.
                this._handling = [];
            },

            init: function (setup) {
                this.__super(setup);
                this.attachHandlingListeners();
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setCollapsibleFromSetup(setup);

                // Properties for the handling mixin.
                this.setHandlingFromSetup(setup);
            },

            setCollapsibleFromSetup: function (setup) {
                if (setup.collapsible !== undefined) {
                    this.setCollapsible(setup.collapsible);
                }
            },

            setCollapsed: function (collapsed) {
                this._collapsed = Boolean(collapsed);
                return this;
            },

            getCollapsed: function () {
                return this._collapsed;
            },

            setCollapsible: function (collapsible) {
                this._collapsible = Boolean(collapsible);
                return this;
            },

            getCollapsible: function () {
                return this._collapsible;
            },

            onDomReady: function () {
                this.__super();
                if (this.getCollapsible()) {
                    this._attachCollapseHandling();
                }
            },

            getForm: function () {
                return this.getElement().form;
            },

            /**
             * attaches a collapse button and the click listener event to the fieldset
             * @private
             */
            _attachCollapseHandling: function () {
                var element = this.getElement(),
                    index;

                // create a button (no ref to the button required)
                new Button($(this._collapseButtonSelector, this.getElement()).shift(), {for: this.getId()});

                this.on('buttonClicked', function (e, options) {
                    if (options.for === this.getId()) {
                        var elementClasses = element.className.split(' ');
                        index = elementClasses.indexOf(this._collapsedClassName);
                        if (index !== -1) {
                            this.setCollapsed(false);
                            elementClasses.splice(index, 1);
                        } else {
                            this.setCollapsed(true);
                            elementClasses.push(this._collapsedClassName);
                        }
                        element.className = elementClasses.join(' ');
                        return false;
                    }
                }, true);
            },
        });
        return Fieldset;
    }
);

