/* eslint-disable no-unused-vars */
define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Class, Promise) {
        'use strict';
        /**
         * @class GridPluginBase
         * @implements GridPlugin
         *
         */
        return Class.extend({
            /**
             * @var {Grid}
             */
            grid: null,

            onCreate: function (grid) {
                return new Promise(function (resolve) {
                    this.grid = grid;
                    resolve();
                }.bind(this));
            },

            /**
             * @param {View} view
             */
            beforeRender: function (view) {
            },

            /**
             * @param {View} view
             */
            afterRender: function (view) {
            },

            /**
             * @param {View} view
             */
            beforeRedraw: function (view) {
            },

            /**
             * @param {View} view
             */
            afterRedraw: function (view) {
            },

            /**
             * @return {Grid}
             */
            getGrid: function () {
                return this.grid;
            },
        });
    }
);

