
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Select',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
    ],
    function (Field, ApiAware) {
        'use strict';

        var Select = Field.extend({
            use: [ApiAware],

            _items: null,
            _multiple: false,
            _valueField: null,
            _displayField: null,
            _fetchingItems: false,

            __construct: function () {
                this.__super(arguments);
                this._api = {};
            },

            /**
             * overwrite and attach custom setup handlers
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.setApiFromConfig(setup);
                this.setItemsFromSetup(setup);
                this.setMultipleFromSetup(setup);
                this.setValueFieldFromSetup(setup);
                this.setDisplayFieldFromSetup(setup);
                this.__super(setup);
            },

            attachListeners: function () {
                this.on('change', this.onChange, true);

                this.__super();

                this.on('reload', this.onReload, true);
            },

            handleRecordChanged: function (e, options) {
                if (!this._fetchingItems) {
                    this.__super(e, options);
                }
            },

            onChange: function (event, options) {
                if (options.id === undefined) {
                    this.attachChangeParametersToObject(options);
                }
            },

            /**
             * @param {Object} object
             */
            attachChangeParametersToObject: function (object) {
                object.id = this.getId();
                object.fieldName = this.getName();
                object.fieldValue = this.getValue();
                object[object.fieldName] = object.value;
            },

            onReload: function(e, options){
                this.fetch(this.buildFetchDataForOptions(options));
            },

            /**
             * @return {Object}
             */
            buildFetchData: function () {
                var data = {};

                return data;
            },

            /**
             * @param {Object} options
             *
             * @return {Object}
             */
            buildFetchDataForOptions: function (options) {
                var data = this.buildFetchData();

                for (var x in options) {
                    if (typeof options[x] !== 'function') {
                        data[x] = options[x];
                    }
                }

                return data;
            },

            /**
             * initiates an AJAX call to fetch the items for the select and reloads it
             * [@param {object} data] data to pass to the request
             * [@param {function} callback] a callback that gets called on success, after items have been set and reloaded
             */
            fetch: function (data, callback) {
                data = data || {};
                this._fetchingItems = true;
                this.apiRequest('getItems', data, function (response) {
                    if (response.success) {
                        this.setItems(response.result);
                        this._fetchingItems = false;
                        this.reloadItems();
                        if (callback) {
                            callback.call(this);
                        }
                    } else {
                        this._fetchingItems = false;
                        throw new Error('Loading the items for the select through AJAX failed!');
                    }
                });
            },

            /**
             * forces the select to reload all its items from the items array
             * also selects the correct value(s) if set
             */
            reloadItems: function () {
                var value = this.getValue(),
                    items = this.getItems(),
                    options = this.getElement().options,
                    oneValueSelected = false,
                    j = 0;

                options.length = 0;

                if (!(value instanceof Array)) {
                    value = new Array(value);
                }
                if (!this.getRequired() && !this.getMultiple()) {
                    options[j] = new Option(
                        '',
                        '',
                        false,
                        false
                    );
                    j++;
                }
                for (var i = 0; i < items.length; ++i) {
                    options[i + j] = new Option(
                        items[i][this.getDisplayField()],
                        items[i][this.getValueField()],
                        false,
                        value.indexOf(items[i][this.getValueField()]) !== -1
                    );
                    oneValueSelected = oneValueSelected || (value.indexOf(items[i][this.getValueField()]) !== -1);
                }

                if (!oneValueSelected && options.length > 0 && this.getMultiple()) {
                    options[0].selected = true;
                }
            },

            /**
             * @param setup
             */
            setValueFieldFromSetup: function (setup) {
                if (setup.valueField !== undefined) {
                    this.setValueField(setup.valueField);
                }
            },

            /**
             * @param setup
             */
            setDisplayFieldFromSetup: function (setup) {
                if (setup.displayField !== undefined) {
                    this.setDisplayField(setup.displayField);
                }
            },

            /**
             * @param setup
             */
            setItemsFromSetup: function (setup) {
                if (setup.items !== undefined) {
                    this.setItems(setup.items);
                }
            },

            /**
             * @param setup
             */
            setMultipleFromSetup: function (setup) {
                if (setup.multiple !== undefined) {
                    this.setMultiple(setup.multiple);
                }
            },

            /**
             *
             * @return string | null
             */
            getDefaultValue: function () {
                var defaultValue = this.__super(),
                    items = this.getItems();

                // Only return the default item if it exists (or the select will receive an empty selection).
                for (var i = 0; i < items.length; ++i) {
                    if (items[i][this.getValueField()] == defaultValue) {
                        return defaultValue;
                    }
                }
                return null;
            },

            setValueFromSetup: function (setup) {
                if (setup.value) {
                    // we should not bypass the setter as these components have logic attached to them for
                    // displaying the currently selected value(s)
                    this.setValue(setup.value);
                }
            },

            /**
             * the field assigned the value to the html element, not validating it
             * that is the responsibility of extending field components
             * @param value
             * @return this
             */
            setValue: function (value) {
                if (value instanceof Array && !this.getMultiple()) {
                    value = value.shift();
                }

                // check if the user wants to set multiple selections
                if (value instanceof Array) {
                    var options = this.getElement().options;

                    for (var i = 0; i < options.length; ++i) {
                        options[i].selected = (value.indexOf(options[i].value) !== -1);
                    }

                    this._value = value;
                    return this;
                }

                return this.__super(value);
            },

            /**
             * returns the value of the current HTML element
             */
            getValue: function () {
                if (!this.getMultiple()) {
                    return this.__super();
                }

                var value = [],
                    options = this.getElement().options;

                for (var i = 0; i < options.length; ++i) {
                    if (options[i].selected) {
                        value.push(options[i].value);
                    }
                }

                return value;
            },

            /**
             *
             * @param valueField
             * @return this
             */
            setValueField: function (valueField) {
                this._valueField = valueField;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getValueField: function () {
                return this._valueField;
            },

            /**
             *
             * @param displayField
             * @return this
             */
            setDisplayField: function (displayField) {
                this._displayField = displayField;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getDisplayField: function () {
                return this._displayField;
            },

            /**
             *
             * @param items
             * @return this
             */
            setItems: function (items) {
                this._items = items;
                return this;
            },

            /**
             *
             * @return {array}
             */
            getItems: function () {
                return this._items;
            },

            /**
             *
             * @param multiple
             * @return this
             */
            setMultiple: function (multiple) {
                this._multiple = multiple;
                return this;
            },

            /**
             *
             * @return {array}
             */
            getMultiple: function () {
                return this._multiple;
            },
        });
        return Select;
    }
);

