
define(
    'Inventis/Bundle/BricksBundle/Brick/Panel',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Application',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Brick, App, $) {
        'use strict';
        // static scope

        /**
         * @class Panel
         * @extends Brick
         */
        var Panel = Brick.extend({
            /**
             * holds the currently set url
             * @var false|{String}
             */
            _url: false,

            /**
             * holds the currently set width
             * @var false|{String}
             */
            _width: false,

            /**
             * holds the currently set height
             * @var false|{String}
             */
            _height: false,

            /**
             * @var {String}
             */
            _title: false,

            /**
             * @var {Boolean}
             */
            _allowScriptLoading: true,

            /**
             * ataches to the init to initiate url load when required
             * @param setup
             */
            init: function (setup) {
                this.__super(setup);
                if (this._url) {
                    this.loadContents();
                } else {
                    this.resize();
                }
            },

            /**
             * this method is called during object init, but only
             * when a url is set
             * initiates the ajax request and
             * injects it into the dom as soon as its ready
             */
            loadContents: function () {
                // initiate an ajax call that will call onAjaxComplete in local scope
                App.ajax(this.getUrl(), {}, this.onAjaxComplete, this);
            },

            resize: function () {
                var el = this.getElement();

                if (this.getWidth()) {
                    el.style.width = this.getWidth();
                }

                if (this.getHeight()) {
                    el.style.height = this.getHeight();
                }
            },

            /**
             * overrides the parent method to attach its own methods to the call
             * @override
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setUrlFromConfig(setup);
                this.setWidthFromConfig(setup);
                this.setHeightFromConfig(setup);
                this.setTitleFromConfig(setup);
                this.setAllowScriptLoadingFromConfig(setup);
            },

            setAllowScriptLoadingFromConfig: function (setup) {
                if (setup.allowScriptLoading) {
                    this.setAllowScriptLoading(setup.allowScriptLoading);
                }
            },

            /**
             * sets the window title from the provided setup if set
             * @param setup
             */
            setTitleFromConfig: function (setup) {
                if (setup.title !== undefined) {
                    this.setTitle(setup.title);
                }
            },

            /**
             * sets the title to the provided string/bool
             * @param title
             * @return Panel
             */
            setTitle: function (title) {
                this._title = title;
                return this;
            },

            /**
             * returns the set title
             * @return {Boolean}|{string}
             */
            getTitle: function () {
                return this._title;
            },

            /**
             * sets the panel url that should ajax load content for rendering
             * from the provided setup if set
             * @param setup
             */
            setUrlFromConfig: function (setup) {
                if (setup.url !== undefined) {
                    this.setUrl(setup.url);
                }
            },

            /**
             * sets the panel width from the provided setup if set
             * @param setup
             */
            setWidthFromConfig: function (setup) {
                if (setup.width !== undefined) {
                    this.setWidth(setup.width);
                }
            },

            /**
             * sets the panel height from the provided setup if set
             * @param setup
             */
            setHeightFromConfig: function (setup) {
                if (setup.height !== undefined) {
                    this.setHeight(setup.height);
                }
            },

            /**
             * sets the url to the provided string/bool
             * and initiates the ajax call with a callback that
             * will handle loading of the content
             * @param url
             * @return Panel
             */
            setUrl: function (url) {
                this._url = url;
                return this;
            },

            /**
             * returns the set url
             * @return {Boolean}|{string}
             */
            getUrl: function () {
                return this._url;
            },

            /**
             * sets the width to the provided string/bool
             * @param width
             * @return Panel
             */
            setWidth: function (width) {
                this._width = width;
                return this;
            },

            /**
             * returns the set width
             * @return {Boolean}|{string}
             */
            getWidth: function () {
                return this._width;
            },

            /**
             * sets the height to the provided string/bool
             * @param height
             * @return Panel
             */
            setHeight: function (height) {
                this._height = height;
                return this;
            },

            /**
             * returns the set height
             * @return {Boolean}|{string}
             */
            getHeight: function () {
                return this._height;
            },

            setAllowScriptLoading: function (allow) {
                this._allowScriptLoading = allow;
                return this;
            },

            getAllowScriptLoading: function () {
                return this._allowScriptLoading;
            },

            /**
             * handler for ajax calls, this method will ensure response handling
             * is only started as soon as the DOM is ready for manipulation
             * @param {Object} response the json_encoded object
             * @param {Object} options the options object with which the ajax call was made
             */
            onAjaxComplete: function (response, options) {
                App.ready(function () {
                    if (response.success === true || response.success === undefined) {
                        this.onAjaxSuccess(response);
                    } else {
                        this.onAjaxFailure(response);
                    }
                }, this);
            },

            /**
             * handles a successfull ajax request by updating the component's
             * element with the content of the response
             * @param response
             */
            onAjaxSuccess: function (response) {
                var el = $('> .data-container', this.getElement()).shift();
                if (response.success === true) {
                    el.innerHTML = response.result;
                    this.resize();
                } else {
                    el.innerHTML = response;
                }
                this.loadScripts(el);
                this.fire('panelLoaded', {url: this.getUrl()});
            },

            /**
             * loads any scripts defined in the proveded element
             * this call should only be used on the element that got ajax content loaded into it
             * @param el
             */
            loadScripts: function (el) {
                if (!this.getAllowScriptLoading()) {
                    return;
                }
                App.parseScripts(el.innerHTML);
            },

            /**
             * the default ajax failure will push the response message
             * into the element's inner html
             * @param response
             */
            onAjaxFailure: function (response) {
                this.onAjaxSuccess(response.message);
            },
        });
        return Panel;
    }
);

