
define(
    'Inventis/Bundle/BricksBundle/Brick/Reply',[
        'Inventis/Bundle/BricksBundle/Brick/Panel',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (Panel, Element) {
        'use strict';

        var Reply = Panel.extend({
            _gridId: null,
            _grid: null,
            _currentRelatedId: null,
            /**
             *
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.getDependenciesFromConfig(setup);
                this.on('componentInitialized', this.onComponentInitialized, true);
                this.on('editRecord', this.onEditRecord, true);
                this.on('newRecord', this.onEditRecord, true);
                this.on('filterChange', this.onFilterChange, true);
            },

            onFilterChange: function (events, options) {
                var relatedIdFound = false;
                for (var x in options.filters) {
                    if (options.filters.hasOwnProperty(x)) {
                        var filter = options.filters[x];
                        if (filter.property === 'related_id') {
                            relatedIdFound = true;
                            break;
                        }
                    }
                }
                if (!relatedIdFound) {
                    this.sendRelatedId();
                }
            },

            getDependenciesFromConfig: function (setup) {
                if (setup.dependencies === undefined) {
                    return;
                }
                this.getGridIdFromSetup(setup.dependencies);
            },

            getGridIdFromSetup: function (setup) {
                if (setup.grid === undefined) {
                    return;
                }
                this.setGridId(setup.grid);
            },

            setGridId: function (gridId) {
                this._gridId = gridId;
            },

            getGridId: function () {
                return this._gridId;
            },

            setGrid: function (grid) {
                this._grid = grid;
            },

            getGrid: function () {
                return this._grid;
            },

            onComponentInitialized: function (event, options) {
                if (options.id === this._gridId) {
                    this.setGrid(options.element);
                }
            },

            onEditRecord: function (event, options) {
                var id = options.id;
                if (!id) {
                    this.disable();
                } else {
                    this.enable();
                    this._currentRelatedId = id;
                    this.sendRelatedId();
                }
            },

            sendRelatedId: function () {
                this.getGrid().fire(
                    'setFilters',
                    {filters: [{property: 'related_id', value: this._currentRelatedId}]},
                    false
                );
            },
        });
        return Reply;
    }
);

