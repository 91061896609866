
define(
    'Inventis/Bundle/BricksBundle/HTML/Field/Element',[
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (HTMLElement) {
        'use strict';

        var Element = HTMLElement.extend({
            _getValueCallback: null,

            __construct: function (element, getValueCallback) {
                this.__super(element);
                this._getValueCallback = getValueCallback;
            },

            getValue: function () {
                return this._getValueCallback.apply(this, arguments);
            },
        });

        return Element;
    }
);

