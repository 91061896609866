
define(
    'Inventis/Bundle/BricksBundle/Brick/Panel/FilterPanel',[
        'Inventis/Bundle/BricksBundle/Brick/Panel',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Panel, Element, $) {
        'use strict';

        var FilterPanel = Panel.extend({

            filterEvent: null,

            filterRouting: null,

            disableWhenFalse: true,

            ids: null,

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setFilterEventFromSetup(setup);
                this.setFilterRoutingFromSetup(setup);
                this.setDisableWhenFalseFromSetup(setup);
                this.setIdsFromSetup(setup);
            },

            onDomReady: function (e, options) {
                this.__super(e, options);
                this._attachEvents();
            },

            _attachEvents: function () {
                for (var x in this.filterEvent) {
                    if (this.filterEvent.hasOwnProperty(x)) {
                        this.on(this.filterEvent[x], this.onFilterEvent, true);
                    }
                }
            },

            onFilterEvent: function (event, options) {
                var ids = this.getIds(),
                    filterRouting = this.getFilterRouting(),
                    newOptions = {},
                    element = null,
                    disable = false,
                    x,
                    y,
                    value;

                newOptions.filters = [];
                for (y in filterRouting) {
                    if (filterRouting.hasOwnProperty(y)) {
                        value = options.record ? options.record[y] : options[y];

                        if (value !== undefined) {
                            var filter = {};
                            filter.property = filterRouting[y];
                            filter.value = value;
                            newOptions.filters.push(filter);
                        }
                        disable = disable || !value;
                    }
                }
                for (x in ids) {
                    if (ids.hasOwnProperty(x)) {
                        element = new Element(document.getElementById(ids[x]));
                        element.fire('setFilters', newOptions);
                    }
                }
                if (disable && this.disableWhenFalse) {
                    this.fire('disable', [], false);
                } else {
                    this.fire('enable', [], false);
                }
            },

            setFilterEventFromSetup: function (setup) {
                if (setup.filterEvent) {
                    this.setFilterEvent(setup.filterEvent);
                }
            },

            setFilterRoutingFromSetup: function (setup) {
                if (setup.filterRouting) {
                    this.setFilterRouting(setup.filterRouting);
                }
            },

            setDisableWhenFalseFromSetup: function (setup) {
                if (setup.disableWhenFalse !== undefined) {
                    this.setDisableWhenFalse(setup.disableWhenFalse);
                }
            },

            setIdsFromSetup: function (setup) {
                if (setup.ids) {
                    this.setIds(setup.ids);
                }
            },

            setFilterEvent: function (filterEvent) {
                this.filterEvent = filterEvent;
                return this;
            },

            setFilterRouting: function (filterRouting) {
                this.filterRouting = filterRouting;
                return this;
            },

            setDisableWhenFalse: function (disableWhenFalse) {
                this.disableWhenFalse = disableWhenFalse;
                return this;
            },

            setIds: function (ids) {
                this.ids = ids;
                return this;
            },

            getFilterEvent: function () {
                return this.filterEvent;
            },

            getFilterRouting: function () {
                return this.filterRouting;
            },

            getDisableWhenFalse: function () {
                return this.disableWhenFalse;
            },

            getIds: function () {
                return this.ids;
            },
        });
        return FilterPanel;
    }
);

