/* eslint-disable no-unused-vars */
define(
    'Inventis/Bundle/BricksBundle/Brick/Grid/DefaultRenderers',[
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Config',
        'moment-timezone',
    ],
    function (Console, Config, moment) {
        'use strict';

        function parseDateToW3C(value, model) {
            if (value.date === undefined || value.timezone === undefined) {
                Console.error(
                    'Invalid date object for date renderer',
                    value,
                    'in record',
                    model,
                    'expecting date and timezone properties to be set'
                );
                return value.date || value;
            }
            // format into a W3C string usable by new Date
            return moment.tz(value.date, value.timezone).format();
        }
        return {
            /**
             * @param {String|Object|null} value
             * @param {Object} model the model data
             * @param {Node} node the node holding the bound template variable
             * @param {String} name the node name
             */
            date: function (value, model, node, name) {
                if (typeof value === 'object' && value !== null) {
                    value = parseDateToW3C(value, model);
                }

                if (typeof value === 'string') {
                    // assume W3C formatting
                    var date = new Date(value);
                    return date.toLocaleDateString(Config.ietfLocale);
                }

                return value;
            },
            /**
             * @param {String|Object|null} value
             * @param {Object} model the model data
             * @param {Node} node the node holding the bound template variable
             * @param {String} name the node name
             */
            time: function (value, model, node, name) {
                if (typeof value === 'object' && value !== null) {
                    value = parseDateToW3C(value, model);
                }

                if (typeof value === 'string') {
                    // assume W3C formatting
                    var date = new Date(value);
                    return date.toLocaleTimeString(Config.ietfLocale);
                }

                return value;
            },

            /**
             * @param {String|Object|null} value
             * @param {Object} model the model data
             * @param {Node} node the node holding the bound template variable
             * @param {String} name the node name
             */
            datetime: function (value, model, node, name) {
                if (typeof value === 'object' && value !== null) {
                    value = parseDateToW3C(value, model);
                }

                if (typeof value === 'string') {
                    // assume W3C formatting
                    var date = new Date(value);
                    return date.toLocaleString(Config.ietfLocale);
                }

                return value;
            },

            evenOrOdd: function () {
                if (this.getElement().dataset.counter) {
                    this.getElement().dataset.counter = '0';
                }
                this.getElement().dataset.counter++;
                return ((this.getElement().dataset.counter % 2) ? 'even' : 'odd');
            },
        };
    }
);

