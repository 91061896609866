define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/HeaderSortingPlugin',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',
    ],
    function (GridPluginBase) {
        'use strict';
        /**
         * @class HeaderSortingPlugin
         * @extends GridPluginBase
         */
        return GridPluginBase.extend({

            __construct: function (config) {
                this.config = this.processConfig(config);
                this.listeners = [];
                this.incremental = false;
                this.grid = null;
            },

            processConfig: function (config) {
                /*
                 * the dataset properties that will contain sorting information
                 */
                return {
                    sortFieldData: config.sortFieldData || 'sortField',
                    sortDirData: config.sortDirData || 'sortDir',
                };
            },

            afterRender: function (view) {
                this.attachListeners(view.getContainerElement());
            },

            /**
             * @protected
             * @param {HTMLElement} container
             * @return {void} true when listeners where attached, false otherwise
             */
            attachListeners: function (container) {
                this.listeners.push(container.addEventListener('keydown', this.onKeyDown.bind(this)));
                this.listeners.push(container.addEventListener('keyup', this.onKeyUp.bind(this)));
                this.listeners.push(container.addEventListener('changeSort', this.onChangeSort.bind(this, container)));
            },

            /**
             * @param {KeyboardEvent} event
             */
            onKeyDown: function (event) {
                if (event.shiftKey) {
                    this.incremental = true;
                }
            },
            /**
             * @param {KeyboardEvent} event
             */
            onKeyUp: function (event) {
                if (event.shiftKey) {
                    this.incremental = false;
                }
            },

            onChangeSort: function (container, event) {
                if (!this.grid) {
                    console.error('Sorting plugin cannot handle sort changes, no grid defined');
                    return;
                }
                var field,
                    dir,
                    sort;
                field = event.target.dataset[this.config.sortFieldData];
                if (field) {
                    dir = event.target.dataset[this.config.sortDirData];
                    if (!dir) {
                        dir = 'ASC';
                    } else {
                        dir = this.getInvertedSortDirection(dir);
                    }
                    event.target.dataset[this.config.sortDirData] = dir;

                    sort = this.incremental ? this.grid.getSort() : {};
                    sort[field] = dir;

                    this.applySort(sort, container);
                } else {
                    console.error(
                        'changeSort event fired, but there was no `' + this.config.sortFieldData
                        + '` dataset value available. Either add a data-' + this.config.sortFieldData
                        + '="myField" to your'
                        + ' template, or define another dataset property in your config through ' +
                        ' `sortFieldData: "myCustomDataSetProp"`'
                    );
                }
            },

            getInvertedSortDirection: function (direction) {
                return (direction === 'ASC' ? 'DESC' : 'ASC');
            },

            applySort: function (sort, container) {
                this.applySortToHeaders(sort, container);

                this.grid.setSort(sort);
                this.grid.redraw();
            },

            /**
             * Applies the specified sorting to headers.
             *
             * Headers that are not contained in the sort will be reset. Those that are present, will be updated to
             * match the new sorting.
             *
             * @param {Object}      sort
             * @param {HTMLElement} container
             */
            applySortToHeaders: function (sort, container) {
                var items = container.querySelectorAll('[' + this.convertAttributeCamelCaseToDashedNotation(this.config.sortFieldData) +  ']');

                for (var i = 0; i < items.length; ++i) {
                    if (sort.hasOwnProperty(items[i].dataset[this.config.sortFieldData])) {
                        var directionToSet = sort[items[i].dataset[this.config.sortFieldData]];

                        items[i].dataset[this.config.sortDirData] = directionToSet;
                    } else {
                        delete items[i].dataset[this.config.sortDirData];
                    }
                }
            },

            convertAttributeCamelCaseToDashedNotation: function (name) {
                // \L$0 as substitution isn't supported in JavaScript, so use a callback.
                return 'data-' + this.config.sortFieldData.replace(new RegExp('([A-Z])', 'g'), function (match) {
                    return '-' + match.toLowerCase();
                }.bind(this));
            }
        });
    }
);

