define(
    'Inventis/Bundle/BricksBundle/Grid/Store/RecordCollection',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Grid/Store/Record',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Class, Record, Console) {
        'use strict';
        /**
         * @class RecordCollection
         */
        var RecordCollection = Class.extend({
            /**
             * @private
             * @var {Object}
             */
            collection: null,
            /**
             * @private
             * @var {Array}
             */
            index: null,

            /**
             * a map of id's and their index in the collection
             * @private
             * @var {Object}
             */
            map: null,
            childCollection: null,

            length: null,

            __construct: function (config) {
                this.config = config;
                this.clear();
            },

            /**
             * @param {int} index,
             * @param {Record} record
             * @throws {Error} when you dont pass an instance of {@see Record}
             */
            set: function (index, record) {
                if (!(record instanceof Record)) {
                    Console.error('Trying to assign invalid data to the record collection:', record, 'at index', index);
                    throw new Error('Trying to assign invalid data to the record collection');
                }
                this.collection[index] = record;
                this.map[record.getId()] = index;
                this.index.push(index);
                ++this.length;
            },

            delete: function (index) {
                var record = this.collection[index];
                if (record) {
                    delete this.map[record.getId()];
                    // delete entry and move everything in the collection 1 forward
                    delete this.collection[index];
                    while (index+1 < this.length) {
                        record = this.collection[index+1];
                        this.collection[index] = record;
                        delete this.collection[index+1];
                        this.map[record.getId()] = index;
                        ++index;
                    }
                    this.index.pop();
                    --this.length;
                }
            },

            deleteRecord: function (id) {
                if (this.map[id] !== undefined) {
                    this.delete(this.map[id]);
                    return true;
                }

                return this.index.some(function (index) {
                    return this.get(index).removeChild(id);
                }.bind(this));
            },

            /**
             * @param {Number} index
             * @return {Record|null}
             */
            get: function (index) {
                return this.collection[index] || null;
            },

            has: function (index) {
                return this.collection.hasOwnProperty(index);
            },

            hasRecord: function (id) {
                if (this.map[id] !== undefined) {
                    return true;
                }

                return this.index.some(function (index) {
                    /** @var {Record} record */
                    return this.get(index).hasChild(id);
                }.bind(this));
            },

            getRecordIndex: function (id) {
                var index = this.map[id];
                if (index === undefined) {
                    throw new Error('Unknown id, ' + id + ' is not available in this record collection');
                }
                return index;
            },

            getRecord: function (id) {
                if (this.map[id] !== undefined) {
                    return this.get(this.map[id]);
                }

                var childRecord = null;
                this.index.some(function (index) {
                    childRecord = this.get(index).getChild(id);
                    return childRecord !== null;
                }.bind(this));
                if (childRecord !== null) {
                    return childRecord;
                }

                throw new Error('Unknown id, ' + id + ' is not available in this record collection');
            },

            /**
             * returns all available items within the given range
             * @param {Number} start
             * @param {Number} end
             * @return {Array}
             */
            getRange: function (start, end) {
                var range = [];
                while (start <= end) {
                    if (this.has(start)) {
                        range.push(this.get(start));
                    }
                    ++start;
                }
                return range;
            },

            clear: function () {
                this.map = {};
                this.collection = {};
                this.length = 0;
                this.index = [];
            },

            createRecord: function (data) {
                return new Record(this.config.model, data);
            },

            getLength: function () {
                return this.length;
            }
        });

        return RecordCollection;
    }
);

