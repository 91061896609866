
define(
    'Inventis/Bundle/BricksBundle/Brick/PreviewPanel',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
        'Inventis/Bundle/BricksBundle/Mixins/Maskable',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Application',
    ],
    function (Component, Api, Maskable, $, App) {
        'use strict';

        var PreviewPanel = Component.extend({
            use: [Api, Maskable],

            _allowScriptLoading: true,

            __construct: function () {
                this.__super(arguments);
                this._api = {};
            },

            /**
             *
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setApiFromConfig(setup);
                this.setAllowScriptLoadingFromConfig(setup);
            },

            setAllowScriptLoadingFromConfig: function (setup) {
                if (setup.allowScriptLoading) {
                    this.setAllowScriptLoading(setup.allowScriptLoading);
                }
            },

            onDomReady: function () {
                this.attachEventListeners();
                this.__super();
            },

            attachEventListeners: function () {
                this.on('loadPreview', this.onLoadPreview, true);
            },

            onLoadPreview: function (e, options) {
                if (options.ids && options.ids.length) {
                    this.mask();
                    this.apiRequest('get', {ids: options.ids}, this.onPreviewDataLoaded);
                } else if (options.id) {
                    this.mask();
                    this.apiRequest('get', {ids: [options.id]}, this.onPreviewDataLoaded);
                } else {
                    this.clear();
                }
            },

            clear: function () {
                this.mask();
                var el = $('.data-container', this.getElement()).shift();
                el.innerHTML = '';
            },

            onPreviewDataLoaded: function (response) {
                var el = $('.data-container', this.getElement()).shift();
                if (response.success === true) {
                    el.innerHTML = response.result;
                } else {
                    el.innerHTML = response.message || response;
                }
                this.loadScripts(el);
                this.unmask();
            },

            /**
             * loads any scripts defined in the proveded element
             * this call should only be used on the element that got ajax content loaded into it
             * @param el
             */
            loadScripts: function (el) {
                if (!this.getAllowScriptLoading()) {
                    return;
                }
                App.parseScripts(el.innerHTML);
            },

            setAllowScriptLoading: function (allow) {
                this._allowScriptLoading = allow;
                return this;
            },

            getAllowScriptLoading: function () {
                return this._allowScriptLoading;
            },
        });
        return PreviewPanel;
    }
);

