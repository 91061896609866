
/**
 * Console
 * This is a dependency for places where you want to leave debugging logs
 * at all times to speed up dev debugging, like in the Observable's event handling
 *
 * @important the object is specifically crafted to endure compatibility with BEHAT's console replacement hooks
 */
define(
    'Inventis/Bundle/BricksBundle/Console',[
    ],
    function () {
        'use strict';
        return {
            debug: function () {
                if (window.console.debug) {
                    window.console.debug.apply(null, arguments);
                }
            },

            log: function () {
                if (window.console.log) {
                    window.console.log.apply(null, arguments);
                }
            },

            error: function () {
                if (window.console.error) {
                    window.console.error.apply(null, arguments);
                }
            },

            warn: function () {
                if (window.console.warn) {
                    window.console.warn.apply(null, arguments);
                }
            },

            group: function () {
                if (window.console.group) {
                    window.console.group.apply(null, arguments);
                }
            },

            groupEnd: function () {
                if (window.console.groupEnd) {
                    window.console.groupEnd.apply(null, arguments);
                }
            },

            groupCollapsed: function () {
                if (window.console.groupCollapsed) {
                    window.console.groupCollapsed.apply(null, arguments);
                }
            },
        };
    }
);

