define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/SelectionPlugin',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',
        'Inventis/Bundle/BricksBundle/Console',
        'selectable',
    ],
    function (GridPluginBase, Console, Selectable) {
        'use strict';
        /**
         * @class SelectionPlugin
         * @extends GridPluginBase
         */
        return GridPluginBase.extend({
            __construct: function (config) {
                this.config = this.processConfig(config || {});
                this.selections = [];
                this.doubleClickStart = null;
            },

            /**
             * @link https://github.com/Mobius1/Selectable/wiki
             */
            processConfig: function (config) {
                config.doubleClickDelay = config.doubleClickDelay || 500;
                config.filter = config.filter || '.-selectable';
                config.classes = config.classes || {
                    lasso: 'lasso', // the lasso element itself
                    // modifiers on the rows
                    selected: '-selected',
                    container: '-container',
                    selecting: '-selecting',
                    selectable: '-selectable',
                    unselecting: '-unselecting',
                };

                return config;
            },

            afterRender: function (view) {
                try {
                    var container = view.getContainerElement();
                    this.config.appendTo = this.config.appendTo ?
                        container.querySelector(this.config.appendTo) :
                        container;
                    this.selectable = new Selectable(this.config);
                    this.selectable.on('selectable.end', function (e, selected, unselected) {
                        var selectedNodes = [],
                            unselectedNodes = [];
                        selected.forEach(function (item) {
                            selectedNodes.push(item.node);
                        });
                        unselected.forEach(function (item) {
                            unselectedNodes.push(item.node);
                        });

                        if (unselected.length > 0 || this.selectionsChanged(selectedNodes)) {
                            var selectionChangeEvent = new Event('selectionChanged', {
                                bubbles: true,
                            });
                            this.selections = selectionChangeEvent.selections = this.selectable.getSelectedNodes();
                            selectionChangeEvent.addedSelections = selectedNodes;
                            selectionChangeEvent.removedSelections = unselectedNodes;

                            container.dispatchEvent(selectionChangeEvent);
                        } else if (this.isDoubleClick()) {
                            this.doubleClickStart = null;
                            var selectionDoubleClickEvent = new Event('selectionDoubleClick', {
                                bubbles: true,
                            });
                            selectionDoubleClickEvent.selections = this.selectable.getSelectedNodes();

                            container.dispatchEvent(selectionDoubleClickEvent);
                        }
                        this.doubleClickStart = new Date();
                    }.bind(this));

                    container.addEventListener('reordered', function () {
                        this.selectable.setContainer(view.getContainerElement());
                    }.bind(this));

                    container.addEventListener('viewRedrawn', function () {
                        this.afterRedraw(view);
                    }.bind(this));
                } catch (e) {
                    Console.debug('cannot initialize selectable plugin: ' + e.message);
                }
            },

            /**
             * @private
             * @param {Array|Node[]} selections
             */
            selectionsChanged: function (selections) {
                if (this.selections.length === selections.length) {
                    var intersection = this.selections.filter(function (nodeA) {
                        return selections.some(function (nodeB) {
                            return nodeB === nodeA;
                        });
                    });
                    return intersection.length !== selections.length;
                }
                return true
            },

            isDoubleClick: function () {
                if (this.doubleClickStart === null) {
                    return false;
                }
                console.debug(((new Date()) - this.doubleClickStart), this.config.doubleClickDelay);
                return ((new Date()) - this.doubleClickStart) <= this.config.doubleClickDelay;
            },

            afterRedraw: function (view) {
                if (this.selectable) {
                    this.selectable.setContainer(this.config.appendTo || view.getContainerElement());
                    this.selectable.update();
                }
            },
        });
    }
);

