
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input',[
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Brick/Form/Field',
    ],
    function ($, Field) {
        'use strict';

        /**
         * @class Input
         * @extends Field
         */
        var Input = Field.extend({
            _type: null,
            _length: null,
            _counter: null,
            _useSecondsGranularity: null,

            updateCounter: function () {
                if (this._counter) {
                    this._counter.innerHTML = parseInt(this.getLength()) - this.getValue().length;
                }
            },

            onDomReady: function () {
                this.__super();

                this._counter = $('#' + this.getId() + 'Counter .counter').shift();

                if (this._counter) {
                    this.updateCounter();
                    this.on('keyup', function (e, options) {
                        this.updateCounter();
                    }, true);
                }

                // attach change event data
                this.on('change', function (e, options) {
                    options.fieldName = this.getName();
                    options.fieldValue = this.getValue();
                }, true, true);// its important that we capture the event before anyone gets to process it
            },

            /**
             * overwrite and attach custom setup handlers
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setTypeFromSetup(setup);
                this.setLengthFromSetup(setup);
                this.setUseSecondsGranularityFromSetup(setup);
            },

            /**
             * at the moment there is only one type important to us an that is file
             * when a file component is loaded we need to ensure the correct value is returned
             * so we store the type to allow us to handle this
             * @param setup
             */
            setTypeFromSetup: function (setup) {
                if (setup.type !== undefined) {
                    this.setType(setup.type);
                }
            },

            setValue: function (value) {
                if (this.getType() !== 'submit') {
                    var type = this.getElement().type;

                    if (this.getUseSecondsGranularity() && (type === 'datetime-local' || type === 'time')) {
                        this.getElement().step = 1; // Force seconds to appear (Chrome 39).
                    }

                    // datetime-local requires an infix 'T' separating the date and the time. Adding this server side
                    // requires us to format every date to add it and browsers that do not yet support this type will
                    // display that T literally. (This evaluation will fail for those browsers.)
                    if (type === 'datetime-local' && value) {
                        value = value.replace(new RegExp(/^(\d{4}-\d{2}-\d{2})\s+(\d{2}:\d{2}:\d{2})$/), '$1T$2');
                    }

                    this.__super(value);

                    this.updateCounter();
                }
            },

            getValue: function () {
                var value = this.__super(),
                    type = this.getElement().type;

                if (value) {
                    if (this.getUseSecondsGranularity()) {
                        // Even though we force seconds to show, if the seconds are '00', Chrome will not add them to the
                        // value (which does not happen for the minutes). Add them manually here.
                        if (type === 'datetime-local') {
                            value = value.replace(new RegExp(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})$/), '$1T$2:00');
                        } else if (type === 'time') {
                            value = value.replace(new RegExp(/^(\d{2}:\d{2})$/), '$1:00');
                        }
                    }

                    // See also getValue for an explanation.
                    if (type === 'datetime-local') {
                        value = value.replace(new RegExp(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/), '$1 $2');
                    }
                }

                return value;
            },

            /**
             *
             * @param type
             * @return this
             */
            setType: function (type) {
                this._type = type;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getType: function () {
                return this._type;
            },

            setLengthFromSetup: function (setup) {
                if (setup.length !== undefined) {
                    this.setLength(setup.length);
                }
            },

            /**
             *
             * @param length
             * @return this
             */
            setLength: function (length) {
                this._length = length;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getLength: function () {
                return this._length;
            },

            setUseSecondsGranularityFromSetup: function (setup) {
                if (setup.useSecondsGranularity !== undefined) {
                    this.setUseSecondsGranularity(setup.useSecondsGranularity);
                }
            },

            /**
             * @param use
             * @return this
             */
            setUseSecondsGranularity: function (use) {
                this._useSecondsGranularity = use;
                return this;
            },

            /**
             * @return {bool}
             */
            getUseSecondsGranularity: function () {
                return this._useSecondsGranularity;
            },

            handleBeforeFormSave: function (e, options) {
                if (this.getType() !== 'file') {
                    this.__super(e, options);
                } else {
                    options.files[this.getName()] = this.getElement().files;
                }
            },
        });
        return Input;
    }
);

