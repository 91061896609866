
define(
    'Inventis/Bundle/BricksBundle/Brick/IdentifierFieldsAwareMixin',[
        'Inventis/Bundle/BricksBundle/Mixin',
    ],
    function (Mixin) {
        'use strict';

        return Mixin({
            _identifierFields: null,

            addIdentifierFieldsFromSetup: function (setup) {
                if (setup.identifierFields) {
                    this.setIdentifierFields(setup.identifierFields);
                }
            },

            /**
             *
             * @param {String|Array} identifierFields
             * @return {self}
             */
            setIdentifierFields: function (identifierFields) {
                if (typeof identifierFields !== 'object') {
                    this._identifierFields[identifierFields] = true;
                } else {
                    this._identifierFields = identifierFields;
                }

                return this;
            },

            /**
             *
             * @return {String|Array}
             */
            getIdentifierFields: function () {
                return this._identifierFields;
            },
        });
    }
);

