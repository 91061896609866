define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',
        'Inventis/Bundle/BricksBundle/HTML/Template',
        'Inventis/Bundle/BricksBundle/Promise',
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Polyfill/ElementClosest',
    ],
    /**
     * @param {GridPluginBase} GridPluginBase,
     * @param {HTMLTemplate} Template
     * @param {Promise} Promise
     * @param {Console} Console
     *
     * @return {ActionToolbarPlugin}
     */
    function (GridPluginBase, Template, Promise, Console) {
        'use strict';

        /**
         * @class ActionToolbarPlugin
         * @extends GridPluginBase
         */
        return GridPluginBase.extend({
            /**
             * @var {HTMLTemplate}
             */
            template: null,

            /**
             * @param {ActionToolbarPlugin.Action[]}
             */
            actions: null,

            __construct: function (config) {
                this.actions = [];
                this.config = this.processConfig(config);
            },

            processConfig: function (config) {
                return {
                    containerSelector: config.containerSelector || '.action-toolbar-template',
                    actionMenuSelector: config.actionMenuSelector || '[data-actions-menu]',
                    actionMenuTriggerSelector: config.actionMenuTriggerSelector || '[data-actions-menu-trigger]',
                    actions: this.processActions(config),
                };
            },

            /**
             * @param {Object} config
             *
             * @return {Array}
             */
            processActions: function (config) {
                var actions = config.actions && config.actions.length ? config.actions : [],
                    parsedActions = [];

                actions.forEach(function (action) {
                    if (!action.module) {
                        throw new Error('Missing module for action: ' + JSON.stringify(action));
                    }
                    parsedActions.push(Object.assign({
                        iconClass: config.defaultIconClass || '',
                    }, action));
                });

                return parsedActions;
            },

            onCreate: function (grid) {
                return new Promise(function (resolve, reject) {
                    this.__super(grid).then(function () {
                        this.config.actions.forEach(function (actionConfig) {
                            require(
                                [actionConfig.module],
                                /**
                                 * @param {ActionToolbarPlugin.Action} Action
                                 */
                                function (Action) {
                                    this.actions.push(new Action(grid, actionConfig));
                                    if (this.actions.length === this.config.actions.length) {
                                        resolve();
                                    }
                                }.bind(this)
                            );
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            },

            beforeRender: function (view) {
                var container = view.getContainerElement();
                container.addEventListener('click', this.onContainerClicked.bind(this, container));
                container.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));
                container.addEventListener('selectionDoubleClick', this.onSelectionDblClick.bind(this));
                container.addEventListener('actionSelected', this.onActionSelected.bind(this));
            },

            afterRender: function (view) {
                var container = view.getContainerElement();
                this.template = new Template(container.querySelector(this.config.containerSelector));
                this.template.update({actions: [], hasActions: false});
            },

            afterRedraw: function () {
                this.template.update({actions: [], hasActions: false});
            },

            onContainerClicked: function (container, event) {
                if (event.target.closest(this.config.actionMenuTriggerSelector) !== null) {
                    container.querySelector(this.config.actionMenuSelector).classList.toggle('-active');
                }
            },

            onActionSelected: function (event) {
                if (!event.target.dataset || !event.target.dataset.actionIndex) {
                    throw new Error('Missing action-index in dataset, did you forget to include it in your template?');
                }
                var action = this.getAction(parseInt(event.target.dataset.actionIndex));
                action.execute(this.selections)
                    .then(function (message) {
                        var actionCompletedEvent = new Event('actionCompleted', {bubbles: true});
                        actionCompletedEvent.message = message;
                        this.getGrid().getContainer().dispatchEvent(actionCompletedEvent);
                    }.bind(this))
                    .catch(function (error) {
                        var actionFailedEvent = new Event('actionFailed', {bubbles: true});
                        actionFailedEvent.error = error;
                        this.getGrid().getContainer().dispatchEvent(actionFailedEvent);
                        Console.error(error);
                    }.bind(this));
                event.preventDefault();
            },

            /**
             * @param {Number} index
             *
             * @return {ActionToolbarPlugin.Action}
             */
            getAction: function (index) {
                var action = this.actions[parseInt(index)];
                if (action === undefined) {
                    throw new Error('Invalid action index ' + index);
                }
                return action;
            },

            onSelectionChanged: function (event) {
                this.selections = event.selections || [];
                var actions = [];
                if (this.selections.length > 0) {
                    actions = this.createActions(this.selections.length > 1);
                }
                this.template.update({actions: actions, hasActions: actions.length > 0});
            },

            onSelectionDblClick: function (event) {
                this.selections = event.selections || [];
                if (this.selections.length === 0) {
                    return;
                }
                var multiple = this.selections.length > 1;
                this.actions.forEach(function (action, index) {
                    /** @var {ActionToolbarPlugin.Action} action */
                    if (action.canSupportDblClick() && (multiple === false || action.canSupportMultiple())) {
                        action.execute(this.selections);
                    }
                }.bind(this));
            },

            /**
             * @param {Boolean} multiple
             *
             * @return {Object[]}
             */
            createActions: function (multiple) {
                var actions = [];
                this.actions.forEach(function (action, index) {
                    /** @var {ActionToolbarPlugin.Action} action */
                    if (multiple === false || action.canSupportMultiple()) {
                        var actionModel = action.getModel();
                        actionModel.index = index;
                        actions.push(actionModel);
                    }
                });
                return actions;
            },
        });
    }
);

