
/**
 * This Adapter mixin can be used by a class if adapter functionality needs to be implemented
 * it will wrap the most useful features
 * this mixin already uses the observable mixin as it requires its usage
 */
define(
    'Inventis/Bundle/BricksBundle/Mixins/Adapters/ECMAScript5ApplicationAdapter',[
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Mixin',
        'Inventis/Bundle/BricksBundle/Mixins/Observable',
        'Inventis/Bundle/BricksBundle/JsonEncoder',
    ],
    function (console, mixin, Observable, JsonEncoder) {
        'use strict';
        // set adapter defaults and external dependencies
        // ready state tracking courtesy of https://github.com/jfriend00/docReady/blob/master/docready.js
        var readyList = [];
        var readyFired = false;
        var readyEventHandlersInstalled = false;
        // call this when the document is ready
        // this function protects itself against being called more than once
        function ready() {
            if (!readyFired) {
                // this must be set to true before we start calling callbacks
                readyFired = true;
                for (var i = 0; i < readyList.length; i++) {
                    // if a callback here happens to add new ready handlers,
                    // the docReady() function will see that it already fired
                    // and will schedule the callback to run right after
                    // this event loop finishes so all handlers will still execute
                    // in order and no new ones will be added to the readyList
                    // while we are processing the list
                    readyList[i].fn.call();
                }
                // allow any closures held by these functions to free
                readyList = [];
            }
        }

        function readyStateChange() {
            if (document.readyState === 'complete') {
                ready();
            }
        }

        // return the adapter methods
        return mixin({
            use: [Observable],

            /**
             * object holds json methods like encode/decode
             * @deprecated use the JsonEncoder instead
             */
            JSON: JsonEncoder, // BC compatibility

            /**
             * function to use when you need code executed as soon as
             * the dom is ready for ui interaction
             * @param {Function} callback
             * @param {Object} scope for callback function
             */
            ready: function (callback, scope) {
                /*
                 * we need to ensure scope is maintained when method is called
                 * so we wrap the method and apply scope as soon as we get called
                 */
                var callbackScopeWrapper = function () {
                    callback.call(scope);
                };
                // if ready has already fired, then just schedule the callback
                // to fire asynchronously, but right away
                if (readyFired) {
                    setTimeout(function () {
                        callbackScopeWrapper();
                    }, 1);
                    return;
                }
                // add the function and context to the list
                readyList.push({fn: callbackScopeWrapper});

                // if document already ready to go, schedule the ready function to run
                // IE only safe when readyState is "complete", others safe when readyState is "interactive"
                if (document.readyState === 'complete'
                    || (!document.attachEvent && document.readyState === 'interactive')
                ) {
                    setTimeout(ready, 1);
                } else if (!readyEventHandlersInstalled) {
                    readyEventHandlersInstalled = true;
                    // otherwise if we don't have event handlers installed, install them
                    if (document.addEventListener) {
                        // first choice is DOMContentLoaded event
                        document.addEventListener('DOMContentLoaded', ready, false);
                        // backup is window load event
                        window.addEventListener('load', ready, false);
                    } else {
                        // must be IE
                        document.attachEvent('onreadystatechange', readyStateChange);
                        window.attachEvent('onload', ready);
                    }
                }
            },

            /**
             * holds more information about the browser currently in use
             */
            browser: {
                /**
                 * object holds support flags for various features
                 */
                support: {
                    XMLHttpRequestLevel2: Boolean(window.FormData),
                },
            },

            String: {
                /**
                 *
                 * @param {String} format
                 * @param {Array}  params
                 * @return {String}
                 */
                format: function (format, params) {
                    return format.replace(/\{(\d+)}/g, function (match, i) {
                        return params[i];
                    });
                },
            },

            /**
             * merge 2 object together, overwriting the properties from the receiver
             * @param {Object} receiver the base object to start from
             * @param {Object} provider the object that has properties that should go to the receiver
             * @param {Boolean} [asNew]
             *
             * @return {Object}
             */
            merge: function (receiver, provider, asNew) {
                asNew = asNew === undefined ? false : asNew;
                if (asNew) {
                    receiver = this.merge({}, receiver);
                }
                // Merge the object into the receiver object
                for (var prop in provider) {
                    if (Object.prototype.hasOwnProperty.call(provider, prop)) {
                        receiver[prop] = provider[prop];
                    }
                }

                return receiver;
            },
        });
    }
);

