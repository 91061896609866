
/**
 * The backpanel component is a plain panel component that listens to a specified event for opening
 */
define(
    'Inventis/Bundle/BricksBundle/Brick/Panel/BackPanel',[
        'Inventis/Bundle/BricksBundle/Brick/Panel',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Panel, HTMLElement, $) {
        'use strict';

        var BackPanel = Panel.extend({
            /**
             * @var {Array}
             */
            _dirties: null,

            /**
             * Constructor.
             */
            __construct: function () {
                this.__super(arguments);
                this._dirties = [];
            },

            /**
             * Override to attach additional listeners.
             */
            attachListeners: function () {
                this.__super(arguments);

                this.on('backPanelOpen', this.onBackPanelOpenRequest, true);
                this.on('backPanelClose', this.onBackPanelCloseRequest, true);
                this.on('dirtyChange', this.onDirtyChange, true);

                var closeButton = new HTMLElement($('#' + this.getId() + ' .heading .window-button-close').shift());

                closeButton.on('click', this.onBackPanelCloseRequest.bind(this));
            },

            /**
             * Handler for dirtyChange.
             *
             * @param {String} e
             * @param {*}      options
             */
            onDirtyChange: function (e, options) {
                if (options.isDirty) {
                    if (this._dirties.indexOf(options.id) === -1) {
                        this._dirties.push(options.id);
                    }
                } else {
                    this._dirties.splice(this._dirties.indexOf(options.id), 1);
                }
            },

            /**
             * Handler for backPanelOpen. This will clear the dirty state and behave as a normal show event.
             *
             * @note these type of events will not be bubbled further up the chain.
             *
             * @param {String} e
             * @param {*}      options
             */
            onBackPanelOpenRequest: function (e, options) {
                this._dirties = [];
                this.show();

                return false;
            },

            /**
             * Handler for backPanelClose, this event should only be used if you expect the component to check its
             * dirty state and send a 'dirtyCloseRequested' event when it's dirty. If it isn't, it will simply behave a
             * normal hide.
             *
             * @note To confirm a close, you can simply send a hide event.
             * @note these type of events will not be bubbled further up the chain.
             *
             * @param {String} e
             * @param {*}      options
             */
            onBackPanelCloseRequest: function (e, options) {
                if (this._dirties.length === 0) {
                    this.hide();
                } else {
                    this.fire('dirtyCloseRequested');
                }

                return false;
            },
        });

        return BackPanel;
    }
);

