define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/LocationAction',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/Action',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Action, Promise) {
        'use strict';

        /**
         * @class ActionToolbarPlugin.LocationAction
         * @extends ActionToolbarPlugin.Action
         */
        return Action.extend({

            processConfig: function (config) {
                if (!config.url) {
                    throw new Error('Invalid url specified for LocationAction!');
                }
                return Object.assign(
                    this.__super(config),
                    {
                        url: config.url,
                        supportsMultiple: config.supportsMultiple !== undefined ? config.supportsMultiple : false,
                        idMatcher: new RegExp(config.idMatcher || /{\s?id(?:s)?\s?}/g),
                        message: config.message || 'opening location',
                    }
                );
            },

            doExecute: function (selections) {
                return new Promise(function (resolve, reject) {
                    var ids = this.getIds(selections).join(','),
                        url = new URL(this.config.url.replace(this.config.idMatcher, ids), window.location.origin);
                    if (url.href) {
                        resolve(this.config.message);
                        window.location.href = url.href;
                    } else {
                        reject(new Error('Invalid url ' + this.config.url + ' parsed for LocationAction'));
                    }
                }.bind(this));
            },
        });
    }
);

