
/**
 * Text component uses an adapter mixin that will allow
 * quick swap with another one would you like to do so
 */
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Text',[
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Brick/Form/Field',
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function ($, Field, Console, Promise) {
        'use strict';

        /**
         * @class Text
         * @extends Brick
         */
        var Text = Field.extend({

            _length: null,
            _richText: null,
            _relatedTable: null,

            editorConfig: null,
            renderStrategyPromise: null,
            /**
             * @var {TextRenderStrategy}
             */
            renderStrategyInstance: null,

            updateCounter: function () {
                if (this._counter) {
                    this._counter.innerHTML = parseInt(this.getLength()) - this.getValue().length;
                }
            },

            getRenderStrategyInstance: function () {
                if (!this.renderStrategyPromise) {
                    throw new Error('trying to retrieve render strategy instance before render!');
                }
                return this.renderStrategyPromise;
            },

            setEditorValue: function (value) {
                return this.getRenderStrategyInstance().then(function (renderStrategy) {
                    renderStrategy.setEditorValue(value);
                });
            },

            setEditorConfig: function (editorConfig) {
                this.editorConfig = editorConfig;
            },

            getEditorConfig: function () {
                return this.editorConfig;
            },

            setRenderStrategyFromSetup: function (setup) {
                if (this.getRichText()) {
                    if (!setup.renderStrategy) {
                        throw new TypeError('expected renderStrategy in text brick setup');
                    }
                    if (!setup.renderStrategy.module) {
                        throw new Error('unexpected renderStrategy.module, must be set to a valid module');
                    }
                    this.renderStrategy = setup.renderStrategy.module;
                    this.setEditorConfig(setup.renderStrategy.config || {});
                }
            },

            onDomReady: function () {
                this.__super();

                this._counter = $('.counter', this.getComponentElement().getElement()).shift();

                if (this._counter) {
                    this.updateCounter();
                    this.getComponentElement().on('keyup', function (e, options) {
                        this.updateCounter();
                    }.bind(this), true);
                }
            },

            /**
             *
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setRichTextFromSetup(setup);
                this.setRenderStrategyFromSetup(setup);
                this.setRelatedTableFromConfig(setup);
                this.setLengthFromSetup(setup);
            },

            /**
             *
             * @param setup
             */
            setRelatedTableFromConfig: function (setup) {
                if (setup.relatedTable !== undefined) {
                    this.setRelatedTable(setup.relatedTable);
                }
            },

            /**
             *
             * @param table
             * @return Text
             */
            setRelatedTable: function (table) {
                this._relatedTable = table;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getRelatedTable: function () {
                return this._relatedTable;
            },

            /**
             *
             * @param setup
             */
            setRichTextFromSetup: function (setup) {
                if (setup.richText !== undefined) {
                    this.setRichText(setup.richText);
                }
            },

            /**
             *
             * @param setup
             */
            setRichTextEditorConfigFromSetup: function (setup) {
                if (setup.config) {
                    this.setEditorConfig(setup.config);
                }
            },

            /**
             *
             * @param state
             * @return {*}
             */
            setRichText: function (state) {
                this._richText = Boolean(state);
                return this;
            },

            /**
             *
             * @return {Boolean}
             */
            getRichText: function () {
                return this._richText;
            },

            /**
             *
             */
            render: function () {
                if (this.getRichText()) {
                    this.renderStrategyPromise = new Promise(function (resolve, reject) {
                        require([this.renderStrategy], function (RenderStrategy) {
                            try {
                                /** @var {TextRenderStrategy} renderStrategyInstance */
                                this.renderStrategyInstance = new RenderStrategy(this, this.getEditorConfig());
                                this.renderStrategyInstance.render().catch(reject);
                                resolve(this.renderStrategyInstance);
                            } catch (e) {
                                reject(e);
                            }
                        }.bind(this), function (error) {
                            Console.error(error);
                        });
                    }.bind(this));
                } else {
                    this.__super();
                }
            },

            /**
             * when we are dealing with a rich text instance we need to update its value rather
             * then updating our default element
             * @param value
             */
            setValue: function (value) {
                if (this.getRichText()) {
                    this.setEditorValue(value);
                } else {
                    this.__super(value);
                }

                this.updateCounter();

                return this;
            },

            /**
             *
             * @return {String}
             */
            getValue: function () {
                if (this.getRichText() && this.renderStrategyInstance) {
                    return this.renderStrategyInstance.getEditorValue();
                }
                return this.__super();
            },

            setLengthFromSetup: function (setup) {
                if (setup.length !== undefined) {
                    this.setLength(setup.length);
                }
            },

            /**
             *
             * @param length
             * @return this
             */
            setLength: function (length) {
                this._length = length;
                return this;
            },

            /**
             *
             * @return {String}
             */
            getLength: function () {
                return this._length;
            },
        });
        return Text;
    }
);

