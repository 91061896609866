define(
    'Inventis/Bundle/BricksBundle/Grid/View/AbstractView',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/HTML/Template',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Class, HTMLTemplate, Promise) {
        'use strict';
        /**
         * virtual definition for important config properties
         * @class ViewConfig
         */
        /**
         * @var {Store}
         * @name ViewConfig#store
         */
        /**
         * @var {HTMLTemplateElement}
         * @name ViewConfig#template
         */

        /**
         * @class AbstractView
         * @implements View
         * @abstract
         */
        return Class.extend({
            abstract: ['getCurrentStart', 'getRangeSize'],

            /**
             * @var {ViewConfig}
             */
            config: null,
            /**
             *
             */
            model: null,

            /**
             * based on shift key incremental behaviour is (de)activated
             * @var {Boolean}
             */
            incremental: false,

            totalCount: null,
            /**
             * @var {Function(<Object>)}
             */
            modelProcessor: null,

            __construct: function (config) {
                try {
                    this.listeners = [];
                    this.model = this.createDefaultModel();
                    this.config = this.processConfig(config);
                    this.modelProcessor = config.modelProcessor || function (model) {return model;};
                } catch (e) {
                    throw new Error('Invalid configuration for PaginatedView: ' + e.message);
                }
            },

            createDefaultModel: function () {
                return {rows: [], hasRows: false};
            },

            processConfig: function (config) {
                if (!config) {
                    throw new Error('No config defined');
                }
                var required = 'store,template'.split(',');
                for (var i = 0; i < required.length; ++i) {
                    if (!config[required[i]]) {
                        throw new Error(required[i] + ' not defined or invalid');
                    }
                }

                var template = document.querySelector(config.template);
                if (template === null) {
                    throw new Error('Failed to locate template with selector: ' + config.template);
                }
                if (!(template instanceof HTMLTemplateElement)) {
                    throw new Error('Failed to locate instanceof HTMLTemplateElement with selector: ' + config.template);
                }
                return {
                    store: config.store,
                    template: template,
                    modelProcessor: config.modelProcessor || function (model) {
                        return model;
                    },
                    renderers: config.renderers,
                };
            },

            getContainerElement: function () {
                return this.getTemplateElement().parentElement;
            },

            /**
             * @return {HTMLTemplateElement}
             */
            getTemplateElement: function () {
                return this.config.template;
            },

            getStore: function () {
                return this.config.store;
            },

            render: function () {
                return new Promise(function (resolve, reject) {
                    try {
                        this.getStore().getRange(this.getCurrentStart(), this.getRangeSize())
                            .then(function (result) {
                                this.updateModel(result);
                                this.doRender();
                                resolve(result.data);
                            }.bind(this)).catch(reject);
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            redraw: function () {
                return new Promise(function (resolve, reject) {
                    try {
                        this.getStore().getRange(this.getCurrentStart(), this.getRangeSize())
                            .then(function (result) {
                                this.updateModel(result);
                                this.doRender();

                                var viewRedrawnEvent = new Event('viewRedrawn', {
                                    bubbles: true,
                                });

                                this.getContainerElement().dispatchEvent(viewRedrawnEvent);

                                resolve(result.data);
                            }.bind(this)).catch(reject);
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            getData: function () {
                return this.model;
            },

            /**
             * @protected
             */
            updateModel: function (result) {
                this.model.rows = result.data;
                this.model.hasRows = result.data.length > 0;
                this.model.filters = this.getStore().getFilters();
                this.model.isFiltered = Object.values(this.model.filters).length > 0;
                this.modelProcessor(this.model);
            },

            doRender: function () {
                this.getTemplate().render();
            },

            /**
             * @return {HTMLTemplate}
             */
            getTemplate: function () {
                if (!this.template) {
                    this.template = new HTMLTemplate(
                        this.config.template,
                        this.model,
                        this.config.renderers
                    );
                }
                return this.template;
            }
        });
    }
);

