
define(
    'Inventis/Bundle/BricksBundle/Mixins/Handling',[
        'Inventis/Bundle/BricksBundle/Mixin',
    ],
    function (Mixin, Element) {
        var Handling = Mixin({
            abstract: ['getFormElement'],
            /**
             * @var {Array}
             */
            _handling: null,

            /**
             *
             * @param setup
             */
            setHandlingFromSetup: function (setup) {
                if (setup.handling) {
                    this._handling = setup.handling;
                }
            },

            /**
             * Attaches listeners required for handling.
             */
            attachHandlingListeners: function () {
                var formElement = this.getFormElement();

                if (this._handling && this._handling.length > 0 && formElement === undefined) {
                    throw new Error('The element must be part of a form in order to use handling!');
                }

                if (this._handling && this._handling.length > 0) {
                    this.getFormElement().on('change', this.applyHandling.bind(this));
                    this.getFormElement().on('fieldInitialized', this.applyHandling.bind(this));
                }
            },

            applyHandling: function (event, options) {
                for (var i = 0; i < this._handling.length; ++i) {
                    var name = this._handling[i].name;

                    name = (typeof name === 'object' ? name : [name]);

                    if (name.indexOf(options.fieldName) !== -1) {
                        var valid = (options.fieldValue + '').match(this._handling[i].match),
                            eventName = (valid !== null ? this._handling[i].valid : this._handling[i].invalid);

                        if (eventName) {
                            this.fire(eventName, options, false);
                        }
                    }
                }
            }
        });
        return Handling;
    }
);

