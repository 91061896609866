
define(
    'Inventis/Bundle/BricksBundle/Brick/Manager',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Mixins/Observable',
    ],
    function (Class, Observable) {
        'use strict';

        var instance;
        var Manager = Class.extend({
            use: [Observable],

            __construct: function () {
                this.setupObservable();
                this.attachListeners();
            },

            /**
             * creates listeners for the different components
             */
            attachListeners: function () {
                this.on('createBricks', function (e, options) {
                    this.createBricks(options.children);
                });
            },

            /**
             * creates components from a list of component configurations
             * @param bricks
             */
            createBricks: function (bricks) {
                if (bricks.length === undefined) {
                    throw new Error('Failed to create components, components list must be an array.');
                }
                /*
                 * add components asynchronous, the order in which they are loaded
                 * is not important
                 */
                for (var i = 0; i < bricks.length; i++) {
                    if (bricks[i].class === false) { // component doesn't have JS component
                        // see if there are sub-component configurations available and create those
                        if (bricks[i].children) {
                            this.createBricks(bricks[i].children);
                        }
                    } else {
                        if (!bricks[i].class) {
                            throw new TypeError('Did not find a valid brick class in brick configuration');
                        }
                        this.createBrick(bricks[i].class, bricks[i]);
                    }
                }
            },

            /**
             * creates a brick after asynchronous call and passes it as an argument
             * to the optional callback function once the brick has been created
             * @param {String} brick
             * @param {Object} config
             * @param {Function} [callback]
             * @param {Object} [scope]
             */
            createBrick: function (brick, config, callback, scope) {
                var me = this;// reference this inside callback
                // do async call and add component after initialization
                require(
                    [brick],
                    function (Brick) {
                        if (Brick === undefined) {
                            throw new TypeError(config.class + ' could not be loaded. Ensure namespace is correct.');
                        }
                        var brick = new Brick();
                        brick.init(config);
                        if (callback !== undefined) {
                            callback.call(scope, brick);
                        }
                        me.fire('brickCreated', {brickType: brick});
                    }
                );
            },
        });

        // create singleton
        return (instance = (instance || new Manager()));
    }
);

