define(
    'Inventis/Bundle/BricksBundle/Brick/Grid/DeleteRecordsRequestAction',[
        'Inventis/Bundle/BricksBundle/Brick/Grid/ComponentRequestAction',
    ],
    /**
     * @param {GridBrick.ComponentRequestAction} ComponentRequestAction
     *
     * @return {GridBrick.ComponentRequestAction}
     */
    function (ComponentRequestAction) {
        'use strict';

        /**
         * @class GridBrick.DeleteRecordsRequestAction
         * @xtends GridBrick.ComponentRequestAction
         */
        return ComponentRequestAction.extend({

            processConfig: function (config) {
                return Object.assign(
                    this.__super(config),
                    {
                        name: config.name || 'delete',
                        iconClass: config.iconClass || '-delete',
                        supportsMultiple: config.supportsMultiple ? config.supportsMultiple : true,
                        identifierField: config.identifierField || 'id',
                    }
                );
            },

            /**
             * @param {Array} selections
             *
             * @return {Object}
             */
            getBody: function (selections) {
                var body = {
                        componentAction: this.config.componentAction,
                        componentId: this.config.componentId,
                    },
                    ids = this.getIds(selections),
                    idField = this.config.identifierField;

                ids.forEach(function (value, index) {
                    body[idField + '[' + index + ']'] = value;
                });

                return body;
            },

            /**
             * @param {Grid} grid
             * @param {Array} selections
             *
             * @return  {Promise}
             */
            updateGrid: function (grid, selections) {
                var ids = this.getIds(selections);
                return grid.deleteRecords(ids).then(function () {
                    var gridRecordsDeletedEvent = new CustomEvent('recordsDeleted', {
                        detail: {
                            ids: ids,
                        },
                        bubbles: true,
                    });
                    grid.getContainer().dispatchEvent(gridRecordsDeletedEvent);
                }).catch(function (e) {
                    var gridRecordsDeleteFailedEvent = new CustomEvent('deleteRecordsFailed', {
                        detail: {
                            ids: ids,
                            message: e.message,
                        },
                        bubbles: true,
                    });
                    grid.getContainer().dispatchEvent(gridRecordsDeleteFailedEvent);
                });
            },
        });
    }
);

