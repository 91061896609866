define(
    'Inventis/Bundle/BricksBundle/Grid/Store/Record',[
        'Inventis/Bundle/BricksBundle/Class',
    ],
    function (Class) {
        'use strict';
        /**
         * @class Record
         */
        var Record = Class.extend({
            /**
             * @var {String}
             */
            __model__: null,

            /**
             * @var {Object}
             */
            __data__: null,

            __construct: function (model, data) {
                if (!data || !(data instanceof Object)) {
                    throw new Error('Cannot create record, invalid data provided');
                }
                if (!model.idField) {
                    throw new Error('Model must have id defined');
                }
                if (!model.name) {
                    throw new Error('Model must have name defined to identify record types');
                }
                if (data[model.idField] === undefined) {
                    data[model.idField] = null;
                }
                if (model.childrenFields === undefined) {
                    model.childrenFields = [];
                }
                if (Array.isArray(model.childrenFields) !== true) {
                    throw new TypeError('expected childrenFields model option to be an array');
                }
                if (model.childrenFields) {
                    this.normalizeChildren(model.childrenFields, data);
                    model.childrenFields.forEach(function (childField) {
                        if (data[childField] === undefined) {
                            return;
                        }
                        data[childField] = data[childField].map(function (childData, index) {
                            return this.createRecord(model, childData);
                        }.bind(this));
                    }.bind(this));
                }

                this.__model__ = model;
                this.__data__ = data;

                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        Object.defineProperty(this, prop, {
                            get: function (prop) {
                                return this.get(prop);
                            }.bind(this, prop),
                            set: function (prop, value) {
                                return this.set(prop, value);
                            }.bind(this, prop),
                        });
                    }
                }
            },

            getId: function () {
                return this.get(this.__model__.idField);
            },

            /**
             * @return mixed
             */
            get: function (key) {
                return this.__data__[key];
            },

            /**
             * @return void
             */
            set: function (key, value) {
                this.__data__[key] = value;
            },

            removeChild: function (id) {
                var idField = this.__model__.idField;
                return this.__model__.childrenFields.some(function (childField) {
                    if (this.__data__[childField] === undefined) {
                        return false;
                    }

                    return this.__data__[childField].some(function (childRecord, index) {
                        if (childRecord[idField] === id) {
                            delete this.__data__[childField][index];
                            return true;
                        }

                        return childRecord.removeChild(id);
                    }.bind(this));

                }.bind(this));
            },

            hasChild: function (id) {
                var idField = this.__model__.idField;
                return this.__model__.childrenFields.some(function (childField) {
                    if (this.__data__[childField] === undefined) {
                        return false;
                    }
                    return this.__data__[childField].some(function (childRecord, index) {
                        if (childRecord[idField] === id) {
                            return true;
                        }
                        return childRecord.hasChild(id);
                    }.bind(this));
                }.bind(this));
            },

            getChild: function (id) {
                var idField = this.__model__.idField,
                    child = null;
                this.__model__.childrenFields.some(function (childField) {
                    if (this.__data__[childField] === undefined) {
                        return false;
                    }
                    return this.__data__[childField].some(function (childRecord) {
                        if (childRecord[idField] === id) {
                            child = childRecord;
                            return true;
                        }
                        child = childRecord.getChild(id);
                        return child !== null;
                    }.bind(this));
                }.bind(this));

                return child;
            },

            /**
             * @private
             *
             * @param {Array|Object} data
             *
             * @returns {Record}
             */
            createRecord: function (model, data) {
                if (model.childrenFields) {
                    this.normalizeChildren(model.childrenFields, data);
                }
                return new Record(model, data);
            },

            normalizeChildren(childrenFields, data) {
                childrenFields.forEach(function (childField) {
                    if (data[childField] && !Array.isArray(data[childField])) {
                        var map = new Map(Object.entries(data[childField]));
                        data[childField] = Array.from(map.values());
                    }
                }.bind(this));
            }
        });

        return Record;
    }
);

