define(
    'Inventis/Bundle/BricksBundle/Ajax',[
        'nanoajax', // https://github.com/yanatan16/nanoajax
        'Inventis/Bundle/BricksBundle/Console',
    ],
    /**
     * @param {nanoajax} nanoajax
     * @param Console
     */
    function (nanoajax, Console) {
        function encodeRequestData(data) {
            return Object.keys(data).map(function (k) {
                var value = data[k];
                if ((value instanceof Object)
                    || (value instanceof Array)
                ) {
                    value = JSON.stringify(value);
                }
                return encodeURIComponent(k) + '=' + encodeURIComponent(value);
            }).join('&');
        }

        function normalizeParams(params) {
            params = normalizeHeaders(params);
            params = normalizeBody(params);

            return params;
        }

        function normalizeHeaders(params) {
            if (params.headers) {
                // normalize headers to lowercase
                for (var header in params.headers) {
                    if (params.headers.hasOwnProperty(header)) {
                        var parts = [];
                        header.split('-').forEach(function (part) {
                            part = part.toLowerCase();
                            parts.push(part.charAt(0).toUpperCase() + part.slice(1));
                        });
                        var camelCased = parts.join('-');
                        if (camelCased !== header) {
                            params.headers[camelCased] = params.headers[header];
                            delete params.headers[header];
                        }
                    }
                }
            }
            var contentType = getContentType(params);
            if (!contentType
                && (params.body instanceof Object)
                && !(params.body instanceof FormData)
                && params.method !== 'GET'
            ) {
                if (!params.headers) {
                    params.headers = {};
                }
                params.headers['Content-Type'] = 'application/json';
            }
            return params;
        }

        /**
         * fetch headers from normalized headers data
         */
        function getContentType(params) {
            var contentType = '';
            if (params.headers && params.headers['Content-Type']) {
                contentType = params.headers['Content-Type'];
            } else if (params.data instanceof Object) {
                contentType = 'application/json';
            }
            return contentType.toLowerCase();
        }

        function normalizeBody(params) {
            if (params.body instanceof Object) {
                var contentType = getContentType(params);
                if (params.method === 'GET' && (!contentType || contentType === 'application/x-www-form-urlencoded')) {
                    params.url += (params.url.indexOf('?') === -1 ? '?' : '&') + encodeRequestData(params.body);
                    delete params.body;
                }
                if (params.body && !(params.body instanceof FormData)) {
                    if (!contentType || contentType === 'application/json') {
                        params.body = JSON.stringify(params.body);
                    } else {
                        params.body = encodeRequestData(params.body);
                    }
                }
            }

            return params;
        }

        // wrapping to add features like data object to request params string conversion
        return function (params) {
            if (!params.url) {
                throw new Error('No url specified in ajax parameters!');
            }
            params = normalizeParams(params);

            var onReady = function (statusCode, response, request) {
                var responseContentType = request.getResponseHeader('content-type');
                if (responseContentType === 'application/json' && typeof response === 'string') {
                    try {
                        response = JSON.parse(response);
                    } catch (e) {
                        Console.warn('Invalid json,', response, ' received for content type', responseContentType);
                    }
                }
                if (statusCode >= 200 && statusCode < 300) {
                    if (params.success instanceof Function) {
                        params.success(response, request);
                    }
                } else if (params.error instanceof Function) {
                    params.error(statusCode, response, request);
                }

                if (params.completed instanceof Function) {
                    params.completed(statusCode, response, request);
                }
                if (!(params.completed || params.success || params.error)) {
                    Console.warn('ajax call without completion handlers', params);
                }
            };

            return nanoajax.ajax(params, onReady);
        };
    }
);

