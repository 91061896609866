
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Select/AutoComplete',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Select',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/UI/Selector',
    ],
    function (Select, HtmlElement, UISelector) {
        'use strict';

        // static definitions
        var __optionsClass = 'options',
            __optionSelectedClass = 'is-selected',
            __optionDisabledClass = 'is-disabled',
            __loadingClass = '-loading',
            __selectionsClass = 'selections';

        var AutoComplete = Select.extend({
            _optionsContainer: null,
            _selectionsContainer: null,
            _inputElement: null,
            _delay: null,

            _currentFilter: null,
            _uiSelector: null,
            _noResultsMessage: false,

            autocompleteFieldContainer: null,
            _loadingCount: 0,

            __construct: function () {
                this.__super();
                this.hideOptionsHandler = this.onPotentialBlur.bind(this);
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setDelayFromSetup(setup);
                this.setNoResultsMessageFromSetup(setup);
            },

            setDelayFromSetup: function (setup) {
                if (setup.delay) {
                    this.setDelay(setup.delay);
                } else {
                    this.setDelay(0);
                }
            },

            setNoResultsMessageFromSetup: function (setup) {
                if (setup.noResultsMessage) {
                    this.setNoResultsMessage(setup.noResultsMessage);
                }
            },

            onDomReady: function () {
                this.__super();
                var inputElement = new HtmlElement(this.getInputElement());
                inputElement.on('focus', this.onFocus.bind(this), true);
                var timeoutId = null;
                inputElement.on('keyup', function (e, options) {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                    timeoutId = setTimeout(this.onKeyUp.bind(this, e, options), this.getDelay());
                }.bind(this), true);
                inputElement.on('dblclick', this.onDblClick.bind(this), true);
            },

            onFocus: function (e, options) {
                // Ensure that the very first focus we load all results. This also ensures that the filter doesn't
                // change on following focus events.
                var newFilterValue = this._currentFilter !== null ? this._currentFilter : '';

                this.tryRefilterSuggestions(newFilterValue, options, function () {
                    this.showOptions();
                }.bind(this));
            },

            onPotentialBlur: function (e, options) {
                if (options.getTarget() === this.getInputElement()) {
                    return;
                }
                if (!this.getMultiple()) {
                    this.hideOptions();
                    return;
                }
                if(!this.getOptionsContainer().getElement().contains(options.getTarget())) {
                    this.hideOptions();
                    return;
                }
            },

            onDblClick: function (e) {
                if (this.getItems() && this.getItems().length) {
                    this.showOptions();
                }
            },

            getUiSelector: function () {
                if (!this._uiSelector) {
                    this._uiSelector = new UISelector(this.getOptionsContainer().getElement(), this.getInputElement());
                }
                return this._uiSelector;
            },

            /**
             * {@inheritdoc}
             */
            onReload: function(e, options){
                this.fetch(this.buildFetchDataForOptions(options), function () {
                    // Validate that items that are already selected are still valid after filtering. If the provider
                    // decides to limit the results further, options that no longer exist should no longer be
                    // selectable.
                    var i,
                        selections = this.getSelections();

                    for (i = 0; i < selections.length; ++i) {
                        var item = this.findItemForSelection(selections[i]);

                        if (item === null) {
                            this.removeSelection(selections[i]);
                        }
                    }
                });
            },

            /**
             * {@inheritdoc}
             */
            buildFetchData: function () {
                var data = this.__super();
                data.filter = this.getInputElement().value;

                return data;
            },

            /**
             * called when an actual character is pressed
             * @param e
             * @param options
             */
            onKeyUp: function (e, options) {
                this.tryRefilterSuggestions(options.getTarget().value, options, function () {
                    this.showOptions();
                }.bind(this));
            },

            tryRefilterSuggestions: function (filterValue, additionalOptions, whenDoneCallback) {
                if (filterValue === this._currentFilter) {
                    if (whenDoneCallback) {
                        whenDoneCallback();
                    }

                    return; // Same filter as before, skip.
                }

                this.refilterSuggestions(filterValue, additionalOptions, whenDoneCallback);
            },

            refilterSuggestions: function (filterValue, additionalOptions, whenDoneCallback) {
                this._currentFilter = filterValue;
                this.getInputElement().value = filterValue;

                this.hideOptions();

                this.fetch(this.buildFetchDataForOptions(additionalOptions), whenDoneCallback);
            },

            fetch: function (data, callback) {
                this.loading(true);
                var wrapper = function () {
                    if (callback) {
                        callback.call(this);
                    }
                    this.loading(false);
                }.bind(this);
                this.__super(data, wrapper);
            },

            /**
             *
             * @param state
             */
            loading: function (state) {
                var autocompleteFieldContainer = this.getAutocompleteFieldContainer();

                if (state) {
                    this._loadingCount++;
                    autocompleteFieldContainer.classList.add(__loadingClass);
                } else if ((--this._loadingCount) === 0) {
                    autocompleteFieldContainer.classList.remove(__loadingClass);
                }
            },

            /**
             * @return {HTMLElement}
             */
            getAutocompleteFieldContainer: function () {
                if (!this.autocompleteFieldContainer) {
                    this.autocompleteFieldContainer = this.getElement().querySelector('.autocomplete__field');
                }
                return this.autocompleteFieldContainer;
            },

            showOptions: function () {
                this.un('click', this.hideOptionsHandler, false);
                this.getOptionsContainer().show();
                this.on('click', this.hideOptionsHandler, false);
            },

            hideOptions: function () {
                this.un('click', this.hideOptionsHandler, false);
                this.getOptionsContainer().hide();
            },

            /**
             * clear input when record is loaded or saved
             * @param event
             * @param options
             */
            handleRecordLoaded: function (event, options) {
                this.__super(event, options);
                this.getInputElement().value = '';
            },

            /**
             * returns the container that holds selectable values
             * @return Inventis/Bundle/BricksBundle/ApplicationH/HTML/Element
             */
            getOptionsContainer: function () {
                if (!this._optionsContainer) {
                    this._optionsContainer = new HtmlElement(this.getElement().querySelector('.' + __optionsClass));
                }
                return this._optionsContainer;
            },

            /**
             * returns the container that hold the selected values
             * @return Inventis/Bundle/BricksBundle/ApplicationH/HTML/Element
             */
            getSelectionsContainer: function () {
                if (!this._selectionsContainer) {
                    this._selectionsContainer = new HtmlElement(this.getElement().querySelector('.' + __selectionsClass));
                }
                return this._selectionsContainer;
            },

            /**
             *
             * @param {Node} selection
             * @param {Boolean} [fireChanged]
             */
            addSelection: function (selection, fireChanged) {
                fireChanged = fireChanged === undefined ? true : fireChanged;
                if (!this.selectionExists(selection)) {
                    if (!this.getMultiple()) {
                        this.clearSelections();
                    }
                    this.getSelectionsContainer().getElement().appendChild(selection);
                    if (fireChanged) {
                        this.fireSelectionChange();
                    }
                }
            },

            clear: function (defaults) {
                this.getOptionsContainer().empty();
                this.clearSelections();
                this.tryRefilterSuggestions('', {});

                if (defaults && defaults[this.getName()] !== undefined) {
                    this.setValue(defaults[this.getName()]);
                } else {
                    this.setValue(null);
                }
            },

            clearSelections: function () {
                this.getSelectionsContainer().empty();
            },

            createSelection: function (value, text) {
                if (text === undefined) {
                    text = this.findValueText(value);
                }

                var selection = document.createElement('li'),
                    input = document.createElement('input'),
                    close = document.createElement('span');

                this.setDataValueAttribute(selection, value);

                selection.className = __selectionsClass;
                selection.innerHTML = text;

                // we add an input field for browsers that are not able to send the form through ajax
                input.type = 'hidden';
                input.name = this.getName();
                input.value = value;
                selection.appendChild(input);

                close.textContent = 'x';
                close.className = 'close-button';
                (new HtmlElement(close)).on('click', this.onSelectionCloseClick.bind(this, selection), true);
                selection.appendChild(close);

                return selection;
            },

            onSelectionCloseClick: function (selection, e, options) {
                if (!this.isFieldEnabled()) {
                    return;
                }

                this.removeSelection(selection);
                var option = this.getOptionsContainer().getElement()
                    .querySelector('[data-value="' + this.getDataValueAttribute(selection) + '"]');
                if (option) {
                    (new HtmlElement(option)).removeClass(__optionSelectedClass);
                }
            },

            /**
             * removed the provided selection node from the selection container
             * @param {Node} selection
             */
            removeSelection: function (selection) {
                this.getSelectionsContainer().getElement().removeChild(selection);
                this.fireSelectionChange();
            },

            /**
             * fired from different location, so central method for reuse seemed best
             */
            fireSelectionChange: function () {
                var options = {};

                this.attachChangeParametersToObject(options);

                options.fieldValue = this.getValue(false);

                this.fire('change', options);
            },

            /**
             * go through all items in search of a matching taxt for the provided value
             * @param value
             * @param text
             * @returns {*}
             */
            findValueText: function(value) {
                var item = this.findItemByValue(value);

                if (item) {
                    return item[this.getDisplayField()];
                }

                return value;
            },

            /**
             * Locates the loaded item (from the data provider) that the specified selection is selecting.
             *
             * @param {Object} selection
             *
             * @return {Object|null}
             */
            findItemForSelection: function (selection) {
                var selectionValue = this.getDataValueAttribute(selection);

                return this.findItemByValue(selectionValue);
            },

            /**
             * Locates the loaded item (from the data provider) that has the specified value.
             *
             * @param {*} value
             *
             * @return {Object|null}
             */
            findItemByValue: function (value) {
                var items = this.getItems(),
                    valueField = this.getValueField();

                for (var i = 0; i < items.length; ++i) {
                    if (items[i][valueField] == value) {
                        return items[i];
                    }
                }

                return null;
            },

            /**
             * returns the selections node matching the provided value
             * @param value
             * @return {Node}
             */
            getSelection: function (value) {
                return this.getSelectionsContainer().getElement().querySelector('[data-value="' + value + '"]');
            },

            /**
             * returns true if a node matching the value of the provided selection exists
             * @param {Node} selection
             * @return {Boolean}
             */
            selectionExists: function (selection) {
                return this.getSelection(this.getDataValueAttribute(selection)) !== null;
            },

            /**
             *
             * @return {NodeList|querySelectorAll|*}
             */
            getSelections: function () {
                return this.getSelectionsContainer().getElement().querySelectorAll('.' + __selectionsClass);
            },

            /**
             * mainly called when the form loaded its values
             * @param value
             * @return {*}
             */
            setValue: function (value) {
                if (value === null) {
                    this._value = null;
                    return;
                }
                if (!(value instanceof Array)) {
                    if (value === '') {
                        this._value = value = [];
                    } else {
                        this._value = value = [value];
                    }
                }

                this.reloadItems();

                var option;
                for (var i=0; i < value.length; i++) {
                    this.addSelection(this.createSelection(value[i]), false);
                    if ((option = this.getOption(value[i]))) {
                        option = new HtmlElement(option);
                        if(!option.classExists(__optionSelectedClass)) {
                            option.addClass(__optionSelectedClass);
                        }
                    }
                }

                return this;
            },

            /**
             * returns the values of the currently selected items
             * @return {Array}
             */
            getValue: function () {
                var i,
                    values = [],
                    selections = this.getSelections();

                for (i = 0; i < selections.length; i++) {
                    values.push(this.getDataValueAttribute(selections[i]));
                }

                if (this.getMultiple()) {
                    return values;
                }

                return values.length > 0 ? values.shift() : '';
            },

            disableField: function () {
                this.getInputElement().disabled = true;
            },

            enableField: function () {
                this.getInputElement().disabled = false;
            },

            isFieldEnabled: function () {
                return !this.getInputElement().disabled;
            },

            setDelay: function (delay) {
                this._delay = delay;
                return this;
            },

            getDelay: function () {
                return this._delay;
            },

            setNoResultsMessage: function (noResultsMessage) {
                this._noResultsMessage = noResultsMessage;
                return this;
            },

            getNoResultsMessage: function () {
                return this._noResultsMessage;
            },

            /**
             * reloads the items into a displayable container from which the user can make a selection
             */
            reloadItems: function () {
                var container = this.getOptionsContainer(),
                    items = this.getItems(),
                    i;
                container.empty();
                container.getElement().scrollTop = 0;
                if (!items || items.length === 0) {
                    var option = this.createOptionFromParameters(this.getNoResultsMessage(), -1);
                    option.classList.add(__optionDisabledClass);

                    this.addOption(option);
                    return;
                }
                for (i = 0; i < items.length; i++) {
                    this.addOption(this.createOption(items[i]));
                }
            },

            /**
             * adds an option to the list of selectable options attaching required listeners to it in the process
             * @param option
             */
            addOption: function (option) {
                var element = new HtmlElement(option);

                if (!option.classList.contains(__optionDisabledClass)) {
                    element.on('click', this.onOptionClick.bind(this), true);
                }
                if (this.getSelection(this.getDataValueAttribute(option)) !== null) {
                    element.addClass(__optionSelectedClass);
                }

                this.getOptionsContainer().getElement().appendChild(option);
            },

            /**
             * returns a list of option elements, optionally filtering the selected options only
             * @param {Boolean} [selectedOnly]
             * @return {NodeList|querySelectorAll|*}
             */
            getOptions: function (selectedOnly) {
                return this.getOptionsContainer().getElement().querySelectorAll(
                    '.' + __optionsClass + ' li' + (selectedOnly ? '.' + __optionSelectedClass : '')
                );
            },

            getOption: function (value) {
                return this.getOptionsContainer().getElement().querySelector(
                    '.' + __optionsClass + ' li[data-value="' + value + '"]'
                );
            },

            /**
             * called when an option is selected this method handles all selection actions
             * @param e
             * @param option
             */
            onOptionClick: function (e, options) {
                var selection,
                    i,
                    optionElements = this.getOptions(true),
                    targetElement = options.getTarget(),
                    target = new HtmlElement(targetElement),
                    isSelected = target.classExists(__optionSelectedClass);
                if (isSelected && (selection = this.getSelection(this.getDataValueAttribute(targetElement)))) {
                    target.removeClass(__optionSelectedClass);
                    this.removeSelection(selection);
                } else {
                    if (!this.getMultiple()) {
                        for (i = 0; i < optionElements.length; i++) {
                            if (optionElements[i] !== targetElement) {
                                (new HtmlElement(optionElements[i])).removeClass(__optionSelectedClass);
                            }
                        }
                    }
                    if (!isSelected) {
                        target.addClass(__optionSelectedClass);
                    }
                    this.addSelection(this.createSelection(
                        this.getDataValueAttribute(targetElement),
                        targetElement.textContent
                    ));

                    if (!this.getMultiple()) {
                        this.hideOptions();
                    }
                }
            },

            /**
             * creates an option from an item
             * @param item
             */
            createOption: function (item) {
                return this.createOptionFromParameters(
                    item[this.getDisplayField()],
                    item[this.getValueField()]
                );
            },

            /**
             * creates an option from an item
             * @param item
             */
            createOptionFromParameters: function (title, value) {
                var option = document.createElement('li');

                this.setDataValueAttribute(option, value);

                option.textContent = title;

                return option;
            },

            /**
             * returns a reference to the form that is relevant to the current field
             * as the element is not really a form element, we cannot use the default form return handler
             * @return {*}
             */
            getForm: function () {
                return this.getInputElement().form;
            },

            /**
             * @return {Node}
             */
            getInputElement: function () {
                if (!this._inputElement) {
                    this._inputElement = this.getElement().querySelector('input[name="' + this.getName() + '-search"]');
                }
                return this._inputElement;
            },

            /**
             * The element autocomplete is it's own wrapper
             * @return {null|*|HTMLElement|Object}
             */
            getComponentElement: function () {
                if (!this._componentElement) {
                    this._componentElement = new HtmlElement(this.getElement());
                }
                return this._componentElement;
            },

            /**
             * Retrieves the value of the data-value attribute.
             *
             * @param {Object} element
             *
             * @return {*|undefined}
             */
            getDataValueAttribute: function (element) {
                // Support IE 10 and lower (IE 11 doesn't require this anymore).
                return (element.dataset !== undefined) ? element.dataset.value : element.attributes['data-value'].value;
            },

            /**
             * Sets the value of the data-value attribute.
             *
             * @param {Object} element
             * @param {*}      value
             *
             * @return {this}
             */
            setDataValueAttribute: function (element, value) {
                if (element.dataset !== undefined) {
                    element.dataset.value = value;
                } else {
                    // IE 10 support, see above.
                    element.setAttribute('data-value', value);
                }

                return this;
            },
        });

        return AutoComplete;
    }
);

