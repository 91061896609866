define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/RemoteAction',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/Action',
        'Inventis/Bundle/BricksBundle/Promise',
        'Inventis/Bundle/BricksBundle/Ajax',
    ],
    function (Action, Promise, ajax) {
        'use strict';

        /**
         * @class ActionToolbarPlugin.RemoteAction
         * @extends ActionToolbarPlugin.Action
         */
        return Action.extend({
            processConfig: function (config) {
                if (config.url === undefined || !config.url) {
                    throw new Error('Invalid url configured for RemoteAction!');
                }
                return Object.assign(
                    this.__super(config),
                    {
                        url: new URL(config.url, window.location.origin),
                        successMessage: config.successMessage || 'action completed',
                        errorMessage: config.errorMessage || 'action failed',
                        requestMethod: config.requestMethod || 'GET',
                    }
                );
            },

            getUrl: function () {
                return this.config.url.toString();
            },

            getBody: function (selections) {
                return {ids: this.getIds(selections)};
            },

            getHeaders: function () {
                return {contentType: 'application/json'};
            },

            getMethod: function () {
                return this.config.requestMethod;
            },

            doExecute: function (selections) {
                return new Promise(function (resolve, reject) {
                    var params = {
                        url: this.getUrl(),
                        body: this.getBody(selections),
                        method: this.getMethod(),
                        header: this.getHeaders(),
                        success: this.onSuccess.bind(this, selections, resolve, reject),
                        error: this.onError.bind(this, selections, reject),
                    };

                    ajax(params);
                }.bind(this));
            },

            onSuccess: function (selections, resolve, reject, response) {
                if (!response.success) {
                    reject(new Error(response.message || this.config.errorMessage));
                } else {
                    this.updateGrid(this.getGrid(), selections).then(function () {
                        resolve(this.config.successMessage);
                    }.bind(this));
                }
            },

            updateGrid: function (grid/* , selections*/) {
                return grid.reload();
            },

            onError: function (selections, reject, statusCode/* , response*/) {
                reject(new Error(this.config.errorMessage + ' [code: ' + statusCode + ']'));
            },
        });
    }
);

