
define(
    'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',[
        'Inventis/Bundle/BricksBundle/Mixin',
        'Inventis/Bundle/BricksBundle/Application',
    ],
    function (mixin, App) {
        'use strict';

        if (!App.browser.support.XMLHttpRequestLevel2) {
            throw new Error(
                'you can only use binaryData with XMLHttpRequest Level2 compatible browsers'
            );
        }

        return mixin({
            /**
             * @var {Object}
             */
            _api: null,

            /**
             * handles setting the API from a setup provided
             * @param {Object} setup
             */
            setApiFromConfig: function (setup) {
                if (typeof setup.api === 'object') {
                    this.setApi(setup.api);
                }
            },

            /**
             * set the API
             * @param {Object} api
             */
            setApi: function (api) {
                if (typeof api !== 'object') {
                    throw new TypeError('Api must be an instance of an object');
                }
                this._api = api;
            },

            /**
             * get the API set
             * @return {Object}
             */
            getApi: function () {
                if (!this._api) {
                    this._api = {};
                }
                return this._api;
            },

            /**
             * this is the default API request handler
             * which will form a default request object for you
             * that matches the provided action and will ensure the data gets passed
             * as part of the request, callback will only be called when the ajax request completed
             * (e.g. a valid response was returns)
             * @param {String} action
             * @param {Object} data
             * @param {callback} callback
             * @param {Object} [binaryData] optional binary data to add to the request
             * @param {callback|null} errorCallback
             */
            apiRequest: function (action, data, callback, binaryData, errorCallback) {
                binaryData = binaryData || [];
                var i,
                    serverData = this.buildRequestData(action, data),
                    request,
                    name;

                request = new FormData();
                for (name in serverData) {
                    if (!Array.isArray(serverData[name])) {
                        request.append(name, serverData[name] !== null ? serverData[name] : '');
                    } else {
                        for (i = 0; i < serverData[name].length; i++) {
                            request.append(
                                name,
                                serverData[name][i] !== null ? serverData[name][i] : ''
                            );
                        }
                    }
                }
                for (name in binaryData) {
                    for (i = 0; i < binaryData[name].length; i++) {
                        request.append(name, binaryData[name][i]);
                    }
                }
                serverData = request;
                App.ajax(this.getActionUrl(action), serverData, callback, this, undefined, null, null, errorCallback);
            },

            getComponentRequestData: function (action) {
                if (!action) {
                    throw new Error('Failed to build componentRequestData, no action specified');
                }
                var api = this.getApi();
                if (!api[action]) {
                    throw new Error('Failed to build componentRequestData, unknown action ' + action + ' specified');
                }
                return {
                    componentId: this.getId(),
                    componentAction: action,
                };
            },

            /**
             * builds API compliant request params that should be used to send to the server
             * @param {String} action
             * @param {Object} [data={}]
             * @return {{request: *}}
             */
            buildRequestData: function (action, data) {
                data = data || {};
                if (data.componentAction !== undefined) {
                    throw new Error(
                        'Your request holds and action property, this property is reserved for the component request'
                    );
                }
                if (data.componentId !== undefined) {
                    throw new Error(
                        'Your request holds and componentId property,'
                        + ' this property is reserved for the component request'
                    );
                }
                return App.merge(data, this.getComponentRequestData(action), true);
            },

            /**
             * returns the full url for use in your api call
             * @param {String} action
             * @return {*}
             */
            getActionUrl: function (action) {
                var api = this.getApi();
                if (api[action] === undefined) {
                    throw new TypeError(
                        'Action [' + action + '] is not a valid api request, either correct the action or ' +
                        'make sure that the api knows what to do with that action by defining a route for it ' +
                        'in the component config'
                    );
                }
                if (api[action].indexOf('/') === 0) {
                    return api[action];
                }
                return (api.baseUrl || this._getLocationBaseUrl()) + '/' + api[action];
            },

            /**
             * returns the current locations base url
             * @return {String}
             * @private
             */
            _getLocationBaseUrl: function () {
                var l = window.location;
                return l.protocol + '//' + l.hostname + ([80, 443].indexOf(l.port) === -1 ? ':' + l.port : '') +
                    l.pathname;
            },

        });
    }
);

