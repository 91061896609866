
define(
    'Inventis/Bundle/BricksBundle/Brick/Grid',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
        'Inventis/Bundle/BricksBundle/Promise',

        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Brick, ApiAwareMixin, Promise, Console) {
        'use strict';

        /**
         * @class GridBrick
         * @extends Brick
         */
        var Grid = Brick.extend({
            use: [ApiAwareMixin],

            observablePromiseEnabled: true,
            /**
             * object of AMD loaded renderers
             */
            gridRenderers: null,
            /**
             * object of AMD loaded handlers
             */
            gridHandlers: null,
            /**
             * object of AMD loaded listeners
             */
            gridListeners: null,

            /**
             * object of AMD loaded listeners
             */
            gridModelProcessors: null,
            /**
             * to ensure our grid only renders when certain dependencies are finished loading
             * every require that has dependencies that the grid relies on, should ass it to this list
             * so that we can hold the rendering until dependency loading is finished
             * @type {Array}
             */
            gridLoadedDependencies: null,

            /**
             * @type {RenderStrategyConfig}
             */
            renderStrategy: null,

            /**
             * @type {String}
             */
            deleteRecordEvent: null,

            __construct: function () {
                this.__super(arguments);
                // init all none static properties
                this.gridRenderers = {};
                this.gridHandlers = {};
                this.gridListeners = {};
                this.gridModelProcessors = {};
                this.gridLoadedDependencies = [];
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setApiFromConfig(setup);
                this.setDeleteRecordEventFromConfig(setup);
                this.setGridCallbacksFromConfig(setup);
                try {
                    // must happen after callbacks as it depends on its registration of callbacks
                    this.setRenderStrategyFromConfig(setup);
                } catch (e) {
                    Console.error('Invalid renderStrategy: ', setup.renderStrategy, e.message);
                    throw e;
                }
            },

            setRenderStrategyFromConfig: function (setup) {
                if (!setup.renderStrategy) {
                    throw new Error('missing renderStrategy');
                }
                if (!setup.renderStrategy.module) {
                    throw new Error('module invalid');
                }

                this.renderStrategy = setup.renderStrategy;
                // preload the renderer already to save time
                require([this.renderStrategy.module], function (Render) {
                    this.createRendererInstance(Render);
                }.bind(this));
            },

            /**
             * Attach all listeners
             */
            attachListeners: function () {
                this.__super();

                this.on('redraw', this.onRedrawRequest, true);

                this.on('addFilters', this.onAddFiltersRequest, true);
                this.on('setFilters', this.onSetFiltersRequest, true);
                this.on('clearFilters', this.onClearFiltersRequest, true);

                this.on('refresh', this.onRefreshRequest, true);
            },

            onAddFiltersRequest: function (event, options) {
                this.whenRendererReady().then(function (renderer) {
                    renderer.addFilters(options.filters);
                });
            },

            onSetFiltersRequest: function (event, options) {
                this.whenRendererReady().then(function (renderer) {
                    renderer.setFilters(options.filters);
                });
            },

            onClearFiltersRequest: function (event, options) {
                this.whenRendererReady().then(function (renderer) {
                    renderer.clearFilters(options.filters || null);
                });
            },

            onRedrawRequest: function () {
                this.whenRendererReady().then(function (renderer) {
                    renderer.redraw().catch(Console.error);
                });
            },

            onDomReady: function () {
                this.__super();
                this.attachEventHandlers();
            },

            onRefreshRequest: function () {
                this.whenRendererReady().then(function (renderer) {
                    renderer.refresh();
                });
            },

            /**
             * this method attaches event handlers that are NOT part of the framework
             * dependency (e.g ExtJS) as these event can be handled uniformly across
             * dependencies
             */
            attachEventHandlers: function () {
                this.on('addRecord', this.onAddRecordRequest, true);
                this.on('moveRecord', this.onMoveRecordRequest, true);
                this.on(this.deleteRecordEvent, this.onDeleteRecordRequest, true);
            },

            collectIdPropertyValues: function (options) {
                if (options.id) {
                    return {id: options.id};
                }
                if (options.ids) {
                    return {id: options.ids};
                }
                return this.getRendererInstance().getNodeIdentity(options.getTarget());
            },

            transformRequestDataRecordId: function (data) {
                if (Array.isArray(data.id)) {
                    data['id[]'] = data.id;
                    delete data.id;
                }

                return data;
            },

            onDeleteRecordRequest: function (e, options) {
                this.mask();
                try {
                    var data = this.collectIdPropertyValues(options);
                    var requestData = this.transformRequestDataRecordId(Object.assign({}, data));

                    this.apiRequest('deleteRecords', requestData, function (response) {
                        if (response.success) {
                            this.getRendererInstance().onRecordDeleted(data, response).then(function (records) {
                                this.unmask();
                                this.fire('recordsDeleted', {records: records});
                            }.bind(this)).catch(Console.error);
                        } else {
                            this.fire(
                                'deleteRecordsFailed',
                                {error: response.error ? response.error : response.message}
                            );
                            this.unmask();
                        }
                    }.bind(this));
                } catch (error) {
                    this.fire('deleteRecordsFailed', {error: e.message});
                }
            },

            /**
             * handler for the addRecordWithoutRefresh event
             * @param {Event} e
             * @param {Object} options
             */
            onAddRecordRequest: function (e, options) {
                if (options.data === undefined) {
                    return;
                }

                this.mask();
                // only fire server side call if not specifically requested not to
                if (options.commit !== false) {
                    var data = Object.assign({}, options.data, this.collectIdPropertyValues(options)),
                        i;
                    if (options.fields !== undefined && options.fields.length) {
                        data = {};
                        for (i in options.fields) {
                            if (options.fields.hasOwnProperty(i)) {
                                data[options.fields[i]] = options.data[options.fields[i]];
                            }
                        }
                    }
                    this.apiRequest('addRecord', data, function (response) {
                        if (response.success) {
                            this.getRendererInstance().onRecordAdded(data, response).then(function (record) {
                                this.fire('recordAdded', {record: record});
                                this.unmask();
                            }.bind(this));
                        } else {
                            this.fire(
                                'addRecordFailed',
                                {error: response.error ? response.error : response.message}
                            );
                            this.getRendererInstance().redraw().catch(Console.error).then(function () {
                                this.unmask();
                            }.bind(this));
                        }
                    }.bind(this));
                } else {
                    this.getRendererInstance().addRecord(options).then(function (record) {
                        this.fire('recordAdded', {record: record});
                        this.unmask();
                    }.bind(this));
                }
            },

            /**
             * this handler is called when the grid has defined that a record
             * needs to be moved from one location to another
             * @param {Event} e
             * @param {Object} options
             */
            onMoveRecordRequest: function (e, options) {
                this.mask();
                var data = {
                    id: options.id,
                    target: options.target,
                    position: options.position,
                };
                this.apiRequest('moveRecord', data, function (response) {
                    if (response.success) {
                        this.getRendererInstance().onRecordMoved(options, response).then(function (record) {
                            this.unmask();
                            this.fire('recordMoved', {record: response.result || record});
                        }.bind(this));
                    } else {
                        this.fire(
                            'moveRecordFailed',
                            {error: response.error ? response.error : response.message}
                        );
                        this.getRendererInstance().refresh().catch(function (e) {
                            Console.error(e);
                            this.unmask();
                        }.bind(this)).then(this.unmask.bind(this));
                    }
                }.bind(this));
            },

            /**
             * @param {Object} setup
             */
            setDeleteRecordEventFromConfig: function (setup) {
                if (setup.deleteRecordEvent) {
                    this.deleteRecordEvent = setup.deleteRecordEvent;
                }
            },

            /**
             * @param {Object} setup
             */
            setGridCallbacksFromConfig: function (setup) {
                if (setup.callbacks) {
                    var callbacks = setup.callbacks;
                    if (callbacks.renderers && callbacks.renderers.length) {
                        this.setGridRenderers(callbacks.renderers);
                    }
                    if (callbacks.handlers && callbacks.handlers.length) {
                        this.setGridHandlers(callbacks.handlers);
                    }
                    if (callbacks.listeners && callbacks.listeners.length) {
                        this.setGridListeners(callbacks.listeners);
                    }
                    if (callbacks.modelProcessors && typeof callbacks.modelProcessors.length) {
                        this.setGridModelProcessors(callbacks.modelProcessors);
                    }
                }
            },

            callbackLoader: function (dependencies, addCallback) {
                // push all dependencies on the stack that will be testen before grid rendering
                this.gridLoadedDependencies = this.gridLoadedDependencies.concat(dependencies);
                require(dependencies, function () {
                    for (var i = 0; i < arguments.length; i++) {
                        if (typeof arguments[i] === 'function') {
                            addCallback.call(this, i, arguments[i].bind(this));
                        } else {
                            for (var name in arguments[i]) {
                                if (arguments[i].hasOwnProperty(name)) {
                                    addCallback.call(this, name, arguments[i][name].bind(this));
                                }
                            }
                        }
                    }
                }.bind(this));
            },

            setGridRenderers: function (renderers) {
                this.callbackLoader(renderers, this.setGridRenderer);
                return this;
            },

            /**
             * returns a renderer callable if defined
             * @param {String} name
             * @return {Function|null}
             */
            getGridRenderer: function (name) {
                return this.gridRenderers[name];
            },

            /**
             * adds a single renderer
             * @param {String} name
             * @param {Function} renderer
             * @return {GridBrick}
             */
            setGridRenderer: function (name, renderer) {
                this.gridRenderers[name] = renderer;
                return this;
            },

            /**
             * returns all defines renderer callables
             * @return {*}
             */
            getGridRenderers: function () {
                return this.gridRenderers;
            },


            /**
             * loads handler dependencies through AMD loading
             * @param {Array} handlers a string array of handler dependencies (files)
             * @return {*}
             */
            setGridHandlers: function (handlers) {
                this.callbackLoader(handlers, this.setGridHandler);
                return this;
            },

            /**
             * loads handler dependencies through AMD loading
             * @param {Array} listeners a string array of listener dependencies (files)
             * @return {*}
             */
            setGridListeners: function (listeners) {
                this.callbackLoader(listeners, this.setGridListener);
                return this;
            },

            /**
             * returns listeners defined for the grid
             * @return {*}
             */
            getGridListeners: function () {
                return this.gridListeners;
            },

            /**
             * adds a single handler to the handlers list
             * @param name
             * @param handler
             * @return {*}
             */
            setGridHandler: function (name, handler) {
                this.gridHandlers[name] = handler;
                return this;
            },

            /**
             * adds a single listener to the listener list
             * @param name
             * @param listener
             * @return {*}
             */
            setGridListener: function (name, listener) {
                this.gridListeners[name] = listener;
                return this;
            },

            /**
             * return currently set handlers
             * @return {*}
             */
            getGridHandlers: function () {
                return this.gridHandlers;
            },


            /**
             * loads handler dependencies through AMD loading
             * @param {String} modelProcessors a string array of listener dependencies (files)
             * @return {*}
             */
            setGridModelProcessors: function (modelProcessors) {
                this.callbackLoader(modelProcessors, this.setGridModelProcessor);
                return this;
            },


            /**
             * adds a single listener to the listener list
             * @param name
             * @param listener
             * @return {*}
             */
            setGridModelProcessor: function (name, listener) {
                this.gridModelProcessors[name] = listener;
                return this;
            },

            /**
             * @return {Function}
             */
            createModelProcessorChain: function () {
                var entriesMap = new Map(Object.entries(this.gridModelProcessors));
                return function (model) {
                    entriesMap.forEach(function (processor) {
                        var response = processor(model);
                        if (response !== undefined) {
                            throw new Error('A grid model processor return a model object, only adjustments by reference are supported!');
                        }
                    });
                };
            },

            /**
             * render the grid when rendering is requested
             */
            render: function () {
                this.__super();
                require(this.gridLoadedDependencies, function () {
                    var onRejected = function (error) {
                        var message = 'failed to render grid ' + this.getId() + ':' + error;
                        Console.error(message);
                        throw new Error(message);
                    }.bind(this);
                    this.whenRendererReady().then(function (renderer) {
                        renderer.render().then(function () {
                            this.fire('rendered');
                        }.bind(this)).catch(onRejected);
                    }.bind(this)).catch(onRejected);
                }.bind(this));
            },

            /**
             * @param {RenderStrategy} [Renderer]
             * @return {Promise}
             */
            createRendererInstance: function (Renderer) {
                return new Promise(function (resolve, reject) {
                    try {
                        if (!(this.gridInstance instanceof Renderer)) {
                            require(this.gridLoadedDependencies, function () {
                                var renderStrategyConfig = Object.assign({}, this.renderStrategy);
                                renderStrategyConfig.renderers = this.getGridRenderers();
                                renderStrategyConfig.modelProcessor = this.createModelProcessorChain();
                                this.gridInstance = new Renderer(this, renderStrategyConfig);
                                this.fire('ready');
                                resolve(this.gridInstance);
                            }.bind(this), function (error) {
                                var modules = error.requireModules ? error.requireModules : [];
                                reject(new Error('failed to load modules ' + modules.join(', ')));
                            });
                        } else {
                            resolve(this.gridInstance);
                        }
                    } catch (error) {
                        reject(error);
                    }
                }.bind(this));
            },

            /**
             * @return {Promise}
             */
            whenRendererReady: function () {
                return new Promise(function (resolve, reject) {
                    try {
                        if (this.isRendererReady()) {
                            resolve(this.getRendererInstance());
                        } else {
                            require([this.renderStrategy.module], function (Renderer) {
                                this.createRendererInstance(Renderer).then(resolve);
                            }.bind(this), function (error) {
                                var modules = error.requireModules ? error.requireModules : [];
                                reject(new Error('failed to load modules ' + modules.join(', ')));
                            });
                        }
                    } catch (error) {
                        reject(error);
                    }
                }.bind(this));
            },

            /**
             * @return {Boolean}
             */
            isRendererReady: function () {
                return !!this.gridInstance;
            },

            /**
             * @return {RenderStrategy}
             */
            getRendererInstance: function () {
                if (!this.isRendererReady()) {
                    throw new Error(this.getId() + ': Error no instance of a renderer defined!');
                }
                return this.gridInstance;
            },
        });
        return Grid;
    }
);

