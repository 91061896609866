/* eslint-disable multiline-comment-style */

define('Inventis/Bundle/BricksBundle/Config',[
    'module', // requireJS internal module that holds configuration
],
function (module) {
    'use strict';

    var locale = module.config().locale;
    locale = locale ? locale : null;

    return {
        // //module specific config
        // componentConfig: module.config().componentConfig,
        // moduleConfig: module.config().moduleConfig,
        // moduleName: module.config().moduleName,
        // translations: module.config().translations,
        app: {debug: module.config().enableBrickDebugging},
        locale: locale,
        ietfLocale: locale !== null ? locale.replace('_', '-') : null,
        // application specific config settings, for BC
        // siteName: module.config().applicationConfig.siteName,
        // siteUrl: module.config().applicationConfig.siteUrl,
        // baseUrl: module.config().applicationConfig.baseUrl,
        // iconUrl: module.config().applicationConfig.iconUrl,
        // statsUrl: module.config().applicationConfig.statsUrl,
        // adminUrl: module.config().applicationConfig.adminUrl,
        // requireLanguage: module.config().applicationConfig.requireLanguage,
        // defaultLanguage: module.config().applicationConfig.defaultLanguage,
        // currentLanguage: module.config().applicationConfig.lang,
        // __SID__: module.config().applicationConfig.__SID__,
        // __USERID__: module.config().applicationConfig.__USERID__,
        // languagesCount: module.config().applicationConfig.languagesCount,
        // languages: module.config().applicationConfig.languages
    };
});

