
define(
    'Inventis/Bundle/BricksBundle/Brick/Brick/EventRoutingMixin',[
        'Inventis/Bundle/BricksBundle/Mixin',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/Application',
    ],
    function (Mixin, Element, App) {
        'use strict';

        return Mixin({
            abstract: ['getListeners'],

            /**
             * @var {Object}
             */
            _eventRouting: null,

            /**
             * handles setting the API from a setup provided
             * @param setup
             */
            setEventRoutingFromConfig: function (setup) {
                if (setup.eventRouting !== undefined &&
                    typeof setup.eventRouting === 'object' &&
                    setup.eventRouting.length !== 0) {
                    this.setEventRouting(setup.eventRouting);
                }
            },

            /**
             * set the eventRouting object
             * @param {Object} eventRouting
             */
            setEventRouting: function (eventRouting) {
                if (typeof eventRouting !== 'object') {
                    throw new TypeError('Event routing must be an instance of an object');
                }
                this._eventRouting = eventRouting;
            },

            /**
             * get the eventRouting object
             * @return {Object}|null
             */
            getEventRouting: function () {
                return this._eventRouting;
            },

            /**
             * Initialize the routing of the events received by the config
             */
            initEventRouting: function () {
                var eventRouting = this.getEventRouting();
                for (var x in eventRouting) {
                    if (eventRouting.hasOwnProperty(x)) {
                        var eventRoute = eventRouting[x];
                        this.addEventRoute(eventRoute);
                    }
                }
            },

            /**
             * Translates an eventRoute object to an actual mechanism (this.on(event, callback)) to route the
             * events that are specified to the right object
             * @param {Object} eventRoute an eventRoute object that contains events, destinations and an absorb flag
             */
            addEventRoute: function (eventRoute) {
                var events = eventRoute.events,
                    destinations = eventRoute.destinations,
                    useListeners = eventRoute.useListeners,
                    absorb = eventRoute.absorb,
                    bubbles = eventRoute.bubbles,
                    sources = eventRoute.sources || null,
                    data = eventRoute.data || null;
                for (var listenEvent in events) {
                    if (events.hasOwnProperty(listenEvent)) {
                        /*
                         * for every event, create an listener that listens on listenEvent
                         */
                        this.on(
                            listenEvent,
                            this.route(sources, destinations, absorb, bubbles, useListeners, events[listenEvent], data),
                            true
                        );
                    }
                }
            },

            /**
             * The actual routing mechanism that fires an event to a destination and chooses to cancel the bubbling of
             * the event
             * @param {Array<String>|null} sources the ids of the elements that initiated the event
             * @param {Array<String>} destinations the ids of the elements that must receive the fireEvent(s)
             * @param {Boolean} absorb whether or not to cancel the bubbling of the event
             * @param {Boolean} bubbles whether or not to fire new events as bubbling
             * @param {Boolean} useListeners whether or not to invoke a listener instead of firing an event
             * @param {String|Array<String>} fireEvent the event to be fired on the destinations
             * @param {Object} data additional data to be passed to the event handlers or listeners
             * @return {Function} the callback that has to be executed when a certain event must be routed
             */
            route: function (sources, destinations, absorb, bubbles, useListeners, fireEvent, data) {
                /**
                 * @param {Event} event
                 * @param {Object} options
                 * @return {Boolean}
                 */
                var isSourceMatch = function (event, options) {
                    if (sources === null || sources.length === 0) {
                        return true;
                    }
                    return sources.indexOf(options.getTarget().id) !== -1;
                };
                if (fireEvent === false) {
                    /*
                     * All we are interested in is the absorb as the event is routed to false. This is normally only
                     * used together with absorb.
                     */
                    return function (event, options) {
                        if (!isSourceMatch(event, options)) {
                            return true;
                        }
                        return !absorb;
                    };
                } else if (typeof fireEvent !== 'object') {
                    fireEvent = [fireEvent];
                }
                return function (event, options) {
                    if (!isSourceMatch(event, options)) {
                        return true;
                    }
                    var listeners = this.getListeners();
                    // for every destination (id of the element to be fired on)
                    for (var x in destinations) {
                        if (destinations.hasOwnProperty(x) && destinations[x] !== false) {
                            // Search element that has the current destination id
                            var destinationElement = document.getElementById(destinations[x]);
                            if (destinationElement === undefined || destinationElement === null) {
                                throw new Error('Element with id ' + destinations[x] + ' isn\'t found');
                            }
                            destinationElement = new Element(destinationElement);

                            for (var i in fireEvent) {
                                if (fireEvent.hasOwnProperty(i)) {
                                    if (useListeners) {
                                        if (!listeners.hasOwnProperty(fireEvent[i])) {
                                            throw new Error(
                                                'Custom event listener ' + fireEvent[i] + ' could not be found!'
                                            );
                                        }
                                        listeners[fireEvent[i]].call(this, event, options, destinationElement, data);
                                    } else {
                                        if (data !== null) {
                                            throw new Error(
                                                'You can only attach custom data when using custom listeners'
                                            );
                                        }

                                        // fire the new event fireEvent with the same objects on the found element
                                        destinationElement.fire(fireEvent[i], options, bubbles);
                                    }
                                }
                            }
                        }
                    }
                    if (useListeners && (!destinations || destinations.length === 0)) {
                        for (var j in fireEvent) {
                            if (fireEvent.hasOwnProperty(j)) {
                                if (!listeners.hasOwnProperty(fireEvent[j])) {
                                    throw new Error(
                                        'Custom event listener ' + fireEvent[j] + ' could not be found!'
                                    );
                                }
                                listeners[fireEvent[j]].call(this, event, options, null, data);
                            }
                        }
                    }
                    /*
                     * When absorb is true (the event must prevent the event from bubbling further),
                     * false is returned, preventing the event from bubbling to the ancestors of the eventRouter
                     */
                    return !absorb;
                }.bind(this);
            },
        });
    }
);

