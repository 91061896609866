
define(
    'Inventis/Bundle/BricksBundle/Mixins/DataToRemember',[
        'Inventis/Bundle/BricksBundle/Mixin',
    ],
    function (Mixin, Element) {
        var DataToRemember = Mixin({
            abstract: ['on'],

            /**
             * @var {Array}
             */
            _dataToRemember: null,

            /**
             * @var {Array}
             */
            _extraData: null,

            /**
             *
             * @param setup
             */
            setDataToRememberFromConfig: function (setup) {
                if (setup.dataToRemember) {
                    this._extraData = {};
                    this.setDataToRemember(setup.dataToRemember);
                }
            },

            setDataToRemember: function (dataToRemember) {
                this._dataToRemember = dataToRemember;
                return this;
            },

            getDataToRemember: function () {
                return this._dataToRemember;
            },

            onExtraData: function (e, options) {
                var record = options.record,
                    dataToRemember = this.getDataToRemember();

                for (var x in dataToRemember) {
                    if (dataToRemember.hasOwnProperty(x) &&
                        ((record && record[x] !== undefined) || options[x] !== undefined)) {
                        this._extraData[dataToRemember[x]] =
                            !record || record[x] === undefined ? options[x] : record[x];
                    }
                }
            },

            /**
             * Attaches listeners required for supporting data to remember.
             */
            attachDataToRememberListeners: function () {
                this.on('extraData', this.onExtraData.bind(this), true);
            },

            getExtraData: function () {
                return this._extraData;
            },
        });
        return DataToRemember;
    }
);

