
define(
    'Inventis/Bundle/BricksBundle/Brick/TabPanel/SelectTabPanel',[
        'Inventis/Bundle/BricksBundle/Brick/TabPanel',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (TabPanel, Element, $) {
        'use strict';

        var SelectTabPanel = TabPanel.extend({
            _selectId: null,
            _select: null,

            __construct: function () {
                this.__super(arguments);
            },

            hasNoError: function () {
                return function () {};
            },

            hasError: function () {
                return function () {};
            },

            onDomReady: function () {
                this.__super();
                this._select = new Element($('#' + this._selectId).shift());
                this._attachSelectEventHandling();
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setSelectFromSetup(setup);
            },

            setSelectFromSetup: function (setup) {
                if (setup.dependencies === undefined || setup.dependencies.select === undefined) {
                    return;
                }
                this._selectId = setup.dependencies.select;
            },

            onBeforeFormSave: function (event, options) {
                var select = this._select.getElement();
                if (select.form === options.getTarget()) {
                    options.fields[select.name] = select.value;
                }
            },

            _attachSelectEventHandling: function () {
                this.on('beforeFormSave', this.onBeforeFormSave);
                this._select.on(
                    'change',
                    function (event) {
                        this.showPanel(this._select.getElement().value);
                    }.bind(this)
                );
            },
        });
        return SelectTabPanel;
    }
);

