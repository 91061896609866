
/**
 * this component can handle UI selections and highlights for a provided Html element
 * current UI highlights supported are keyboard up,down keys
 * current UI selections supported are keyboard space, enter keys and mouse shift-selections
 */
define(
    'Inventis/Bundle/BricksBundle/HTML/UI/Selector',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (Class, HtmlElement) {
        'use strict';

        var __cursorClass = 'is-cursor',
            __selectedClass = 'is-selected';

        var Selector = Class.extend({
            keyCodes: {
                UP: 38,
                DOWN: 40,
                RETURN: 13,
                SPACE: 32,
                ESCAPE: 27,
                SHIFT: 16,
            },
            _element: null,
            _listener: null,

            /**
             * the Node that currently holds the pointer
             * @var {HtmlElement}
             */
            _cursor: null,

            /**
             * @var {Array}
             */
            _selections: null,
            _lastDirection: null,
            _lastShiftClick: null,

            /**
             *
             * @param {Node} element
             * @param {Node} [listener] if not provided the element will become the listener
             * @private
             */
            __construct: function (element, listener) {
                this._selections = [];
                listener = listener || element;
                this._element = new HtmlElement(element);
                this._listener = new HtmlElement(listener);
                this._attachListeners();
            },

            _attachListeners: function () {
                this._listener.on('keydown', this.onKeyDown.bind(this), true, true);
                this._element.on('click', this.onElementClick.bind(this), true, true);
            },

            onElementClick: function (e, options) {
                if (!options.selecting) {
                    if (e.shiftKey) {
                        this.clickShiftKeySelect(options.getTarget());
                    } else {
                        this.setCursor(options.getTarget());
                        this._lastShiftClick = null;
                    }
                }
                this._listener.getElement().focus();
            },

            clickShiftKeySelect: function (target) {
                var indexOf = Array.prototype.indexOf, // childNodes is a nodeList object, which has no indexOf, so steal it
                    children = this._element.getElement().childNodes,
                    targetIndex = indexOf.call(children, target),
                    currentIndex = indexOf.call(children, this.getCursor().getElement()),
                    startIndex = Math.min(targetIndex, currentIndex),
                    endIndex = Math.max(targetIndex, currentIndex);

                while (startIndex < endIndex) {
                    this.selectCursor(new HtmlElement(children[startIndex]), false);
                    startIndex++;
                }
                // un-select previous shift-click selection within same shift-scope
                if (this._lastShiftClick && endIndex < this._lastShiftClick) {
                    startIndex = endIndex;
                    while (startIndex <= this._lastShiftClick) {
                        this.selectCursor(new HtmlElement(children[startIndex]), true);
                        startIndex++;
                    }
                }
                this._lastShiftClick = endIndex;
            },

            onKeyDown: function (e, options) {
                var keyCode = options.getKeyCode(),
                    c = this.keyCodes;

                switch (keyCode) {
                case c.UP: return this.onKeyCodeUp(e, options);
                case c.DOWN: return this.onKeyCodeDown(e, options);
                case c.RETURN: return this.onKeyCodeReturn(e, options);
                case c.ESCAPE: return this.onKeyCodeEscape(e, options);
                }

                if (keyCode !== c.SHIFT && !this._element.isHidden()) {
                    this._listener.getElement().focus();
                    this.reset();
                }
            },

            onKeyCodeUp: function (e, options) {
                var current = this._cursor;

                if (this._element.isHidden()) {
                    this._element.show();
                } else if (current) {
                    this.setCursor(this.previousNode());
                    if (e.shiftKey) {
                        this.upDownShiftKeySelect(current, options.getKeyCode());
                    }
                } else {
                    this.focusFirstItem();
                }

                e.preventDefault();
            },

            onKeyCodeDown: function (e, options) {
                var current = this._cursor;

                if (this._element.isHidden()) {
                    this._element.show();
                } else if (current) {
                    this.setCursor(this.nextNode());
                    if (e.shiftKey) {
                        this.upDownShiftKeySelect(current, options.getKeyCode());
                    }
                } else {
                    this.focusFirstItem();
                }

                e.preventDefault();
            },

            scrollSelectionIntoView: function () {
                var container = this._element.getElement(),
                    containerRect = container.getBoundingClientRect(),
                    selection = this._cursor.getElement(),
                    selectionRect = selection.getBoundingClientRect();

                if (selection.offsetTop + selectionRect.height > containerRect.height + container.scrollTop) {
                    // When selection is below current scrollbox.
                    container.scrollTop = Math.max(
                        selection.offsetTop +
                        selectionRect.height -
                        containerRect.height,
                        0
                    );
                } else if (selection.offsetTop < container.scrollTop) {
                    // When selection is above current scrollbox.
                    container.scrollTop = Math.max(
                        selection.offsetTop,
                        0
                    );
                }
            },

            /**
             * method handles cursor selection when the shift key is pressed
             * @param current
             * @param direction
             */
            upDownShiftKeySelect: function (current, direction) {
                if (current &&
                    (!this.cursorIsSelected(current) ||
                        (this._lastDirection !== direction &&
                            this._selections[this._selections.length - 1].getElement() === current.getElement()))
                ) {
                    if (this.selectCursor(current)) {
                        this._selections.push(current);
                        this._lastDirection = direction;
                    } else {
                        this._selections.pop();
                        if (this._selections.length === 1) {
                            this._lastDirection = direction;
                        }
                    }
                }
                if (this.selectCursor(this.getCursor(), false)) {
                    this._selections.push(this.getCursor());
                }
            },

            onKeyCodeReturn: function (e, options) {
                if (this._cursor) {
                    this.selectCursor(this.getCursor());
                    e.preventDefault();
                }
            },

            onKeyCodeEscape: function (e, options) {
                if (this._element.isHidden()) {
                    return true;
                }

                this._element.hide();
                this.reset(false);
                e.preventDefault();
                return false;
            },

            setCursor: function (node) {
                if (this._cursor) {
                    this._cursor.removeClass(__cursorClass);
                }
                if (node) {
                    this._cursor = new HtmlElement(node);
                    this._cursor.addClass(__cursorClass);
                    this.scrollSelectionIntoView();
                }
                return this;
            },

            getCursor: function () {
                if (!this._cursor) {
                    this.focusFirstItem();
                }
                return this._cursor;
            },

            focusFirstItem: function () {
                if (this._element.getElement().firstChild) {
                    this.setCursor(this._element.getElement().firstChild);
                }
            },

            /**
             * selected/deselects a cursor and returns the state of the selection, true for selected, false for a deselection or no action
             * @param cursor
             * @param [allowDeselect]
             * @return {*}
             */
            selectCursor: function (cursor, allowDeselect) {
                var selected = this.cursorIsSelected(cursor);
                if (allowDeselect !== false || !selected) {
                    cursor.fire('click', {selecting: true});
                    return !selected;
                }
                return false;
            },

            cursorIsSelected: function (cursor) {
                return cursor.classExists(__selectedClass);
            },

            nextNode: function () {
                return this._cursor && this._cursor.getElement().nextSibling ?
                    this._cursor.getElement().nextSibling : this._element.getElement().firstChild;
            },

            previousNode: function () {
                return this._cursor && this._cursor.getElement().previousSibling ?
                    this._cursor.getElement().previousSibling : this._element.getElement().lastChild;
            },

            hasAtLeastOneOption: function () {
                return !!this._element.getElement().firstChild;
            },

            reset: function (alsoResetCursor) {
                if (alsoResetCursor !== false) {
                    this.setCursor(null);
                }
                this._selections = [];
                this._lastDirection = null;
            },
        });

        return Selector;
    }
);

