define(
    'Inventis/Bundle/BricksBundle/Promise',[
        'when/es6-shim/Promise',
    ],
    function (Promise) {
        'use strict';

        return Promise;
    }
);

