
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/SelectManager',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (Component, Element) {
        'use strict';

        var SelectManager = Component.extend({

            _managerComponentId: null,

            _managerComponent: null,

            _entityComponentId: null,

            _entityComponent: null,

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.dependenciesFromConfig(setup);
                this.getManagerComponent().on('recordSaved', this.onRecordModified.bind(this), true);
                this.getManagerComponent().on('deleted', this.onRecordModified.bind(this), true);
                this.getManagerComponent().on('moved', this.onRecordModified.bind(this), true);
            },

            dependenciesFromConfig: function (setup) {
                if (setup.dependencies === undefined) {
                    return;
                }
                this.managerComponentIdFromDependencies(setup.dependencies);
                this.entityComponentIdFromDependencies(setup.dependencies);
            },

            managerComponentIdFromDependencies: function (dependencies) {
                if (dependencies.managerComponent === undefined) {
                    return;
                }
                this._managerComponentId = dependencies.managerComponent;
            },

            entityComponentIdFromDependencies: function (dependencies) {
                if (dependencies.entityComponent === undefined) {
                    return;
                }
                this._entityComponentId = dependencies.entityComponent;
            },

            getEntityComponent: function () {
                if (!this._entityComponent) {
                    this._entityComponent = new Element(document.getElementById(this._entityComponentId));
                }
                return this._entityComponent;
            },

            getManagerComponent: function () {
                if (!this._managerComponent) {
                    this._managerComponent = new Element(document.getElementById(this._managerComponentId));
                }
                return this._managerComponent;
            },

            onRecordModified: function (event, options) {
                this.getEntityComponent().fire('reload', {}, false);
            },
        });
        return SelectManager;
    }
);

