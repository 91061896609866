define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Text/TextRenderStrategyBase',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Class, Console, Promise) {
        'use strict';

        /**
         * @class TextRenderStrategyBase
         * @implements TextRenderStrategy
         */
        return Class.extend({
            abstract: [
                /**
                 * @abstract
                 * @name TextRenderStrategyBase#doRender
                 */
                'doRender',
                /**
                 * @abstract
                 * @name TextRenderStrategyBase#doGetEditorValue
                 */
                'doGetEditorValue',
                /**
                 * @abstract
                 * @name TextRenderStrategyBase#doSetEditorValue
                 */
                'doSetEditorValue',
            ],
            /**
             * @private
             */
            editorConfig: null,
            /**
             * @private
             */
            editorRenderPromise: null,

            editorInstance: null,

            element: null,

            /**
             * @var {Text}
             */
            textBrick: null,

            __construct: function (textBrick, configModule) {
                this.textBrick = textBrick;
                this.setElement(document.querySelector(textBrick.getRenderTo()));
                this.setEditorConfig(configModule);
            },

            setElement: function (element) {
                if (!(element instanceof HTMLElement)) {
                    throw new TypeError('Invalid element type given, expected HTMLElement, got ' + (typeof element));
                }
                this.element = element;
            },

            getElement: function () {
                return this.element;
            },

            /**
             * @return {Promise}
             */
            render: function () {
                if (!this.editorRenderPromise) {
                    this.editorRenderPromise = new Promise(function (resolve, reject) {
                        this.getEditorConfig().then(function (editorConfig) {
                            this.doRender(editorConfig)
                                .then(function (editor) {
                                    this.editorInstance = editor;
                                    this.attachListeners(editor)
                                    resolve(editor);
                                }.bind(this))
                                .catch(function (error) {
                                    Console.error(error);
                                    reject(error);
                                });
                        }.bind(this));
                    }.bind(this));
                }
                return this.editorRenderPromise;
            },

            /**
             * called when the rich text editor is ready for user manipulation
             *
             * @param {Object} editor
             */
            attachListeners: function (editor) {
                this.getElement().addEventListener('change', function (e) {
                    this.doSetEditorValue(editor, e.target.value);
                }.bind(this));
                editor.relatedTable = this.textBrick.getRelatedTable();
                this.registerEditorOnChangeHandler(editor, function () {
                    this.textBrick.getFormElement().fire('changed', {}, false);
                }.bind(this));
                /*
                 * create a function to return the ID as it will change constantly,
                 * while the editor instance will not be reloaded in between
                 * this code does not go into the adapter as its framework related
                 */
                editor.getRelatedId = function () {
                    var options = {};
                    this.textBrick.fire('getLoadedRecord', options);
                    // check if we have valid data
                    if (!options.record) {
                        throw new Error(
                            'Failed to retrieve loaded record through the getLoadedRecord event, ' +
                            'ensure this component is part of a form that can handle this type of request'
                        );
                    }
                    return options.record.id;
                }.bind(this);
            },

            /**
             * @param {Object} editor
             * @param {function} handler
             */
            registerEditorOnChangeHandler: function (editor, handler) {
                editor.on('change', handler);
            },

            /**
             * @return {Promise}
             */
            getEditorInstance: function () {
                if (!this.editorRenderPromise) {
                    return this.render();
                }
                return this.editorRenderPromise;
            },

            /**
             * stores the config object to use with the editor
             * @param {String} configModule the config module to load
             *
             * @return {Promise}
             */
            setEditorConfig: function (configModule) {
                if (typeof configModule !== 'string') {
                    throw new Error(
                        'Unexpected config received for module loading, expected string, got ' + (typeof configModule)
                    );
                }
                this.editorConfig = new Promise(function (resolve, reject) {
                    require(
                        [configModule],
                        function (config) {
                            resolve(config);
                        },
                        function (error) {
                            reject(
                                new Error('Failed to load ' + (error.requireModules && error.requireModules.join(',')))
                            );
                        }
                    );
                });
                return this.editorConfig;
            },

            /**
             * returns the stored config for use with the editor instance
             *
             * @return {Promise}
             */
            getEditorConfig: function () {
                if (!this.editorConfig) {
                    this.editorConfig = new Promise(function (resolve) {
                        resolve({});
                    });
                }
                return this.editorConfig;
            },

            /**
             * this is the editor value update method
             * it changes the content of the editor to the provided value
             * this method will NOT wait to set the value until the editor is ready
             * your component should call the getEditorIsReady method
             * to validate correct path of action
             *
             * @param {String} value
             *
             * @return {Promise}
             */
            setEditorValue: function (value) {
                if (!this.editorInstance) {
                    this.getElement().value = value;
                }
                return this.getEditorInstance().then(function (editor) {
                    this.doSetEditorValue(editor, value);
                }.bind(this));
            },

            /**
             * returns the current content of the editor or the html field if no editor exists yet
             * @return {String}
             */
            getEditorValue: function () {
                if (this.editorInstance) {
                    return this.doGetEditorValue(this.editorInstance);
                }
                return this.getElement().value;
            },
        });
    }
);

