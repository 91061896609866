
define(
    'Inventis/Bundle/BricksBundle/Brick/NotificationCenter',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/DelayedTask',
        'Inventis/Bundle/BricksBundle/HTML/Template',
        'Inventis/Bundle/BricksBundle/Polyfill/ElementClosest',
    ],
    function (Brick, HTMLElement, DelayedTask, HTMLTemplate) {
        'use strict';

        /**
         * @class NotificationCenter
         * @extends Brick
         */
        // @ts-ignore
        var NotificationCenter = Brick.extend({
            /**
             * @var {Number}
             */
            delayBeforeRemovingElementMilliseconds: 1000,

            /**
             * @var {Number}
             */
            intervalBetweenRemovingMultipleElementMilliseconds: 80,

            /**
             * @var {Number}
             */
            notificationCounter: 1,

            /**
             * @var {Object}
             */
            viewModel: null,

            /**
             * @var {Object}
             */
            template: null,

            /**
             * @var {String}
             */
            globalHiddenClass: null,

            /**
             * @var {Boolean}
             */
            allowClosingAll: null,

            /**
             * @var {String}
             */
            notificationItemSelector: null,

            /**
             * @var {String}
             */
            notificationItemClosedClass: null,

            /**
             * @var {String}
             */
            notificationContainerSelector: null,

            /**
             * @var {String}
             */
            notificationItemCloseButtonSelector: null,

            /**
             * @var {String}
             */
            notificationItemCloseAllButtonSelector: null,

            /**
             * @var {String}
             */
            notificationItemTemplateSelector: null,

            __construct: function () {
                this.viewModel = {
                    items: [],
                };
            },

            setupFromConfig: function (setup) {
                this.__super(setup);

                this.setGlobalHiddenClassFromSetup(setup);
                this.setAllowClosingAllFromSetup(setup);
                this.setNotificationItemSelectorFromSetup(setup);
                this.setNotificationItemClosedClassFromSetup(setup);
                this.setNotificationContainerSelectorFromSetup(setup);
                this.setNotificationItemCloseButtonSelectorFromSetup(setup);
                this.setNotificationItemCloseAllButtonSelectorFromSetup(setup);
                this.setNotificationItemTemplateSelectorFromSetup(setup);
            },

            onDomReady: function () {
                this.__super();

                if (this.getItemElements().length === 0) {
                    this.hideContainer();
                }

                this.template = new HTMLTemplate(this.getItemTemplateElement(), this.viewModel);
                this.template.render();

                this.showExistingItems();
            },

            attachListeners: function () {
                this.__super();

                this.attachCreateItemListeners();
                this.attachCloseListeners();
            },

            attachCreateItemListeners: function () {
                this.on('notify', function (e, options) {
                    this.addItem(this.createViewModelItemFromOptions(options));
                }.bind(this), true);
            },

            attachCloseListeners: function () {
                // Do not place this on the main element or it will conflict with the Symfony debug bar.
                new HTMLElement(this.getContainerElement()).on('click', function (e) {
                    e.preventDefault();

                    if (this.closingAllIsAllowed() && e.target === this.getCloseAllButtonElement()) {
                        this.closeAll();
                    } else if (
                        e.target.closest(this.getNotificationItemSelector()) !== null &&
                        e.target.closest(this.getNotificationItemCloseButtonSelector()) !== null
                    ) {
                        this.closeItem(e.target.closest(this.getNotificationItemSelector()));
                    }
                }.bind(this));
            },

            createViewModelItemFromOptions: function (options) {
                return this.createViewModelItem(
                    options.title,
                    options.message,
                    options.type,
                    options.showCloseButton !== undefined ? options.showCloseButton : true,
                    options.timeout !== undefined ? options.timeout : null
                );
            },

            createViewModelItem: function (title, message, type, showCloseButton, timeout) {
                return {
                    id: this.getId() + '-notification-client-' + this.notificationCounter++,
                    type: type,
                    title: title,
                    message: message,
                    showCloseButton: showCloseButton,
                    timeout: timeout,
                };
            },

            addItem: function (item) {
                this.showContainer();

                this.viewModel.items.push(item);

                // Not strictly necessary, but needed to create it now and for querySelector to succeed.
                this.template.render();

                // Minor delay is necessary at least in Firefox for transition to trigger or it will just be ignored.
                (new DelayedTask()).delay(function () {
                    this.showItem(item);
                }.bind(this), 25);
            },

            showItem: function (item) {
                this.showContainer();
                if (this.closingAllIsAllowed()) {
                    this.showCloseAllButton();
                }

                var itemElement = this.getItemElementForViewModelItem(item);

                itemElement.classList.remove(this.getNotificationItemClosedClass());

                if (item.timeout) {
                    (new DelayedTask()).delay(function (itemElement) {
                        // Check if element wasn't closed manually by the user in the meantime.
                        if (itemElement.parentElement !== null) {
                            this.closeItem(itemElement);
                        }
                    }.bind(this, itemElement), item.timeout);
                }

                this.fire('notificationShown', {
                    notification: itemElement,
                });
            },

            showExistingItems: function () {
                var items = this.getItemElements();

                for (var i = 0; i < items.length; ++i) {
                    // We don't have any view model item for these as they were passed from the server.
                    this.showItem({
                        id: items[i].id,
                        timeout: items[i].dataset.timeout,
                    });
                }
            },

            closeAll: function () {
                this.hideCloseAllButton();

                /*
                 * Explicitly *don't* use the -hide-all class here. If we use the -hide-all class, we have to apply it
                 * to the container, which means we must make sure that these elements - which are closing - get a
                 * second class to hide them whenever a new notification pops up in the mean time - or they will become
                 * visible again.
                 *
                 * Apart from that, there is also the issue that in styling it is hard to style a timeout like this
                 * based on the element count because you need nth-child, which is troublesome because we are injecting
                 * lots of <template> tags as well, which also count as children, so mimic the hiding logic ourselves,
                 * which is simpler in the end.
                 */
                var func = function (item) {
                    this.closeItem(item);
                }.bind(this);

                var itemsToCleanUp = this.getItemElements();

                for (var i = 0; i < itemsToCleanUp.length; ++i) {
                    (new DelayedTask()).delay(
                        func.bind(this, itemsToCleanUp[i]),
                        this.intervalBetweenRemovingMultipleElementMilliseconds * i
                    );
                }
            },

            closeItem: function (element) {
                element.classList.add(this.getNotificationItemClosedClass());

                if (this.getOpenItemElements().length === 0) {
                    this.hideContainer();
                }

                (new DelayedTask()).delay(function () {
                    this.deleteItem(element);
                }.bind(this), this.delayBeforeRemovingElementMilliseconds);
            },

            deleteItem: function (element) {
                element.parentElement.removeChild(element);

                if (this.getOpenItemElements().length === 0) {
                    this.hideContainer();
                }

                this.fire('notificationClosed', {
                    notificationElement: element,
                });
            },

            hideCloseAllButton: function () {
                this.getCloseAllButtonElement().classList.add(this.getGlobalHiddenClass());
            },

            showCloseAllButton: function () {
                this.getCloseAllButtonElement().classList.remove(this.getGlobalHiddenClass());
            },

            hideContainer: function () {
                this.getContainerElement().classList.add(this.getGlobalHiddenClass());
            },

            showContainer: function () {
                this.getContainerElement().classList.remove(this.getGlobalHiddenClass());
            },

            getContainerElement: function () {
                var container = this.getElement().querySelector(this.getNotificationContainerSelector());

                if (!container) {
                    throw new Error('Could not find container element');
                }

                return container;
            },

            getItemElementForViewModelItem: function (item) {
                return this.getContainerElement().querySelector('#' + item.id);
            },

            getItemElements: function () {
                return this.getElement().querySelectorAll(this.getNotificationItemSelector());
            },

            getOpenItemElements: function () {
                return this.getElement().querySelectorAll(
                    this.getNotificationItemSelector() + ':not(' + this.getNotificationItemClosedClass() + ')'
                );
            },

            getCloseAllButtonElement: function () {
                var button = this.getElement().querySelector(this.getNotificationItemCloseAllButtonSelector());

                if (!button) {
                    throw new Error('Could not find close all button element');
                }

                return button;
            },

            getItemTemplateElement: function () {
                var element = this.getElement().querySelector(this.getNotificationItemTemplateSelector());

                if (!element) {
                    throw new Error('Could not find notification template element');
                }

                return element;
            },

            setGlobalHiddenClassFromSetup: function (setup) {
                if (setup.globalHiddenClass !== undefined) {
                    this.setGlobalHiddenClass(setup.globalHiddenClass);
                }
            },

            setGlobalHiddenClass: function (globalHiddenClass) {
                this.globalHiddenClass = globalHiddenClass;
            },

            getGlobalHiddenClass: function () {
                return this.globalHiddenClass;
            },

            setNotificationItemSelectorFromSetup: function (setup) {
                if (setup.notificationItemSelector !== undefined) {
                    this.setNotificationItemSelector(setup.notificationItemSelector);
                }
            },

            setNotificationItemSelector: function (notificationItemSelector) {
                this.notificationItemSelector = notificationItemSelector;
            },

            getNotificationItemSelector: function () {
                return this.notificationItemSelector;
            },

            setNotificationItemClosedClassFromSetup: function (setup) {
                if (setup.notificationItemClosedClass !== undefined) {
                    this.setNotificationItemClosedClass(setup.notificationItemClosedClass);
                }
            },

            setNotificationItemClosedClass: function (notificationItemClosedClass) {
                this.notificationItemClosedClass = notificationItemClosedClass;
            },

            getNotificationItemClosedClass: function () {
                return this.notificationItemClosedClass;
            },

            setNotificationContainerSelectorFromSetup: function (setup) {
                if (setup.notificationContainerSelector !== undefined) {
                    this.setNotificationContainerSelector(setup.notificationContainerSelector);
                }
            },

            setNotificationContainerSelector: function (notificationContainerSelector) {
                this.notificationContainerSelector = notificationContainerSelector;
            },

            getNotificationContainerSelector: function () {
                return this.notificationContainerSelector;
            },

            setNotificationItemCloseButtonSelectorFromSetup: function (setup) {
                if (setup.notificationItemCloseButtonSelector !== undefined) {
                    this.setNotificationItemCloseButtonSelector(setup.notificationItemCloseButtonSelector);
                }
            },

            setNotificationItemCloseButtonSelector: function (notificationItemCloseButtonSelector) {
                this.notificationItemCloseButtonSelector = notificationItemCloseButtonSelector;
            },

            getNotificationItemCloseButtonSelector: function () {
                return this.notificationItemCloseButtonSelector;
            },

            setNotificationItemCloseAllButtonSelectorFromSetup: function (setup) {
                if (setup.notificationItemCloseAllButtonSelector !== undefined) {
                    this.setNotificationItemCloseAllButtonSelector(setup.notificationItemCloseAllButtonSelector);
                }
            },

            setNotificationItemCloseAllButtonSelector: function (notificationItemCloseAllButtonSelector) {
                this.notificationItemCloseAllButtonSelector = notificationItemCloseAllButtonSelector;
            },

            getNotificationItemCloseAllButtonSelector: function () {
                return this.notificationItemCloseAllButtonSelector;
            },

            setNotificationItemTemplateSelectorFromSetup: function (setup) {
                if (setup.notificationItemTemplateSelector !== undefined) {
                    this.setNotificationItemTemplateSelector(setup.notificationItemTemplateSelector);
                }
            },

            setNotificationItemTemplateSelector: function (notificationItemTemplateSelector) {
                this.notificationItemTemplateSelector = notificationItemTemplateSelector;
            },

            getNotificationItemTemplateSelector: function () {
                return this.notificationItemTemplateSelector;
            },

            setAllowClosingAllFromSetup: function(setup) {
                this.allowClosingAll = setup.allowClosingAll;
            },

            closingAllIsAllowed: function () {
                return this.allowClosingAll;
            }
        });

        return NotificationCenter;
    }
);

