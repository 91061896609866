/* global ObserverTransform PathObserver */
/**
 * @see https://www.polymer-project.org/2.0/docs/devguide/data-binding
 * @see https://github.com/googlearchive/TemplateBinding
 */
define(
    'Inventis/Bundle/BricksBundle/HTML/Template',[
        'node-bind', // loads template-binding also, Main.js shim configuration
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Platform, Class, Console) {
        'use strict';
        /**
         * @function
         * @name BindingProcessorFactory
         * @param {String} path
         * @param {String} name
         * @param {Node} node
         * @return {BindingProcessor|null} null of no special binding applies
         */
        /**
         * @function
         * @name BindingProcessor
         * @param {Object|string} model
         * @param {Node} node
         * @param {boolean} oneTime
         * @return {string}
         */
        /**
         * @function
         * @name ModelProcessor
         * @param {String} path
         * @param {String} name
         * @param {Node} node
         * @return {Object}
         */

        /**
         * @class HTMLTemplate
         */
        return Class.extend({
            /**
             * @var {HTMLTemplateElement}
             */
            template: null,

            /**
             * @var Object<function(value)>
             */
            renderers: null,
            /**
             * @param {HTMLTemplateElement} template
             * @param {Object}              model
             * @param {Object}              renderers
             */
            __construct: function (template, model, renderers) {
                if (!(template instanceof HTMLTemplateElement)) {
                    throw new Error('template argument is not a valid HTMLTemplateElement');
                }
                if (!document.contains(template)) {
                    Console.debug(
                        'template',
                        template,
                        'is not yet part of the DOM, place it where its content is supposed to be generated' +
                        ' before binding any data'
                    );
                }
                this.renderers = renderers || {};
                this.template = template;
                this.template.bindingDelegate = {
                    prepareBinding: this.prepareBinding.bind(this)
                };
                this.template.model = model || {};
            },

            getModel: function () {
                return this.template.model;
            },

            /**
             * add new or override existing data in the template model and render it
             * you cannot replace the model entirely as the model reference has to be maintained, but updating all
             * properties will have the same effect
             *
             * @param {Object} dataToUpdate
             */
            update: function (dataToUpdate) {
                Object.assign(this.template.model, dataToUpdate);
                this.render();
            },

            getParent: function () {
                return this.template.parentElement;
            },

            /**
             * trigger a manual update in case you've made changes to your bound data
             */
            render: function () {
                Platform.performMicrotaskCheckpoint();
            },

            /**
             * @private
             *
             * @param {String} bindingString
             * @param {String} name
             * @param {Node} node
             *
             * @return {function}
             */
            prepareBinding: function (bindingString, name, node) {
                if (bindingString === '') {
                    return null;
                }

                // allow var|renderer1| renderer2|renderer...n
                var pattern = /^(!)?([^|:]*)?(?:(\|(?:[^|:]\|?:?)+)+)?$/, // inject this just before $ for events (?:(?:(::[^:]+))+)?
                    matches = bindingString.match(pattern),
                    // in order of possible appearance in pattern:
                    negate = matches[1] === '!',
                    pathString = matches[2],
                    renderers = matches[3],
                    event = matches[4];

                if (!renderers && !event && !negate) {
                    return null;
                }

                if (renderers && renderers.substr(0, 1) === '|') {
                    renderers = renderers.substr(1).replace(/\s/g, '').split('|');
                    for (var i = 0; i < renderers.length; ++i) {
                        if (!this.renderers[renderers[i]]) {
                            throw new Error('Unknown renderer "' + renderers[i] + '" in binding `' + bindingString + '`');
                        }
                        renderers[i] = this.renderers[renderers[i]];
                    }
                }
                // TODO(jane): implement event listeners that trigger on observer changes
                // if (event && event.substr(0, 2) === '::') {
                //     event = event.substr(2);
                // }

                var transformer = function (value, model, node) {
                    if (renderers) {
                        for (var i = 0; i < renderers.length; ++i) {
                            value = renderers[i](value, model, node, name);
                        }
                    }
                    if (negate) {
                        value = !value;
                    }
                    return value;
                };
                return function (model, node, oneTime) {
                    var pathObserver = new PathObserver(model, pathString);

                    if (oneTime) {
                        return transformer(pathObserver.path.getValueFrom(model), model, node);
                    }
                    return new ObserverTransform(pathObserver, function (value) {
                        return transformer(value, model, node);
                    });
                };
            },

            /**
             * @param {String} name
             * @param {function(value)} renderer
             */
            registerRenderer: function (name, renderer) {
                if (this.renderers[name]) {
                    Console.debug('Overriding renderer ' + name);
                }

                this.renderers[name] = renderer;
            },
        });
    }
);

