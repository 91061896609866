
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Checkable',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input',
    ],
    function (Input) {
        'use strict';

        var Checkable = Input.extend({

            onDomReady: function () {
                this.__super();
                // Commented out as it causes the parent form to be immediately marked as dirty. The original commit
                // message was not clear as to why this was added in the first place.
                // IE fix, can't get value of disabled element
                // var element = this.getElement(),
                //    disabled = element.disabled;
                // element.disabled = false;
                // this.fire('change', {fieldName: this.getName(), value: this.checked});
                // element.disabled = disabled;
            },

            attachListeners: function () {
                // put this before super call to ensure its run first on browsers not supporting capture
                this.on('change', function (e, options) {
                    /*
                     * removed all the bloat from the code that was available under onDomReady and moved it to
                     * attachListeners, there should never be a need to re-fire the original event and if there is
                     * it should be clearly documented why.
                     */
                    options.fieldValue = this.getValue();
                    options.fieldName = this.getName();
                    options.checked = this.getElement().checked;
                }, true, true);

                this.__super();
                this.on('check', this.onCheck, true);
                this.on('uncheck', this.onUnCheck, true);
            },

            onCheck: function (event, options) {
                this.setValue(this.getElement().value);
            },

            onUnCheck: function (event, options) {
                this.setValue(false);
            },

            setValueFromRecord: function (record) {
                if (record.checked === undefined || record.checked) {
                    this.setValue(record.value !== undefined ? record.value : record);
                } else {
                    this.setValue(false);
                }
            },

            /**
             * sets the value of the element
             * @param {*} value
             * @return {*}
             */
            setValue: function (value) {
                var element = this.getElement(),
                    disabled = element.disabled;
                // IE fix, can't get value of disabled element
                element.disabled = false;
                element.checked = value == element.value;
                element.disabled = disabled;
                // Firefox fix, firing the change event sometimes just doesn't happen if this statement happens inside
                // the disabled changes.
                this.fire('change', {fieldName: this.getName(), value: this.getValue()});
                return this;
            },

            getValue: function () {
                var disabled = this.getElement().disabled,
                    value;
                this.getElement().disabled = false;
                value = this.getElement().checked ? this.__super() : null;
                this.getElement().disabled = disabled;
                return value;
            },

        });
        return Checkable;
    }
);

