
define(
    'Inventis/Bundle/BricksBundle/Brick/EventListeners/NotifyingListener',[
    ],
    function () {
        'use strict';

        return {
            /**
             * Simple notification sender that reads notification settings from passed data.
             *
             * If you want to do something more advanced, such as use the options of the event or your own configured
             * data, register a custom listener script with your own implementation of this instead.
             *
             * @param {Event}            e
             * @param {Object}           options
             * @param {HTMLElement|null} destinationElement
             * @param {Object|null}      data
             */
            notify: function (e, options, destinationElement, data) {
                this.fire('notify', {
                    title: data.title,
                    message: data.message,
                    type: data.type,
                    showCloseButton: data.showCloseButton,
                    timeout: data.timeout,
                });
            },
        };
    }
);

