
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/CheckboxGroup',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/HTML/Field/Element',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Component, Element, $) {
        'use strict';

        var __errorClass = '-error',
            __errorMessageClass = 'error-text__message',
            __errorMessagesContainerClass = 'group-error-text';

        var CheckboxGroup = Component.extend({
            _groupName: null,
            _checkedValues: null,

            __construct: function () {
                this._checkedValues = {};
                this.__super();
            },

            attachListeners: function () {
                var selectedValues = [];
                this.on('change', function (e, options) {
                    options.groupName = this.getGroupName();

                    // Note that the value is null when a checkbox is not checked and the (HTML attribute) value isn't.
                    if (options.fieldValue !== null) {
                        if (selectedValues.indexOf(options.fieldValue) === -1) {
                            selectedValues.push(options.fieldValue);
                        }
                    } else {
                        var index = selectedValues.indexOf(options.getTarget().value);

                        if (index !== -1) {
                            selectedValues.splice(index, 1);
                        }
                    }
                    options.selectedValues = selectedValues;

                    this.fire('groupChange', options);
                }, true);

                this.on('changed', this.onCheckableChanged, true);
                var formElement = this.getFormElement();

                if (formElement) {
                    formElement.on('recordSaveFailed', this.createFormEventsHandler(this.handleRecordSaveFailed), true);
                    formElement.on('recordLoaded', this.createFormEventsHandler(this.handleRecordLoaded), true);
                    formElement.on('recordSaved', this.createFormEventsHandler(this.handleRecordLoaded), true);
                }

                this.__super();
            },

            onCheckableChanged: function (e, options) {
                var target = options.getTarget(),
                    fieldValues = [],
                    p;
                this._checkedValues[target.id] = options.fieldValue;
                for (p in this._checkedValues) {
                    if (this._checkedValues.hasOwnProperty(p)) {
                        if (this._checkedValues[p] !== null) {
                            fieldValues.push(this._checkedValues[p]);
                        }
                    }
                }
                options.fieldValue = fieldValues;
            },

            createFormEventsHandler: function (callback) {
                var me = this;
                return function (e, options) {
                    // long live IE for its compliance!!
                    if (options.getTarget() === me.getFormElement()._element) {
                        callback.call(me, e, options);
                    }
                };
            },

            handleRecordSaveFailed: function (e, options) {
                if (!options.errors) {
                    return;
                }
                if (options.errors[this.getName()] !== undefined) {
                    this.setErrors(options.errors[this.getName()]);
                } else {
                    this.clearErrors();
                }
            },

            handleRecordLoaded: function () {
                this.clearErrors();
            },

            setErrors: function (messages) {
                var element = this.getComponentElement(),
                    messageContainer = $('.' + __errorMessagesContainerClass, element.getElement()).shift();

                if (!element.classExists(__errorClass)) {
                    element.addClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = '<span class="' + __errorMessageClass + '">'
                        + messages.join('<span/><span class="' + __errorMessageClass + '">')
                        + '</span>';
                }
                this.fire('hasError', {errorClass: __errorClass}, true);
            },

            clearErrors: function () {
                var element = this.getComponentElement(),
                    messageContainer = $('.' + __errorMessagesContainerClass, element.getElement()).shift();

                if (element.classExists(__errorClass)) {
                    element.removeClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = '';
                }
            },

            getFormElement: function () {
                var forms = document.getElementsByTagName('form');

                for (var i = 0; i < forms.length; i++) {
                    if (forms[i].contains(this._element)) {
                        return new Element(forms[i]);
                    }
                }
            },

            getName: function () {
                var fullname = this.getElement().querySelector('input').getAttribute('name');
                return fullname.substr(0, fullname.lastIndexOf('['));
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setGroupNameFromSetup(setup);
            },

            setGroupNameFromSetup: function (setup) {
                if (setup.groupName) {
                    this.setGroupName(setup.groupName);
                }
            },

            setGroupName: function (name) {
                this._groupName = name;
                return this;
            },

            getGroupName: function () {
                return this._groupName;
            },
        });
        return CheckboxGroup;
    }
);

