define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ReorderPlugin',[
        'shopify/draggable',
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',
    ],
    function (Draggable, GridPluginBase) {
        /**
         * @class DragDropPlugin
         * @extends GridPluginBase
         */
        return GridPluginBase.extend({
            __construct: function (config) {
                // @see https://github.com/Shopify/draggable/tree/master/src/Draggable
                this.config = config ? this.processConfig(config) : {};
            },

            processConfig: function (config) {
                if (config.plugins && config.plugins) {
                    var plugins = [];
                    config.plugins.forEach(function (plugin) {
                        plugins.push(Draggable.Plugins[plugin]);
                    });
                    config.plugins = plugins;
                }
                config.draggable = config.draggable || 'tr';
                config.handle = config.handle === undefined ? '.data-grid__icon' : config.handle;
                config.container = config.container || 'tbody';
                return config;
            },

            afterRender: function (view) {
                if (this.sortable) {
                    this.sortable.destroy();
                    this.sortable = null;
                }
                var container = view.getContainerElement();
                if (this.config.container) {
                    container = container.querySelectorAll(this.config.container);
                }
                this.sortable = new Draggable.Sortable(container, this.config);
                /**
                 * @param {SortableSortedEvent} event
                 */
                this.sortable.on('sortable:stop', this.onSortableStop.bind(this));
            },

            afterRedraw: function (view) {
                this.afterRender(view);
            },

            onSortableStop: function (event) {
                if (event.newIndex === event.oldIndex
                    && event.newContainer === event.oldContainer
                ) {
                    return;
                }
                var reorderEvent = new Event('reordered', {
                    bubbles: true,
                });

                reorderEvent.newIndex = event.newIndex;
                reorderEvent.oldIndex = event.oldIndex;
                reorderEvent.newContainer = event.newContainer;
                reorderEvent.oldContainer = event.oldContainer;
                reorderEvent.rowSelector = this.config.draggable;

                /**
                 * There is currently no event in draggable that signals the total end of all its actions
                 * at the last event fired (mirror:destroy) there are still dom elements present that get removed
                 * afterwards, this prevents any other libraries re-evaluating dom state as they will get it wrong
                 * (find a few extra elements extra)
                 * an example is the SelectablePlugin which reevaluates the dom state to identify followup shiftClicks
                 */
                setTimeout(function () {
                    event.dragEvent.originalSource.dispatchEvent(reorderEvent);
                }, 10);
            },

            isDragging: function () {
                return this.sortable && this.sortable.isDragging();
            },
        });
    }
);

