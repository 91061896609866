
define(
    'Inventis/Bundle/BricksBundle/Mixins/Maskable',[
        'Inventis/Bundle/BricksBundle/Mixin',
    ],
    function (Mixin) {
        var __maskClass = 'is-masked',
            __maskElementClass = 'mask';

        var Maskable = Mixin({
            abstract: ['getElement'],

            _mask: null,

            /**
             * Retrieves the existing mask, creating it if needed.
             *
             * @param {String|null} className Class name to use for the mask element, or null to use the default.
             */
            getMask: function (className) {
                if (this._mask === null) {
                    this._mask = document.createElement('div');
                    this._mask.style.top = this.getElement().scrollTop;
                }

                this._mask.className = className || __maskElementClass;
                return this._mask;
            },

            /**
             * Places a mask on the element. If the element is already masked, this method does nothing.
             *
             * @param {String|null} className Class name to use for the mask, or null to use the default.
             * @param {String|null} elementClassName Class name to use for the mask element, or null to use the default.
             */
            mask: function (className, elementClassName) {
                var element = this.getElement(),
                    classes = element.className.split(' '),
                    maskClass = className || __maskClass;

                element.scrollTop = 0;

                if (classes.indexOf(maskClass) === -1) {
                    classes.push(maskClass);
                    element.className = classes.join(' ');
                }

                element.appendChild(this.getMask(elementClassName));
            },

            /**
             * Removes the previously applied mask. Does nothing if the element is not masked.
             */
            unmask: function () {
                var mask = this.getMask(),
                    element = this.getElement();

                if (element.contains(mask)) {
                    var classes = element.className.split(' '),
                        maskClassIndex = classes.indexOf(__maskClass);

                    if (maskClassIndex !== -1) {
                        classes.splice(maskClassIndex, 1);
                        element.className = classes.join(' ');
                    }

                    element.removeChild(mask);
                }
            },
        });

        return Maskable;
    }
);

