define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/EventMapperPlugin',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginBase',
    ],
    function (GridPluginBase) {
        'use strict';

        /**
         * @class EventMapperPlugin
         * @extends GridPluginBase
         */
        return GridPluginBase.extend({
            __construct: function (config) {
                this.config = this.processConfig(config);
            },

            processConfig: function (config) {
                return {
                    eventData: config.eventData || 'eventMapper',
                };
            },

            beforeRender: function (view) {
                var container = view.getContainerElement();
                container.addEventListener('click', this.onClick.bind(this));
            },

            onClick: function (event) {
                var target = event.target;

                if (target.dataset && target.dataset[this.config.eventData]) {
                    var mappedEvent = new Event(target.dataset[this.config.eventData], {
                        bubbles: true,
                    });
                    target.dispatchEvent(mappedEvent);
                    if (mappedEvent.defaultPrevented) {
                        event.preventDefault();
                    }
                    if (mappedEvent.cancelBubble) {
                        event.stopPropagation();
                    }
                }
            },
        });
    }
);

