
define(
    'Inventis/Bundle/BricksBundle/Brick/Chart',[
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Mixins/DataToRemember',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
        'chartjs/chart',
    ],
    function ($, Component, DataToRemember, ApiAwareMixin, ChartJS) {
        'use strict';

        var ChartObject = ChartJS.noConflict();

        var Chart = Component.extend({
            use: [ApiAwareMixin, DataToRemember],

            /**
             * Use promises because we don't want charts in other tabs to load if the tab isn't active.
             */
            observablePromiseEnabled: true,

            /**
             * Wrapper for the chart object that is created when a specific grid type is activated.
             *
             * @var ChartObject
             */
            _instance: null,

            /**
             * @var ChartObject
             */
            _chartObject: null,

            /**
             * @var {Array}
             */
            _config: null,

            /**
             * @var boolean
             */
            _autoLoad: null,

            /**
             * @var boolean
             */
            _maskReloads: null,

            /**
             * @var int
             */
            _autoRefreshTime: null,

            /**
             * The raw (unprocessed, as in not readable by ChartJS) data currently active in the chart.
             *
             * @var {Array}
             */
            _data: null,

            /**
             * @var string
             */
            _idField: null,

            /**
             * @var string
             */
            _valueField: null,

            /**
             * @var string
             */
            _displayField: null,

            /**
             * Constructor.
             */
            __construct: function () {
                this._data = [];
            },

            /**
             * Apply parameters set in the config passed to this object.
             */
            setupFromConfig: function (setup) {
                this.__super(setup);

                this.setConfigFromSetup(setup);
                this.setTypeFromSetup(setup);
                this.setAutoLoadFromSetup(setup);
                this.setMaskReloadsFromSetup(setup);
                this.setAutoRefreshTimeFromSetup(setup);
                this.setIdFieldFromSetup(setup);
                this.setValueFieldFromSetup(setup);
                this.setDisplayFieldFromSetup(setup);

                // Invoke mixin setup.
                this.setApiFromConfig(setup);
                this.setDataToRememberFromConfig(setup);

                if (this.getAutoLoad()) {
                    this.setDataFromSetup(setup);
                }

                if (this.getAutoRefreshTime()) {
                    window.setInterval(this.onReload.bind(this), this.getAutoRefreshTime());
                }
            },

            render: function () {
                var canvas = $('canvas', this.getElement())[0];

                this.setChartObject(new ChartObject(canvas.getContext('2d')));

                this.on('reload', this.onReload, true);

                // Must be performed after the chart object has been initialized.
                if (this.getAutoLoad() === true) {
                    this.updateData(this.getData());
                } else if (this.getAutoLoad() === 'ajax') {
                    this.fire('reload');
                }
            },

            /**
             * Attaches event listeners for the chart.
             */
            attachListeners: function () {
                // If you route extraData to a reload event on {self}, extraData will be processed after the reload is
                // fired if these are not attached here (before event routing handlers).
                this.attachDataToRememberListeners();

                this.__super();
            },

            /**
             * Performs an AJAX request to fetch data to display in the chart.
             */
            onReload: function (options) {
                var data = Object.create(this._extraData || {});

                if (this.getMaskReloads()) {
                    this.mask();
                }

                this.apiRequest('getData', data, function (response) {
                    if (response.success) {
                        this.updateData(response.result);

                        if (this.getMaskReloads()) {
                            this.unmask();
                        }
                    } else {
                        throw new Error('Loading the chart data through AJAX failed!');
                    }
                });

                return false;
            },

            /**
             * @param setup
             */
            setDataFromSetup: function (setup) {
                if (setup.data !== undefined) {
                    this.setData(setup.data);
                }
            },

            /**
             * Template method that processes data provided to the chart into a format ChartJS can understand.
             * Subcomponents should override this to apply the data and pass it to the actual chart in the underlying
             * format it expects.
             *
             * @param data
             */
            updateData: function (data) {
                throw new Error('Chart subclass must implement updateData template method!');
            },

            /**
             * @return {*}
             */
            getInstance: function () {
                return this._instance;
            },

            /**
             * @param instance
             */
            setInstance: function (instance) {
                this._instance = instance;
                return this;
            },

            /**
             * @return {*}
             */
            getChartObject: function () {
                return this._chartObject;
            },

            /**
             * @param object
             */
            setChartObject: function (object) {
                this._chartObject = object;
                return this;
            },

            /**
             * @param setup
             */
            setConfigFromSetup: function (setup) {
                if (setup.config !== undefined) {
                    this.setConfig(setup.config);
                }
            },

            /**
             * @return {*}
             */
            getConfig: function () {
                return this._config;
            },

            /**
             * @param config
             */
            setConfig: function (config) {
                this._config = config;
                return this;
            },

            /**
             * @param setup
             */
            setTypeFromSetup: function (setup) {
                if (setup.type !== undefined) {
                    this.setType(setup.type);
                }
            },

            /**
             * @return {*}
             */
            getType: function () {
                return this._type;
            },

            /**
             * @param type
             */
            setType: function (type) {
                this._type = type;
                return this;
            },

            /**
             * @param setup
             */
            setAutoLoadFromSetup: function (setup) {
                if (setup.autoLoad !== undefined) {
                    this.setAutoLoad(setup.autoLoad);
                }
            },

            /**
             * @return {*}
             */
            getAutoLoad: function () {
                return this._autoLoad;
            },

            /**
             * @param autoLoad
             */
            setAutoLoad: function (autoLoad) {
                this._autoLoad = autoLoad;
                return this;
            },

            /**
             * @param setup
             */
            setMaskReloadsFromSetup: function (setup) {
                if (setup.maskReloads !== undefined) {
                    this.setMaskReloads(setup.maskReloads);
                }
            },

            /**
             * @return {*}
             */
            getMaskReloads: function () {
                return this._maskReloads;
            },

            /**
             * @param maskReloads
             */
            setMaskReloads: function (maskReloads) {
                this._maskReloads = maskReloads;
                return this;
            },

            /**
             * @param setup
             */
            setAutoRefreshTimeFromSetup: function (setup) {
                if (setup.autoRefreshTime !== undefined) {
                    this.setAutoRefreshTime(setup.autoRefreshTime);
                }
            },

            /**
             * @return {*}
             */
            getAutoRefreshTime: function () {
                return this._autoRefreshTime;
            },

            /**
             * @param time
             */
            setAutoRefreshTime: function (time) {
                this._autoRefreshTime = time;
                return this;
            },

            /**
             * Retrieves the currently set (and unprocessed) data.
             *
             * @return {Array}
             */
            getData: function (data) {
                return this._data;
            },

            /**
             * Sets the currently active (and unprocessed) data to use in the chart.
             *
             * @param data
             */
            setData: function (data) {
                this._data = data;
                return this;
            },

            /**
             * @param setup
             */
            setIdFieldFromSetup: function (setup) {
                if (setup.idField !== undefined) {
                    this.setIdField(setup.idField);
                }
            },

            /**
             * @return {*}
             */
            getIdField: function () {
                return this._idField;
            },

            /**
             * @param field
             */
            setIdField: function (field) {
                this._idField = field;
                return this;
            },

            /**
             * @param setup
             */
            setValueFieldFromSetup: function (setup) {
                if (setup.valueField !== undefined) {
                    this.setValueField(setup.valueField);
                }
            },

            /**
             * @return {*}
             */
            getValueField: function () {
                return this._valueField;
            },

            /**
             * @param field
             */
            setValueField: function (field) {
                this._valueField = field;
                return this;
            },

            /**
             * @param setup
             */
            setDisplayFieldFromSetup: function (setup) {
                if (setup.displayField !== undefined) {
                    this.setDisplayField(setup.displayField);
                }
            },

            /**
             * @return {*}
             */
            getDisplayField: function () {
                return this._displayField;
            },

            /**
             * @param field
             */
            setDisplayField: function (field) {
                this._displayField = field;
                return this;
            },
        });

        return Chart;
    }
);

