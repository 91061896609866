define(
    'Inventis/Bundle/BricksBundle/Base64EncodedJsonParser',[
        'Inventis/Bundle/BricksBundle/Base64Encoder',
        'Inventis/Bundle/BricksBundle/JsonEncoder',
    ],
    function (Base64Encoder, JsonEncoder) {
        'use strict';
        return {
            parse: function (string) {
                return JsonEncoder.decode(Base64Encoder.decode(string));
            },
        };
    }
);

