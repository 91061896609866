
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/DataText',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (Field, HtmlElement) {
        'use strict';

        var DataText = Field.extend({
            _formElement: null,
            _text: null,

            /**
             *
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setTextFromSetup(setup);
            },

            /**
             *
             * @param setup
             */
            setTextFromSetup: function (setup) {
                if (setup.text !== undefined) {
                    this.setText(setup.text);
                }
            },

            /**
             *
             * @param text
             * @return {*}
             */
            setText: function (text) {
                this._text = text;
                return this;
            },

            /**
             *
             * @return {Boolean}
             */
            getText: function () {
                return this._text;
            },

            /**
             * the field assigned the value to the html element, not validating it
             * that is the responsibility of extending field components
             * @param value
             * @return this
             */
            setValue: function (value) {
                // Display static text (if 'text' is set) or database field as read only text otherwise.
                if (this._text === null || this._text === undefined || this._text === false) {
                    value = value !== null ? value : '';

                    // When displaying a database field, allow use of 'defaultValue' as fallback when the data is empty.
                    if (value === '' && this._defaultValue) {
                        value = this._defaultValue;
                    }
                } else {
                    value = this._text;
                }

                this.getElement().innerHTML = this._value = value;
                return this;
            },

            /**
             * returns a reference to the form that is relevant to the current field
             * @return {*}
             */
            getForm: function () {
                return this.getFormElement().getElement();
            },

            /**
             * returns the value of the current HTML element
             */
            getValue: function () {
                return this._value;
            },

            getFormElement: function () {
                if (this._formElement !== null) {
                    return this._formElement;
                }
                var element = this.getElement(),
                    tag;
                do {
                    element = element.parentNode;
                    tag = element.tagName.toLowerCase();
                } while (tag !== 'form' && element.parentNode);
                if (tag !== 'form') {
                    throw new Error('DataText components require a form to function');
                }
                this._formElement = new HtmlElement(element);
                return this._formElement;
            },
        });
        return DataText;
    }
);

