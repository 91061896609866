
define(
    'Inventis/Bundle/BricksBundle/Brick/TabPanel',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/HTML/Element',
        'Inventis/Bundle/BricksBundle/Button',
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
    ],
    function (Brick, Element, Button, $) {
        'use strict';

        var _tabsClassSelector = '.tabs__switcher',
            _tabClassSelector = '.tabs__switch',
            activeClassName = '-active';

        /**
         * @class TabPanel
         * @extends Brick
         */
        var TabPanel = Brick.extend({
            /**
             * @var {Array}
             */
            _tabs: null,
            _activeTab: 0,
            /**
             * @var {Array}
             */
            _tabButtons: null,

            __construct: function () {
                this.__super(arguments);
                this._tabs = [];
                this._tabButtons = [];
            },

            onDomReady: function () {
                this.__super();
                this._attachTabEventHandling();

                // domRendered isn't always fired after load (e.g. in the maintenance module), so make the first tab
                // active.
                this.showActiveTab();

                this.on('domRendered', this.onDomRender);
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setTabsFromSetup(setup);
                this.setActiveTabFromSetup(setup);
            },

            setActiveTabFromSetup: function (setup) {
                if (setup.activeTab !== undefined) {
                    this.setActiveTab(setup.activeTab);
                }
            },

            /**
             * shows the panel that is at the activeTab index
             */
            showActiveTab: function () {
                this.showPanel(this.getTabs()[this.getActiveTab()]);
                this.activatePanel();
            },

            /**
             * sets the index of the currently active tab
             * this method does not switch to that tab, use showActiveTab
             * for that or showPanel if you know the panelId
             * @param index
             * @return {*}
             */
            setActiveTab: function (index) {
                this._activeTab = parseInt(index);
                return this;
            },

            getActiveTab: function () {
                return this._activeTab;
            },

            /**
             * reads out all top level sub components and puts their ids
             * in a list
             * @param setup
             */
            setTabsFromSetup: function (setup) {
                if (setup.tabs) {
                    this.setTabs(setup.tabs);
                    this.hideTabPanels();
                }
            },

            hideTabPanels: function () {
                var panel,
                    i,
                    tabs = this.getTabs();
                for (i in tabs) {
                    panel = this.getElement().querySelector('#' + tabs[i]);
                    if (panel) {
                        panel = new Element(panel);
                        panel.hide();
                    }
                }
            },

            setTabs: function (tabs) {
                this._tabs = tabs;
                return this;
            },

            getTabs: function () {
                return this._tabs;
            },

            hasError: function (panelId) {
                return function (event, options) {
                    // 'this' is the panel that has the error
                    for (var x in this.getTabs()) {
                        if (this.getTabs()[x] === panelId) {
                            var element = this._tabButtons[x]._element,
                                classes = element.className.split(' '),
                                __errorClass = options.errorClass;
                            if (classes.indexOf(__errorClass) === -1) {
                                classes.push(__errorClass);
                                element.className = classes.join(' ');
                            }
                        }
                    }
                };
            },

            hasNoError: function (panelId) {
                return function (event, options) {
                    // 'this' is the panel that has the error
                    for (var x in this.getTabs()) {
                        if (this.getTabs()[x] === panelId) {
                            var element = this._tabButtons[x]._element,
                                classes = element.className.split(' '),
                                __errorClass = options.errorClass,
                                index = classes.indexOf(__errorClass);
                            if (index !== -1) {
                                classes.splice(index, 1);
                                element.className = classes.join(' ');
                            }
                        }
                    }
                };
            },

            onDisableAllTabs: function (event, options) {
                if (options.id === this.getId()) {
                    for (var i in this._tabButtons) {
                        if (this._tabButtons.hasOwnProperty(i)) {
                            this._tabButtons[i].disable();
                        }
                    }
                }
            },

            _attachTabEventHandling: function () {
                var tabButtons = $(':scope > ' + _tabsClassSelector + ' ' + _tabClassSelector, this.getElement()),
                    i,
                    x,
                    tab,
                    tabElement;
                this.on('disableAllTabs', this.onDisableAllTabs);
                for (i = 0; i < tabButtons.length; i++) {
                    tabButtons[i] = new Button(
                        tabButtons[i],
                        {for: tabButtons[i].getAttribute('data-for'), tabPanel: this.getId()}
                    );
                    tabButtons[i].on('buttonClicked', this.onTabButtonClick.bind(this), true);
                }

                for (x in this.getTabs()) {
                    tab = document.getElementById(this.getTabs()[x]);
                    tabElement = new Element(tab);
                    tabElement.on('hasError', this.hasError(tabElement._element.id).bind(this), true);
                    tabElement.on('noError', this.hasNoError(tabElement._element.id).bind(this), true);
                }

                // this.on("buttonClicked", this.onTabButtonClick, true);
                this.on('componentDisabled', function (e, options) {
                    var index = this.getTabs().indexOf(options.id);
                    if (index != -1) {
                        (this.hasNoError(options.id).bind(this))({}, {errorClass: 'error'});
                        tabButtons[index].disable();
                        tabElement = new Element();
                        // change the active tab is needed
                        if (index === this.getActiveTab()) {
                            for (var i = 0; i < tabButtons.length; i++) {
                                if (!tabButtons[i].disabled()) {
                                    this.setActiveTab(i).showActiveTab();
                                }
                            }
                            if (index === this.getActiveTab()) {
                                this.setActiveTab(0).showActiveTab();
                            }
                        }
                        return false;
                    }
                }, true);
                this.on('componentEnabled', function (e, options) {
                    var index = this.getTabs().indexOf(options.id);
                    if (index != -1) {
                        tabButtons[index].enable();
                    }
                    // if active tab is disabled, enable this tab that's enabled for sure
                    if (tabButtons[this.getActiveTab()].disabled()) {
                        this.setActiveTab(index).showActiveTab();
                    }
                }, true);
                this._tabButtons = tabButtons;
            },

            activatePanel: function () {
                for (var x in this._tabButtons) {
                    var classes = String(this._tabButtons[x]._element.className).split(' '),
                        index;

                    if (parseInt(x) === this._activeTab) {
                        index = classes.indexOf(activeClassName);
                        if (index == -1) {
                            classes.unshift(activeClassName);
                            this._tabButtons[x]._element.className = classes.join(' ');
                        }
                    } else {
                        index = classes.indexOf(activeClassName);
                        if (index != -1) {
                            classes.splice(index, 1);
                            this._tabButtons[x]._element.className = classes.join(' ');
                        }
                    }
                }
            },

            onTabButtonClick: function (e, options) {
                if (options.tabPanel === this.getId()) {
                    this.showPanel(options.for);
                    this.activatePanel();
                    return false;
                }
            },

            showPanel: function (panelId) {
                var tabs = this.getTabs(),
                    i,
                    panel,
                    activePanel,
                    panelElement,
                    activePanelElement;

                for (i = 0; i < tabs.length; i++) {
                    activePanel = tabs[i] === panelId;
                    panel = $('#' + tabs[i]).shift();
                    panelElement = new Element(panel);

                    if (activePanel) {
                        if (panelElement.isHidden()) {
                            panelElement.show();
                        }
                        this.setActiveTab(i);
                        activePanelElement = panelElement;
                    } else if (!panelElement.isHidden()) {
                        panelElement.hide();
                    }
                }
                if (activePanelElement) {
                    activePanelElement.fire('tabActivated', {}, true);
                }
            },

            /**
             * Fired when DOM is ready of child components
             * @param e
             * @param options
             */
            onDomRender: function (e, options) {
                if (this.isDescendant(options.getTarget())) {
                    // Automatically select the first tab again each time the panel is reshown.
                    if (this.getActiveTab() !== 0) {
                        this.setActiveTab(0).showActiveTab();
                    }
                }
            },
        });
        return TabPanel;
    }
);

