
define(
    'Inventis/Bundle/BricksBundle/Brick/Reload',[
        'Inventis/Bundle/BricksBundle/Brick/Brick',
    ],
    function (Component) {
        'use strict';

        var Reload = Component.extend({

            _onEvent: null,

            /**
             *
             * @param setup
             */
            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setOnEventFromSetup(setup);
            },

            setOnEventFromSetup: function (setup) {
                if (setup.onEvent === undefined) {
                    return;
                }
                this.setOnEvent(setup.onEvent);
            },

            getOnEvent: function () {
                return this._onEvent;
            },

            setOnEvent: function (onEvent) {
                this._onEvent = onEvent;
            },

            onDomReady: function () {
                this.attachEventListeners();
                this.__super();
            },

            attachEventListeners: function () {
                this.on(this.getOnEvent(), this.onReload, true);
            },

            onReload: function (e, options) {
                // refresh current page
                window.location.reload(true);
            },
        });
        return Reload;
    }
);

