
/**
 * the webadmin is a singleton component that functions as the adapter to framework specific code
 * it is there to allow us to quickly switch frameworks without the need to refactor all other components
 * or at least keep this refactoring to a bare minimum
 * we will wrap framework functions that can than be used by all components using the webadmin
 *
 * the webadmin implement a singleton pattern as it will also ensure heartbeat functionality and other
 * `implement once` features that are needed
 */
define(
    'Inventis/Bundle/BricksBundle/Application',[
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Class',
        // load the framework adapter of choice
        'Inventis/Bundle/BricksBundle/Mixins/Adapters/ECMAScript5ApplicationAdapter',
        'Inventis/Bundle/BricksBundle/Config',
    ],
    function (console, Class, Adapter, Config) {
        'use strict';

        // function heartBeat(){
        //     this.ajax(Config.adminUrl + 'common/heartbeat/', null, function(){ setHeartbeatTimeout.call(this); }, this);
        // }
        // function setHeartbeatTimeout(){
        //     setTimeout(heartBeat.bind(this), 100000);
        // }
        // reference to the singleton
        var instance,

            Application = Class.extend({
                use: [Adapter],

                __construct: function () {
                // setHeartbeatTimeout.call(this);
                },

                /**
             * we overwrite the default mixin handler to implement the feature as desired
             * @param response
             * @param options
             */
                ajaxFailureHandler: function (response, options) {
                    console.error('error during ajax call:', response, options);
                },

                /**
             * ajax calls are initiated through this method
             * the call will call the callback with 2 arguments:
             *  - the response
             *  - the options object that was used for the call
             * @param {String} url
             * @param {String|ArrayBuffer|Blob|Document|FormData|Array|Object} data the parameters that should be
             *                                                                 included in the request, of Array|Object
             *                                                                 it will be converted to a json String
             * @param {Function} callback the callback method on ajax completion
             * @param {Object} scope
             * @param {Object} [headers]
             * @param {String} method http method defaults to post
             * @param {Function} requestManipulator receives the xhr object just before its send off
             * @param {Function|null} errorCallback
             * @events beforeAjaxRequest, afterAjaxRequest
             *
             * @return XMLHttpRequest
             */
                ajax: function (url, data, callback, scope, headers, method, requestManipulator, errorCallback) {
                    method = method || 'POST';
                    var xhr = new XMLHttpRequest({responseType: 'json'}),
                        me = this;

                    this.fire('beforeAjaxRequest', {data: data, xhr: xhr});
                    var failHandler = function (response, options) {
                        if (!!errorCallback) {
                            try {
                                // try decode the response or return as is on failure
                                response = this.JSON.decode(response.responseText);
                            } catch (e) {
                                response = response.responseText;
                            } finally {
                                errorCallback.call(scope, response, options);
                            }
                        } else {
                            this.ajaxFailureHandler(response, options);
                        }
                        this.fire('afterAjaxRequest', {response: false, xhr: xhr});
                    }.bind(this);
                    var successHandler = function (response, options) {
                        try {
                            // try decode the response or return as is on failure
                            response = this.JSON.decode(response.responseText);
                        } catch (e) {
                            response = response.responseText;
                        } finally {
                            callback.call(scope, response, options);
                            this.fire('afterAjaxRequest', {response: response, xhr: xhr});
                        }
                    }.bind(this);
                    if (this.browser.support.XMLHttpRequestLevel2) {
                        if (!(data instanceof FormData) && !(data instanceof ArrayBuffer)
                        && !(data instanceof Blob) && !(data instanceof Document)
                        && !(data instanceof String)
                        ) {
                            var formData = new FormData(),
                                i;
                            for (i in data) {
                                formData.append(i, data[i]);
                            }
                            data = formData;
                        }
                        xhr.open(method, url);
                        xhr.onload = function (response) {
                        // IE does not support responseType: 'json'
                            if (response instanceof String) {
                                response = me.JSON.decode(response);
                            }
                            if (response.target.status === 200) {
                                successHandler.call(me, response.target);
                            } else {
                                failHandler.call(me, response.target);
                            }
                        };
                        for (var header in headers) {
                            if (headers.hasOwnProperty(header)) {
                                xhr.setRequestHeader(header, headers[header]);
                            }
                        }
                        if (requestManipulator) {
                            requestManipulator(xhr);
                        }
                        xhr.send(data);

                        return xhr;
                    }
                    throw Error('No browser support for XMLHttpRequest level 2');
                },

                /**
             * searches through provided string and executes any script tags
             * found in it by running it through eval
             * @param html
             */
                parseScripts: function (html) {
                    var match,
                        regex = /<script.*?>([\s\S]*?)<\/script>/gmi;
                    while ((match = regex.exec(html))) {
                        try {
                            eval(match[1]);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                },

            });

        return (instance = (instance || new Application()));
    }
);

