define(
    'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',[],
    function () {
        'use strict';
        return function (selector, searchElement) {
            searchElement = searchElement || document;
            var i,
                response = [],
                entries = searchElement.querySelectorAll(selector);
            for (i = 0; i < entries.length; ++i) {
                response.push(entries.item(i));
            }
            return response;
        };
    }
);

