define(
    'Inventis/Bundle/BricksBundle/JsonEncoder',[],
    function () {
        if (!window.JSON) {
            throw Error('No JSON object available in the browser window instance, browser not compatible!');
        }
        var JSON = window.JSON;
        'use strict';
        return {
            /**
             * method will return a decoded version of the data provided
             * @param data
             * @throws SyntaxError
             * @return {Object}
             */
            decode: function (data) {
                return JSON.parse(data);
            },
            /**
             * encode data into a json object
             * @param data
             * @return {*}
             */
            encode: function (data) {
                return JSON.stringify(data);
            },
        };
    }
);

