define(
    'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/HandlerAction',[
        'Inventis/Bundle/BricksBundle/Grid/Plugin/ActionToolbarPlugin/Action',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (Action, Promise) {
        'use strict';

        /**
         * @class ActionToolbarPlugin.HandlerAction
         * @extends ActionToolbarPlugin.Action
         */
        return Action.extend({

            processConfig: function (config) {
                if (!config.handler) {
                    throw new Error('Invalid handler specified for HandlerAction!');
                }
                return Object.assign(
                    this.__super(config),
                    {
                        handler: config.handler,
                        supportsMultiple: config.supportsMultiple !== undefined ? config.supportsMultiple : false,
                    }
                );
            },

            doExecute: function (selections) {
                return new Promise(function (resolve, reject) {
                    require([this.config.handler], function (handler) {
                        try {
                            handler(this, this.grid, selections, resolve, reject);
                        } catch (e) {
                            reject(e);
                        }
                    }.bind(this));
                }.bind(this));
            },
        });
    }
);

