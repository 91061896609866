
/**
 * Mixin is a parallel type of object extension
 * it allows you to add parts of predefined reusable code
 * into the object.
 */
define(
    'Inventis/Bundle/BricksBundle/Mixin',[],function () {
        'use strict';

        var Mixin = function (prop) {
            // first do own mixins so that we still get the change to overwrite them
            if (prop.use && prop.use.length) {
                var mixins = prop.use;
                // parallel extension similar to PHP Traits
                for (var i = 0; i < mixins.length; i++) {
                    var mixin = mixins[i];// simplify addressing
                    if (mixin === undefined) {
                        throw new TypeError(
                            'Mixin is not defined, check that you have defined it ' +
                            'correctly as a dependency when using AMD loading.'
                        );
                    }
                    // if multiple abstract definitions are available carry them over to the concrete implementation
                    if (prop.abstract && prop.abstract.length && mixin.abstract) {
                        var abstractMethods = mixin.abstract;
                        abstractMethods.splice(0, 0, prop.abstract);
                        var uniques = function (array) {
                            var o = {},
                                i,
                                l = array.length,
                                r = [];
                            for (i = 0; i < l; i += 1) {
                                o[array[i]] = array[i];
                            }
                            for (i in o) {
                                r.push(o[i]);
                            }
                            return r;
                        };
                        prop.abstract = uniques(abstractMethods);
                    }
                    for (var name in mixin) {
                        prop[name] = prop[name] ? prop[name] : mixin[name];
                    }
                }
                delete prop.use;// no need to keep the mixins definition in the actual object
            }
            return prop;
        };
        return Mixin;
    }
);

