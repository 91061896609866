define(
    'Inventis/Bundle/BricksBundle/Base64Encoder',[],
    function () {
        if (!window.btoa || !window.atob) {
            throw Error('No Base64 encoding available in the browser window instance, browser not compatible!');
        }
        'use strict';
        /**
         * @class Base64Encoder
         */
        return {
            encode: function (string) {
                return window.btoa(string);
            },
            decode: function (string) {
                return window.atob(string);
            },
        };
    }
);

