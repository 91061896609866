define(
    'Inventis/Bundle/BricksBundle/Grid/Grid',[
        'Inventis/Bundle/BricksBundle/Class',
        'Inventis/Bundle/BricksBundle/Promise',
        'Inventis/Bundle/BricksBundle/Grid/Plugin/GridPluginChain',
        'Inventis/Bundle/BricksBundle/Console',
    ],
    function (Class, Promise, GridPluginChain, Console) {
        'use strict';

        /**
         * @class Grid
         */
        return Class.extend({
            config: null,
            /**
             * @var {Store}
             * @private
             */
            store: null,
            /**
             * @var {View}
             * @private
             */
            view: null,

            /**
             * @var {GridPluginChain}
             */
            pluginChain: null,

            /**
             * @param {Object} config
             */
            __construct: function (config) {
                this.config = this.processConfig(config);
                this.pluginChain = new GridPluginChain();
            },

            processConfig: function (config) {
                var requiredConfig = 'view,store'.split(',');
                for (var i = 0; i < requiredConfig.length; ++i) {
                    if (!config[requiredConfig[i]]) {
                        throw new Error('No valid ' + requiredConfig[i] + ' specified in grid config!');
                    }
                }

                var processedConfig = {
                    // ensure that default filters is an object
                    defaultFilters: Object.assign({}, config.defaultFilters || {}),
                    store: config.store,
                    view: config.view,
                    /**
                     * list of all require modules
                     */
                    modules: [
                        config.store.module,
                        config.view.module,
                    ],
                    plugins: [],
                };

                if (config.plugins) {
                    if (!Array.isArray(config.plugins)) {
                        throw new Error('plugins are expected to be an array, due to return of non incremental array');
                    }

                    config.plugins.forEach(function (pluginConfig) {
                        if (pluginConfig.module) {
                            processedConfig.modules.push(pluginConfig.module);
                        } else {
                            Console.error('Invalid plugin config', pluginConfig, 'does not contain a module, skipping');
                        }
                        processedConfig.plugins.push(pluginConfig);
                    });
                }

                return processedConfig;
            },

            /**
             * @return {HTMLElement}
             * @throws {Error} when not yet rendered
             */
            getContainer: function () {
                if (!this.view) {
                    throw Error('Render container unknown, call render first');
                }
                return this.view.getContainerElement();
            },

            /**
             * @interface
             *
             * prepare the grid for rendering, calling this method is optional, it allows you to let the grid already
             * do some of the work beforehand, so that rendering it afterwards will be faster
             */
            prepare: function () {
                require(this.config.modules);
            },

            /**
             * @return {Promise}
             */
            render: function () {
                return new Promise(function (resolve, reject) {
                    require(this.config.modules, function (Store, View) {
                        this.getPluginChain().then(function (pluginChain) {
                            try {
                                this.store = this.createStore(Store, this.config.store);
                                this.view = this.createView(View, this.config.view, this.store);
                                pluginChain.beforeRender(this.view);
                                this.doRender()
                                    .then()
                                    .then(function () {
                                        pluginChain.afterRender(this.view);
                                        resolve(this.view);
                                    }.bind(this))
                                    .catch(reject);
                            } catch (e) {
                                reject(e);
                            }
                        }.bind(this)).catch(reject);
                    }.bind(this), function (error) {
                        reject(new Error('Failed to load ' + (error.requireModules && error.requireModules.join(','))));
                    });
                }.bind(this));
            },

            /**
             * Lazy init the plugin chain async
             * @return {Promise}
             */
            getPluginChain: function () {
                return new Promise(function (resolve, reject) {
                    try {
                        var completed = 0;
                        if (this.pluginChain.length < this.config.plugins.length) {
                            this.config.plugins.forEach(function (pluginConfig) {
                                require([pluginConfig.module], function (Plugin) {
                                    try {
                                        var plugin = new Plugin(pluginConfig);
                                        plugin.onCreate(this).then(function () {
                                            this.pluginChain.addPlugin(plugin);
                                            ++completed;
                                            if (completed === this.config.plugins.length) {
                                                resolve(this.pluginChain);
                                            }
                                        }.bind(this));
                                    } catch (e) {
                                        reject(e);
                                    }
                                }.bind(this), function (error) {
                                    reject(new Error(
                                        'Failed to load ' + (error.requireModules && error.requireModules.join(','))
                                    ));
                                });
                            }.bind(this));
                        } else {
                            resolve(this.pluginChain);
                        }
                    } catch (e) {
                        reject(e);
                    }
                }.bind(this));
            },

            /**
             * @protected
             * @return {Promise}
             */
            doRender: function () {
                if (!this.view) {
                    throw new Error('Cannot render because no view is available!');
                }
                return this.view.render();
            },

            /**
             * @protected
             * @param {Store} Store
             * @param {Object} config
             *
             * @return {Store}
             */
            createStore: function (Store, config) {
                try {
                    return new Store(config, this.config.defaultFilters);
                } catch (e) {
                    throw new Error('Failed to create the store for the grid: ' + e.message);
                }
            },

            /**
             * @protected
             * @param {View} View
             * @param {Object} config
             * @param {Store} store
             *
             * @return {View}
             */
            createView: function (View, config, store) {
                config.store = store;
                try {
                    return new View(config);
                } catch (e) {
                    throw new Error('Failed to create the view for the grid: ' + e.message);
                }
            },

            /**
             * @return {Promise}
             */
            redraw: function () {
                return new Promise(function (resolve, reject) {
                    if (!this.view) {
                        reject(new Error('cannot redraw when not yet rendered'));
                    }
                    this.getPluginChain().then(function (pluginChain) {
                        pluginChain.beforeRedraw(this.view);
                        this.view.redraw().then(function () {
                            try {
                                pluginChain.afterRedraw(this.view);
                                resolve(this.view);
                            } catch (e) {
                                reject(e);
                            }
                        }.bind(this)).catch(reject);
                    }.bind(this)).catch(reject);
                }.bind(this));
            },

            /**
             * @param {Object} filters
             * @return {Promise}
             */
            addFilters: function (filters) {
                var newFilters = Object.assign({}, this.getFilters());
                for (var filter in filters) {
                    if (filters.hasOwnProperty(filter)) {
                        newFilters[filter] = filters[filter];
                    }
                }
                return this.setFilters(newFilters);
            },

            /**
             * @param {Object} filters
             * @return {Promise}
             */
            setFilters: function (filters) {
                this.store.setFilters(Object.assign({}, this.config.defaultFilters, filters));
                return this.redraw();
            },

            /**
             * @return {Object}
             */
            getFilters: function () {
                return this.store.getFilters();
            },

            /**
             * @param {String[]|null} List of filters to clear, or clear all filters if null.
             * @return {Promise}
             */
            clearFilters: function (filters) {
                filters = filters || null;

                if (filters === null) {
                    this.store.setFilters(Object.assign({}, this.config.defaultFilters));
                } else {
                    var existingFilters = this.getFilters() || {},
                        newFilters = {};

                    for (var filter in existingFilters) {
                        if (existingFilters.hasOwnProperty(filter) && filters.indexOf(filter) === -1) {
                            newFilters[filter] = existingFilters[filter];
                        }
                    }

                    this.store.setFilters(newFilters);
                }

                return this.redraw();
            },

            /**
             * @param {Object} sort
             * @return {Promise}
             */
            addSort: function (sort) {
                var newSort = this.store.getSort();
                for (var i in sort) {
                    if (sort.hasOwnProperty(i)) {
                        newSort[i] = sort[i];
                    }
                }
                return this.setSort(newSort);
            },

            /**
             * @return {Object}
             */
            getSort: function () {
                return this.store.getSort();
            },

            /**
             * @param {Object} sort
             * @return {Promise}
             */
            setSort: function (sort) {
                this.store.setSort(sort);
                return this.redraw();
            },

            /**
             * @return {Promise}
             */
            clearSort: function () {
                return this.setSort({});
            },

            /**
             * invalidates the store's data state
             */
            invalidateStore: function () {
                this.store.clear();
            },

            /**
             * @param {Array} ids
             *
             * @return {Promise}
             */
            deleteRecords: function (ids) {
                ids.forEach(function (id) {
                    this.store.deleteRecord(id);
                }.bind(this));
                return this.redraw();
            },

            /**
             * invalidate data ans reload the grid
             *
             * @return {Promise}
             */
            reload: function () {
                this.invalidateStore();
                return this.redraw();
            },

            /**
             * @param {Number|String} id
             * @return {Record}
             * @throws {Error} when no record was found
             */
            getRecord: function (id) {
                return this.getStore().getRecord(id);
            },

            updateRecord: function (id, newData) {
                return this.store.updateRecord(id, newData);
            },

            /**
             * @protected
             * @return {Store}
             * @throws {Error} when called before the store is defined (happens after render)
             */
            getStore: function () {
                if (!this.store) {
                    throw new Error('Store is required, but not yet defined! Did you forget to render?');
                }
                return this.store;
            },

            /**
             * @protected
             * @return {View}
             * @throws {Error} when called before the view is defined (happens after render)
             */
            getView: function () {
                if (!this.view) {
                    throw new Error('View is required, but not yet defined! Did you forget to render?');
                }
                return this.view;
            },

            /**
             * @param {Object} data the data to create a record from
             * @return {Record}
             * @throws {Error} when creation fails
             */
            createRecord: function (data) {
                return this.getStore().createRecord(data);
            },

            /**
             * @param {GridPlugin} plugin
             *
             * @return {Promise}
             */
            registerPlugin: function (plugin) {
                return new Promise(function (resolve, reject) {
                    this.getPluginChain().then(function (pluginChain) {
                        plugin.onCreate(this).then(function () {
                            pluginChain.addPlugin(plugin);
                            resolve(plugin);
                        }).catch(reject);
                    }.bind(this));
                }.bind(this));
            },
        });
    }
);

