
/**
 * group button types keep track of their groups state
 * and will set/remove active states when clicked
 *
 */
define(
    'Inventis/Bundle/BricksBundle/Brick/GroupButton',[
        'Inventis/Bundle/BricksBundle/Button',
    ],
    function (Button) {
        'use strict';

        var _clickEventGroupKey = '__ButtonClickGroupKey__';
        var GroupButton = Button.extend({
            _group: null,

            /**
             *
             * @param string group
             * @param HTMLElement attachedItem
             * @param {Object} clickEventData
             */
            __construct: function (group, attachedItem, clickEventData) {
                clickEventData[_clickEventGroupKey] = group;
                this.__super(attachedItem, clickEventData);
                this.setGroup(group);
                this._attachGroupEventHandling();
            },

            setGroup: function (group) {
                this._group = group;
                return this;
            },

            getGroup: function () {
                return this._group;
            },

            _attachGroupEventHandling: function () {
                // we capture the event to ensure we are first in the list
                // and act on that by checking if we are the component that fired
                /*
                this.on('buttonClicked', function(e, options){
                    var target = (e.srcElement || e.target),
                        element = this.getElement(),
                        classes = String(element.className).split(" "),
                        index;
                    if (options[_clickEventGroupKey] === this.getGroup()) {
                        if (element === target) {
                            classes.unshift("active");
                            element.className = classes.join(" ");
                        } else {
                            index = classes.indexOf("active");
                            if (index != -1) {
                                classes.splice(index, 1);
                                element.className = classes.join(" ");
                            }
                        }
                    }
                }, false, true);
                */
            },
        });
        return GroupButton;
    }
);

