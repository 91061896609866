
define(
    'Inventis/Bundle/BricksBundle/Brick/Form',[
        'Inventis/Bundle/BricksBundle/HTML/DocumentSelector',
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Config',
        'Inventis/Bundle/BricksBundle/Brick/Brick',
        'Inventis/Bundle/BricksBundle/Brick/Brick/ApiAwareMixin',
        'Inventis/Bundle/BricksBundle/Application',
        'Inventis/Bundle/BricksBundle/Mixins/DataToRemember',
        'Inventis/Bundle/BricksBundle/Brick/IdentifierFieldsAwareMixin',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function ($, console, Config, Brick, ApiAware, App, DataToRemember, IdentifierFieldsAwareMixin, Element) {
        'use strict';

        var __errorClass = '-error',
            __errorMessageClass = 'error-text__message',
            __errorMessagesContainerClass = 'error-text';

        var FormBuilder = Brick.extend({
            use: [ApiAware, IdentifierFieldsAwareMixin, DataToRemember],

            _record: null,
            _identifier: null,

            url: null,
            isDirty: false,
            target: null,
            model: null,
            name: null,
            metadata: null,
            modelCheck: true,

            _extraDataFields: null,
            _identifierFields: null,

            __construct: function () {
                this.__super(arguments);
                this._api = {};
                this._extraData = {};
                this._dataToRemember = {};
                this._extraDataFields = {};
                this._identifierFields = {};
            },

            init: function (setup) {
                this.__super(setup);
            },

            setupFromConfig: function (setup) {
                this.__super(setup);
                this.setApiFromConfig(setup);
                this.setUrlFromConfig(setup);
                this.setNameFromConfig(setup);
                this.setTargetFromConfig(setup);
                this.setModelFromConfig(setup);
                this.setDataToRememberFromConfig(setup);
                this.addIdentifierFieldsFromSetup(setup);
                this.addIdentifierFromSetup(setup);
            },

            addIdentifierFromSetup: function(setup) {
                if (setup.loadedIdentifier) {
                    this.setIdentifier(setup.loadedIdentifier);
                }
            },

            setModelFromConfig: function (setup) {
                if (setup.entityClass) {
                    this.model = setup.entityClass;
                }
                if (setup.modelCheck !== undefined) {
                    this.modelCheck = setup.modelCheck;
                }
            },

            setUrlFromConfig: function (setup) {
                if (setup.url) {
                    this.url = setup.url;
                }
            },

            setNameFromConfig: function (setup) {
                if (setup.name) {
                    this.setName(setup.name);
                }
            },

            getName: function () {
                return this.name;
            },

            setName: function (name) {
                this.name = name;
            },

            setTargetFromConfig: function (setup) {
                if (setup.target) {
                    this.target = setup.target;
                }
            },

            /**
             * to detail with the default behaviour we catch the submit event
             * and dispatch the request to our internal handler instead
             *
             */
            attachListeners: function () {
                this.__super();

                // attach on submit listener to own element
                this.on('submit', this.onSubmit, true);
                // attach edit record event to top level (document)
                this.on('editRecord', this.onEditRecord, true);
                // attach new record event to top level (document)
                this.on('newRecord', this.onNewRecord, true);
                this.on('changed', this.onChanged, true);
                this.on('getLoadedRecord', this.onGetLoadedRecord, true);

                this.on('markDirty', this.onMarkDirty, true);
                this.on('clearDirty', this.onClearDirty, true);

                this.on('beforeRecordLoad', this.onBeforeRecordLoad, true);
                this.on('beforeFormSave', this.onBeforeFormSave, true);

                this.on('fieldInitialized', this.onFieldInitialized, true)

                this.attachDataToRememberListeners();
            },

            onSubmit: function (e) {
                if (!this.url) {
                    e.preventDefault();
                    this.submit();
                    return false;
                }
                return true;
            },

            onEditRecord: function (e, options) {
                this.loadRecord(options);
            },

            onNewRecord: function (e, options) {
                this.newRecord(options);
            },

            onGetLoadedRecord: function (e, options) {
                // attach the record to the options object
                options.record = this.getRecord();
                return false;// stop bubble
            },

            onChanged: function (event, options) {
                this.changeDirtyState(true);
            },

            onMarkDirty: function (event, options) {
                this.changeDirtyState(true);
            },

            onClearDirty: function (event, options) {
                this.changeDirtyState(false);
            },

            onFieldInitialized: function (event, options) {
                var record = this.getRecord();
                if (record) {
                    var field = new Element(options.getTarget());
                    field.fire('loadRecord', {type: 'form', record: record, metadata: this.getMetadata()}, false);
                }
            },

            /**
             * form is submitted
             * @return {Boolean}
             */
            submit: function () {
                this.disable();
                // test if browser is not HTML5 ready when using files
                if (this.isMultipart() && !App.browser.support.XMLHttpRequestLevel2) {
                    console.error('Cannot save data. Your browser is outdated!');
                } else {
                    var identifier = this.getIdentifier(),
                        binaryData = false,
                        params = {fields: {}, files: {}};

                    for (var i in identifier) {
                        params.fields[i] = identifier[i];
                    }

                    this.fire('beforeFormSave', params);

                    if (this.isMultipart()) {
                        binaryData = params.files;
                    }

                    this.apiRequest(
                        'save',
                        params.fields,
                        this.recordSubmitCompleted,
                        binaryData,
                        this.recordSubmitCompleted
                    );
                }
            },

            isMultipart: function () {
                return this.getElement().getAttribute('enctype') === 'multipart/form-data'
            },

            /**
             * callback for submit ajax request
             * @param response the response of the request
             */
            recordSubmitCompleted: function (response) {
                this.enable();
                if (response && response.success === true) {
                    this.recordSubmitSuccess(response);
                } else {
                    this.recordSubmitFailed(response);
                }
            },

            /**
             * on success show a flash message and fire the recordSaved event
             * @param response
             */
            recordSubmitSuccess: function (response) {
                var record,
                    identifier,
                    metadata;
                if (response.result) {
                    if (response.result.data) {
                        record = response.result.data;
                        identifier = response.result.identifier;
                    }

                    if (response.result.metadata) {
                        metadata = response.result.metadata;
                    }
                }
                this.fire(
                    'recordSaved',
                    {
                        record: record,
                        id: identifier.id,
                        identifier: identifier,
                        model: this.model,
                        metadata: metadata,
                    }
                );
                this.setRecord(record);
                this.setIdentifier(identifier);
                this.changeDirtyState(false);
            },

            /**
             * on submit failure we need to process the response for the fields
             * so that they can see if any response was for them
             *
             * @param response
             */
            recordSubmitFailed: function (response) {
                var result = [];

                this.clearErrors();

                if (!response || (typeof response !== 'object')) {
                    this.fire('formSaveRequestFailed');
                } else {
                    result = (response.result === undefined) ? [] : response.result;

                    var errors = this.extractValidationErrors(result);

                    if (errors[this.getName()] !== undefined) {
                        this.setErrors(errors[this.getName()]);
                    }

                    this.fire('formValidationFailed', {errors: errors});
                }

                this.fire('recordSaveFailed', {errors: this.extractValidationErrors(result)});
            },

            extractValidationErrors: function (result) {
                var errors = {};

                if (result) {
                    for (var i = 0; i < result.length; ++i) {
                        errors[result[i].fieldName] = result[i].messages;
                    }
                }

                return errors;
            },

            setErrors: function (messages) {
                var element = this.getComponentElement(),
                    messageContainer = $('.' + __errorMessagesContainerClass, element.getElement()).shift();

                if (!element.classExists(__errorClass)) {
                    element.addClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = '<span class="' + __errorMessageClass + '">'
                        + messages.join('<span/><span class="' + __errorMessageClass + '">')
                        + '</span>';
                }
                this.fire('hasError', {errorClass: __errorClass}, true);
            },

            clearErrors: function () {
                var element = this.getComponentElement(),
                    messageContainer = $('.' + __errorMessagesContainerClass, element.getElement()).shift();

                if (element.classExists(__errorClass)) {
                    element.removeClass(__errorClass);
                }
                if (messageContainer) {
                    messageContainer.innerHTML = '';
                }
            },

            /**
             * method is called when the editRecord event is fired
             * and is passed the options object from the event
             * which can then be handled in a form specific way
             * (allows overwriting for additional checks etc.)
             * @param options
             * @event beforeFormRecordLoaded fired before the form requests server side record loading
             * @event formRecordLoaded
             */
            loadRecord: function (options) {
                this.disable();
                if (this.isAllowedModel(options.model)) {
                    this.clearErrors();
                    this.fire('clear', {defaults: []}, false);

                    // change dirty state after this clear event, else parents will think this form is dirty.
                    this.changeDirtyState(false);

                    var data;
                    if ((data = this.getIdentifierFieldsValues(options.record || options))) {
                        // params for event
                        this.fire('beforeRecordLoad', {type: 'form', data: data, record: options.record});
                        this.apiRequest('get', data, this.recordLoadCompleted, null, this.recordLoadCompleted);
                    }
                }
            },

            assignFormFieldData: function (ajaxData, data) {
                for (var x in data) {
                    if (data.hasOwnProperty(x)) {
                        if (!this.url) {
                            // Inject the extra data into the request (will be used as filter).
                            ajaxData[x] = data[x];
                        } else {
                            // Pretend as if the data came from form fields, since URL's require POST or GET.
                            var input = (x in this._extraDataFields) ? this._extraDataFields[x] : null;

                            if (input === null) {
                                input = this._extraDataFields[x] = document.createElement('input');
                                input.type = 'hidden';
                                input.name = x;

                                this.getElement().appendChild(input);
                            }

                            input.value = data[x];
                        }
                    }
                }
            },

            getIdentifierFieldsValues: function (data) {
                if (!data || !this.identifierFieldsAvailable(data)) {
                    return false;
                }
                var i,
                    values = {},
                    keys = this.getIdentifierFields();
                for (i in keys) {
                    values[i] = data[i] !== undefined ? data[i] : this._extraData[i];
                }
                return values;
            },

            identifierFieldsAvailable: function (data) {
                var i,
                    keys = this.getIdentifierFields();
                for (i in keys) {
                    // if primary key is marked as true (required) make sure its not set to null
                    if (keys[i] && data[i] === null && this._extraData[i] === null) {
                        return false;
                    }
                    if (data[i] === undefined && this._extraData[i] === undefined) {
                        return false;
                    }
                }
                return true;
            },

            /**
             * Checks if the specified model is a model that passes through the model check.
             *
             * @param {String} model
             *
             * @return {bool}
             */
            isAllowedModel: function (model) {
                if (this.modelCheck === false || model === undefined) {
                    return true;
                } else if (this.modelCheck === true && model === this.model) {
                    return true;
                } else if (typeof this.modelCheck === 'object') {
                    for (var i in this.modelCheck) {
                        if (this.modelCheck.hasOwnProperty(i) && this.modelCheck[i] === model) {
                            return true;
                        }
                    }
                }

                return false;
            },

            /**
             * method calls load record enforcing an empty id
             * @param options
             */
            newRecord: function (options) {
                var data = {model: options.model};
                if (this.identifierFieldsAvailable(options.record || options)) {
                    data.record = this.getIdentifierFieldsValues(options.record || options);
                } else {
                    var record = {};
                    var keys = this.getIdentifierFields();

                    for (var i in keys) {
                        record[i] = this._extraData[i] || null;
                    }

                    data.record = record;
                }

                this.loadRecord(data);
            },

            changeDirtyState: function (dirty) {
                dirty = !!dirty;

                if (this.isDirty !== dirty) {
                    this.isDirty = dirty;
                    this.fire('dirtyChange', {isDirty: this.isDirty, id: this.getId()}, true);
                }
            },

            recordLoadCompleted: function (response) {
                if (response && response.success) {
                    this.setRecord(response.result.data);
                    this.setMetadata(response.result.metadata)
                    this.setIdentifier(this.getIdentifierFieldsValues(response.result.identifier));
                    this.fire(
                        'recordLoaded',
                        {type: 'form', record: response.result.data, metadata: response.result.metadata}
                    );
                    this.changeDirtyState(false);
                } else {
                    this.fire('recordLoadFailed');
                }
                this.enable();
            },

            setMetadata : function (metadata) {
                this.metadata = metadata;
            },

            getMetadata: function () {
                return this.metadata;
            },

            /**
             * stores the currently loaded record
             * @param record
             */
            setRecord: function (record) {
                this._record = record;
                return this;
            },

            /**
             *
             * @return {Object}
             */
            getRecord: function () {
                return this._record;
            },

            /**
             * @param {Object} identifier
             */
            setIdentifier: function (identifier) {
                this._identifier = identifier;

                return this;
            },

            getIdentifier: function () {
                return this._identifier || this.createNewIdentifier();
            },

            createNewIdentifier: function () {
                var identifierFields = this.getIdentifierFields(),
                    x,
                    identifier = {};
                for (x in identifierFields) {
                    if (identifierFields.hasOwnProperty(x)) {
                        if (identifierFields[x]) {
                            identifier[x] = null;
                        } else {
                            identifier[x] = this._extraData[x];
                        }
                    }
                }
                return identifier;
            },

            onBeforeFormSave: function (e, options) {
                this.assignFormFieldData(options.fields, this.getExtraData());
            },

            onBeforeRecordLoad: function (e, options) {
                if (options.type !== 'form') {
                    return;
                }
                var data = options.data,
                    x;
                // duplicate the extra data to avoid reference issues
                for (x in this._extraData) {
                    if (this._extraData.hasOwnProperty(x)) {
                        data[x] = this._extraData[x];
                    }
                }
                if (options.record) {
                    var identifierFields = this.getIdentifierFields();
                    for (x in identifierFields) {
                        if (options.record[identifierFields[x]] !== undefined) {
                            data[identifierFields[x]] = options.record[identifierFields[x]];
                        }
                    }
                }
                return data;
            },
        });

        return FormBuilder;
    }
);

