
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Radio',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Checkable',
        'Inventis/Bundle/BricksBundle/HTML/Element',
    ],
    function (Checkable, Element) {
        'use strict';

        var Radio = Checkable.extend({
            /**
             * handles the form firing a beforeFormSubmit event
             * this will allow the field to attach its values to the form field
             * values so that ist can be send off to the server for processing
             * @param {HTMLEvent} e the html event object
             * @param options
             */
            handleBeforeFormSave: function (e, options) {
                var value = this.getValue();
                /*
                 * don't override the name with null when another radio button with the same name has already
                 * put content in the options.fields array
                 */
                if (value !== null) {
                    options.fields[this.getName()] = value;
                }
            },
        });
        return Radio;
    }
);

