
define(
    'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Checkbox',[
        'Inventis/Bundle/BricksBundle/Brick/Form/Field/Input/Checkable',
        'Inventis/Bundle/BricksBundle/HTML/Field/Element',
    ],
    function (Checkable, Element) {
        'use strict';

        var Checkbox = Checkable.extend({
            /**
             * {@inheritDoc}
             */
            handleBeforeFormSave: function (e, options) {
                var name = this.getName(),
                    value = this.getValue();

                if (!options.fields.hasOwnProperty(name)) {
                    options.fields[name] = value;
                } else {
                    /*
                     * The name of this checkbox occurred multiple times, this happens for checkboxes with the same
                     * name, such as 'checkboxGroup[]'. Turn the field value into an array.
                     */
                    var currentValue = options.fields[name];

                    if (currentValue === null || typeof (currentValue) !== 'object') {
                        options.fields[name] = currentValue ? [currentValue] : [];
                    }

                    if (value) {
                        options.fields[name].push(value);
                    }
                }
            },

            /**
             * {@inheritDoc}
             */
            setValue: function (value) {
                if (value !== null && typeof (value) === 'object') {
                    var actualValue = this.getElement().value;

                    /*
                     * This checkbox is probably part of a checkbox group and an array is passed for multiple checkbox
                     * fields that all have the same name. See if this checkbox needs to be checked in the group.
                     */
                    value = (value.indexOf(actualValue) !== -1) ? actualValue : null;
                }

                return this.__super(value);
            },
        });
        return Checkbox;
    }
);

