
define(
    'Inventis/Bundle/BricksBundle/Mixins/Observable',[
        'Inventis/Bundle/BricksBundle/Console',
        'Inventis/Bundle/BricksBundle/Mixin',
        'Inventis/Bundle/BricksBundle/Config',
        'Inventis/Bundle/BricksBundle/Promise',
    ],
    function (console, Mixin, Config, Promise) {
        /**
         * the top level dom element for event listening (or firing if no component element is available)
         * @type {*|HTMLDocument}
         * @private
         */
        var __domListener = window.document;

        /**
         * the property name in the event object that holds options data
         * for later extraction
         * @type {string}
         * @private
         */
        var __optionsProperty = 'detail'; // detail is the CustomerEvent property

        var useCustomEvent = !!window.CustomEvent;

        var mutationObserverMap = new WeakMap();
        var mutationObserver = new MutationObserver(function (mutationList, observer) {
            mutationList.forEach(function (mutation) {
                if (mutation.type !== 'childList') {
                    return;
                }

                mutation.addedNodes.forEach(function (node) {
                    if (!mutationObserverMap.has(node)) {
                        return; // Not interested in this node.
                    }

                    mutationObserverMap.get(node).forEach(function (observable) {
                        observable.onReaddedToDom(mutation.target);
                    }.bind(this));
                }.bind(this));

                mutation.removedNodes.forEach(function (node) {
                    if (!mutationObserverMap.has(node)) {
                        return; // Not interested in this node.
                    }

                    mutationObserverMap.get(node).forEach(function (observable) {
                        observable.onRemovedFromDom(mutation.target);
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        });

        mutationObserver.observe(__domListener, {
            childList: true,
            attributes: false,
            subtree: true,
        });

        /**
         * @interface
         * @mixin {Observable}
         */
        return Mixin({
            observableDebugMode: false,
            observablePromise: null,
            observablePromiseHandlers: null,
            // if you like to use promise pattern define it enabled
            observablePromiseEnabled: false,
            registeredDocumentListeners: null,

            setupObservable: function () {
                this.registeredDocumentListeners = [];

                this.registerMutationObserverListeners();
            },

            registerMutationObserverListeners: function () {
                var element = this.getElement();

                // Mutation events only trigger for the one element that is added or removed. If this is a parent
                // element, only that Observable instance would be notified, but if a parent is removed, the children
                // are automatically also removed, and their Observable instances also need to be notified.
                while (element !== null && element !== undefined) {
                    this.registerMutationObserverListener(element);
                    element = element.parentElement;
                }
            },

            registerMutationObserverListener: function (element) {
                if (!mutationObserverMap.has(element)) {
                    mutationObserverMap.set(element, []);
                }

                var observables = mutationObserverMap.get(element);
                observables.push(this);

                mutationObserverMap.set(element, observables);
            },

            /**
             * Called when this element is readded to the DOM.
             *
             * Override this if you need to listen to this element being readded to the DOM. This will not usually
             * be called for the initial add because components are usually initialized after they are already
             * in the DOM.
             *
             * @param {Element} newParentElement
             */
            onReaddedToDom: function (newParentElement) {
            },

            /**
             * Called when this element is removed from the DOM.
             *
             * Override this if you need to listen to this element being removed from the DOM.
             *
             * @param {Element} newParentElement
             */
            onRemovedFromDom: function (oldParentElement) {
                this.cleanUpRegisteredDocumentListeners();
            },

            /**
             * Removes any registered event listeners registered on the document.
             *
             * Listeners on this element are automatically cleaned up as the element is removed, but elements
             * registered on the document need to be explicitly removed.
             */
            cleanUpRegisteredDocumentListeners: function () {
                this.registeredDocumentListeners.forEach(function (registeredDocumentListener) {
                    this.cleanupRegisteredDocumentListener(registeredDocumentListener);
                }.bind(this));
            },

            cleanupRegisteredDocumentListener: function (registeredDocumentListener) {
                this.un(
                    registeredDocumentListener.name,
                    registeredDocumentListener.callback,
                    registeredDocumentListener.self,
                    registeredDocumentListener.capture
                );
            },

            setObservableDebugMode: function (state) {
                this.observableDebugMode = state;
                return this;
            },

            getObservableDebugMode: function () {
                return this.observableDebugMode;
            },

            debugEvents: function () {
                return Config.app.debug || this.getObservableDebugMode();
            },

            /**
             * returns the observablePromise or null if implementing class decides not to make use of it
             * to ensure no observable promise is used set the observablePromiseEnabled property to false in your
             * concrete implementation
             * @return {Promise|Boolean} promise or false if not enabled
             */
            getObservablePromise: function () {
                if (!this.observablePromiseEnabled) {
                    return false;
                }
                // by checking on null we can allow implementing classes to define it false to reject its usage
                if (this.observablePromise === null) {
                    this.observablePromise = new Promise(function (resolve, reject) {
                        this.createObservablePromiseResolver(resolve);
                        this.createObservablePromiseReject(reject);
                        this.observablePromiseHandlers = {
                            resolve: resolve,
                            reject: reject,
                        };
                    }.bind(this)).catch(function (e) {
                        if (this.debugEvents()) {
                            console.log(e);
                        }
                    }.bind(this));
                }
                return this.observablePromise;
            },

            rejectObservablePromise: function (reason) {
                this.getObservablePromise();// ensure one is created
                this.observablePromiseHandlers.reject(reason);
            },

            resolveObservablePromise: function () {
                this.getObservablePromise();// ensure one is created
                this.observablePromiseHandlers.resolve();
            },

            resetObservablePromise: function () {
                this.rejectObservablePromise(this.getId() + ': resetting promise');
                this.observablePromise = null;
            },

            /**
             * create a promise resolver, this default method will just resolve directly, allowing
             * implementing objects to redefine and customize its behaviour
             * @param resolve
             */
            createObservablePromiseResolver: function (resolve) {
                resolve();
            },

            /**
             * method stub
             * create a promise rejector
             * @param reject
             */
            createObservablePromiseReject: function (reject) {
            },

            /**
             * by default the document is our element for observing
             * @return HTMLElement
             */
            getElement: function () {
                return __domListener;
            },

            /**
             * removes an event listener from the document or itself when self is true
             * @param {String} name
             * @param {Function} fn the listener function that needs removing
             * @param {Boolean} self
             * @param {Boolean} capture
             */
            un: function (name, fn, self, capture) {
                var listener = self ? (this.getElement() || __domListener) : __domListener;
                if (fn.__observable_callback_wrapper !== undefined) {
                    fn = fn.__observable_callback_wrapper;
                }
                if (listener.removeEventListener) {
                    listener.removeEventListener(name, fn, capture);
                } else {
                    // IE lte8 compatibility
                    listener.detachEvent(name, fn);
                }

                // NOTE: Normally we should remove the listener from this.registeredDocumentListeners here, but comparing all
                // the parameters with the ones we stored does not seem to match. Fortunately, removing the same
                // listener twice does not seem to break.
            },

            /**
             * attaches a listener to the document or to its own element if that element is available
             * through the getElement method and self is set to true
             * it is strictly forbidden to listen in on other elements this to avoid tight
             * coupling between components
             * @param eventName
             * @param callback
             * @param {Boolean} [self] [default: false] attach the listener to itself
             * @param {Boolean} [capture] [default: false] listen to reverse stack (true) or normal bubble stack (false)
             * @param {Boolean} [promiseEnabled] [default: true] promise the event will be delivered when promise is resolved,
             * when set to false the event is delivered as soon as received
             */
            on: function (eventName, callback, self, capture, promiseEnabled) {
                if (callback === undefined) {
                    throw new TypeError('you must define a callback for listeners!');
                }
                var me = this, // reference to this for scope retention inside wrapper,
                    // wrap a function around the callback to deal with option extraction and compatibility issues
                    // @TODO we probably need to wrap events for cross browser compatibility (e.g IE cant do event.preventDefault())
                    callbackWrapper = function (event) {
                        /*
                         * a browser event wont have an options property set,
                         * in which case we create a reference to a now object inside
                         * the event, which will allow us to pass it on further up the chain
                         */
                        if (event.hasOwnProperty(__optionsProperty) && typeof event[__optionsProperty] !== 'object') {
                            throw new Error(
                                'Event options should always be an object, event with name "' + eventName +
                                '" does not adhere to this constraint'
                            );
                        }

                        var originalOptions = event[__optionsProperty] || {},
                            // Make a copy to ensure no interference can happen.
                            options = Object.assign({}, originalOptions),
                            promise = promiseEnabled !== false ? me.getObservablePromise() : false;

                        options.getTarget = function () {
                            return event.target || event.srcElement;
                        };
                        options.getKeyCode = function () {
                            return event.keyCode || event.which;
                        };
                        options.isFromParent = function () {
                            return this.getTarget().contains(me.getElement());
                        };

                        // Keep event detail and options objects in sync, so changes to one reflect in the other.
                        // Use of "options" can probably be removed in the future in favor of the "detail" property
                        // of CustomEvent.
                        event[__optionsProperty] = options;

                        var callbackHandler = function () {
                            if (me.debugEvents()) {
                                var targetElement = self ? (me.getElement() || __domListener) : __domListener,
                                    handlerElement = me.getElement();
                                console.groupCollapsed(
                                    '%s %O %cheard%c ' + eventName + ' %con%c %s %O %cwith%c %O',
                                    handlerElement,
                                    {el: handlerElement},
                                    'color: green',
                                    'color: blue',
                                    'color: green',
                                    'color: inherit',
                                    targetElement,
                                    {el: targetElement},
                                    'color: green',
                                    'color: inherit',
                                    options
                                );
                                console.debug('Event', event);
                                console.debug('Options', options);
                            }

                            var returnValue = callback.call(me, event, options);

                            if (returnValue === false) {
                                // cross browser compliant way of stopping event
                                event.cancelBubble = true;
                                event.stopPropagation();
                            }

                            if (me.debugEvents()) {
                                console.groupEnd();
                            }

                            return returnValue;
                        };

                        if (me.observablePromiseEnabled && promise instanceof Promise) {
                            promise.then(callbackHandler);
                        } else {
                            return callbackHandler();
                        }
                    },

                    listener = self ? (this.getElement() || __domListener) : __domListener;
                if (listener.addEventListener) {
                    listener.addEventListener(eventName, callbackWrapper, capture);
                } else {
                    // IE lte8 compatibility
                    listener.attachEvent(eventName, callbackWrapper, capture);
                }

                if (this.registeredDocumentListeners === null) {
                    throw new Error('You must call setupObservable before trying to register event handlers');
                } else if (!self) {
                    this.registeredDocumentListeners.push({
                        name: eventName,
                        self: self,
                        callback: callbackWrapper,
                        capture: capture,
                    });
                }
                callback.__observable_callback_wrapper = callbackWrapper;
            },

            /**
             * fires an event
             * @param {String} event the name of the event to fire
             * @param {Object} [options] info you want to pass as 2nd argument to listeners
             * @param {Boolean} [bubble] [Default:true] whether the event should bubble up the dom
             * @param {Boolean} [cancelable] [Default: true] whether a listener can cancel the event
             * @param {Boolean} [self] [Default: true] fire the event from own element (=true) or top level (=false)
             * @return {*}
             */
            fire: function (event, options, bubble, cancelable, self) {
                self = self !== undefined ? Boolean(self) : true;
                bubble = bubble !== undefined ? Boolean(bubble) : true;
                cancelable = cancelable !== undefined ? Boolean(cancelable) : true;

                var evt,
                    targetElement = self ? (this.getElement() || __domListener) : __domListener,
                    triggerElement = this.getElement();

                if (useCustomEvent) {
                    // dispatch for real browsers
                    evt = new CustomEvent(event, {
                        detail: options,
                        bubbles: bubble,

                    });
                    evt.initCustomEvent(event, bubble, cancelable, options); // event type,bubbling,cancelable
                } else {
                    document.createEvent(event);
                    // for paraplegic IE
                    evt = document.createEvent(event);
                    evt[__optionsProperty] = options || {};
                    evt.cancelable = cancelable;
                    evt.cancelBubble = !bubble;
                }

                if (this.debugEvents()) {
                    console.groupCollapsed(
                        '%s %O %cfired%c ' + event + ' %con%c %s %O %cwith%c %O',
                        triggerElement,
                        {el: triggerElement},
                        'color: green',
                        'color: blue',
                        'color: green',
                        'color: inherit',
                        targetElement,
                        {el: targetElement},
                        'color: green',
                        'color: inherit',
                        options
                    );
                    console.debug('Event', evt);
                    console.debug('Options', options);
                }

                var result = targetElement.dispatchEvent(evt);

                if (this.debugEvents()) {
                    console.groupEnd();
                }

                return result;
            },
        });
    }
);

